.mm-account-overview {
  &__add-account {
    font-weight: 600;
    font-size: 1.25rem;

    .upgrade-caption {
      display: block;
      font-size: 0.875rem;
      color: #969eac;
      margin-top: 5px;
    }
  }

  &__error {
    border-left: 3px solid $redAlert;

    .fix-connection-sec {
      border-bottom: 1px solid $grayOpacity24;
    }
  }

  &__info {
    border-left: 3px solid $yellow;
  }

  &__connected {
    border-left: 3px solid $green;
  }

  &__account {
    border-left: 3px solid $blue;
  }

  &__archived {
    border-left: 3px solid $gray;
  }

  &__error-title,
  &__connected-title,
  &__info-title {
    font-weight: $lighter;
    font-size: 0.875rem;
  }

  &__error-title {
    color: $redAlert;
  }

  &__info-title {
    color: $yellow;
  }

  &__connected-title {
    color: $green;
  }

  &__block-title {
    font-size: 1.125rem;
    font-weight: $normal;
  }

  &__update-link {
    font-weight: 500;
    font-size: 0.875rem;

    .update-credentials {
      color: $purple;
      cursor: pointer;
    }
  }

  &__delete-link {
    font-weight: $normal;
    font-size: 0.875rem;
  }
}
.accounts-provider-logo {
  max-height: 40px;
  max-width: 100px;
}
.no-update {
  color: $grayText;
  font-weight: 400;
  font-size: 0.75rem;
}
.plan-badge img {
  height: 80px;
}
