.mm-form-group {
  @extend .form-group;
  position: relative;

  .text-input-label {
    color: $black;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    font-style: normal;
    letter-spacing: 0.02em;
  }

  .form-control {
    width: 100%;
    height: 48px;
    color: $darkGray;
    font-size: 0.875rem;
    padding: 0 20px 0 20px;
    border-radius: $radiusS;
    border: 1px solid $grayOpacity24;

    &:active,
    &:focus {
      border: 1px solid $gray;
    }

    &::placeholder {
      color: $placeholder;
    }

    &__no-icon {
      background-image: unset !important;
    }

    @media (min-width: $med) {
      padding: 0 30px 0 20px;
    }
  }

  .invalid-feedback {
    text-align: right;
  }

  .input-add-on {
    top: 34px;
    right: 10px;
    color: $gray;
    font-weight: 400;
    position: absolute;
    font-size: 0.875rem;
    padding: 16px 5px 0 0;
    line-height: 1.125rem;
  }

  .tooltip-icon {
    margin-left: 8px;
  }
}
