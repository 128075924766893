.account-types {
  &-head {
    display: flex;
    width: 100%;
    flex-direction: column;

    @media screen and (min-width: $md) {
      flex-direction: row;
      align-items: center;
    }

    @media screen and(min-width: $bs-lg) {
      position: relative;
    }

    &__back {
      border: none;
      background-color: transparent;
      margin-right: 16px;
      display: inline-flex;
      align-items: center;
    }

    a:hover {
      color: $darkGray;
    }

    &__from,
    &__account-type {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $gray;
      display: inline-flex;
      align-items: center;
      background-color: transparent;
      border: none;

      &:hover {
        color: $darkGray;
      }
    }

    &__title {
      display: flex;
      flex: 1;
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
      margin-top: 16px;

      span {
        display: inline-block;
        margin-left: 8px;
      }

      @media screen and (min-width: $md) {
        justify-content: center;
        margin-top: 0;
      }

      @media screen and(min-width: $bs-lg) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .drop-box {
      height: 36px;
      width: unset;

      .dropdown-toggle {
        position: relative;
        height: 100%;
        border: none;
      }

      .account-types-dropdown {
        overflow-y: auto;
        margin-bottom: unset;
        padding: 8px 0;

        li {
          padding: 8px 24px;
          color: $gray;
        }
      }
    }

    .drop-box .dropdown-toggle:after {
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
    }

    .drop-box.show .dropdown-toggle:after {
      top: 50%;
      transform: rotate(-135deg);
    }
  }

  &__table-toggles {
    padding: 0 !important;
  }
}

.account-types-chart-container {
  background: $white;
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusS;
  padding: 0 16px 16px 0;
  display: flex;

  &__summary {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 8px 4px 8px 32px;
    min-width: 220px;
    border-left: 1.5px solid $blue;
    height: fit-content;

    .chart-label {
      display: block;
      color: $blue;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 4px;
    }

    .chart-value {
      color: $blue;
      font-weight: 300;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.02em;
    }

    .legend-wrapper {
      display: flex;
      align-items: center;
      color: $gray;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;

      .legend--blue {
        background-color: $blue;
      }

      .legend--red {
        background-color: $red;
      }

      span {
        width: 18px;
        height: 18px;
        border-radius: 2px;
        margin-right: 16px;
      }
    }
  }

  &__chart {
    flex: 1;
  }
}

.sticky-table-holder table {
  max-height: 600px;
  overflow-y: auto;
}

.sticky-table-holder thead tr:first-child {
  background: $white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.accounts-table {
  button.account-name {
    border: none;
    background: transparent;
    padding: 0;
    text-align: left;
  }

  tbody {
    tr td:first-child {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 6px !important;
        height: 6px !important;
        border-radius: 50%;
        top: 50% !important;
        transform: translateY(-50%) !important;
        left: -16px;
        bottom: unset !important;
      }
    }

    .account--error::before {
      background-color: $redAlert !important;
    }

    .account--warn::before {
      background: $yellow !important;
    }

    .account--archived::before {
      background: $gray !important;
    }
  }

  .ct-box {
    position: relative;

    .hide-before-elements {
      position: absolute;
      width: 20px;
      height: 60px;
      top: 16px;
      left: 16px;
      background-color: $white;
      z-index: 100;
    }
  }
}

.account-types-nav {
  min-height: 40px;

  .account-name {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: $black;
    display: inline-block;
    margin-left: 16px;
  }
}
