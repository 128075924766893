.form-wrap {
  position: relative;
}

.form-wrap .credentials-checkbox input {
  width: 29px;
  display: inline;
  vertical-align: middle;
  margin-right: 12px;
  height: 28px;
  outline: none;
}

.form-wrap .credentials-checkbox {
  font-size: 0.75rem;
  padding: 28px 0 14px;
}

.form-wrap .checkbox-item {
  margin-bottom: 11px;
  display: inline-block;
  color: $grayText;
}

.form-wrap .checkbox-item label {
  margin-bottom: 0;
}

.feedback.signup {
  position: absolute;
  right: -46%;
  top: 7%;
  width: 40%;
  color: $yellow;
}

.feedback.card-list {
  margin-bottom: 15px;
  margin-top: -15px;
}

.form-wrap .checkbox-item .check-box {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-weight: 400;
  line-height: 150%;
  font-size: 0.875rem;
}

.form-wrap .checkbox-item input[type='checkbox'] {
  visibility: hidden;
}

.form-wrap .checkbox-item .geekmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid $purpleOpacity24;
  border-radius: 4px;

  &.checkbox-light {
    border: 1px solid $lightBgOpacity24;
  }
}

.form-wrap .checkbox-item .geekmark:after {
  content: '';
  position: absolute;
  display: none;
}

.form-wrap .checkbox-item .check-box input:checked ~ .geekmark:after {
  display: block;
}

.form-wrap .checkbox-item .check-box .geekmark:after {
  left: 6px;
  bottom: 5px;
  width: 5px;
  height: 11px;
  border: solid $primary;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (max-width: $sm) {
  .feedback.signup {
    position: static;
    display: block;
    text-align: right;
    width: 100%;
    max-width: none;
  }
}
