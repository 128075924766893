.inline-alert {
  display: none;
}

.inline-alert:not(.hidden) {
  top: 1rem;
  left: 12%;
  z-index: 99;
  display: flex;
  color: $white;
  padding: 0.7rem;
  cursor: pointer;
  position: absolute;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 4px 8px $pinkShadow30;
  font-size: 0.9375rem;
  height: 42px;

  @media (min-width: $md) {
    left: 40%;
  }

  button {
    margin-left: 2rem;
    font-weight: 200;
    margin-bottom: 4px;
    border: none;
    background: none;
  }
}

.close-alert:before, .close-alert:after {
  position: absolute;
  top: 14px;
  content: ' ';
  height: 14px;
  width: 1.5px;
  background-color: lighten($white, 20);
}

.close-alert:before {
  transform: rotate(45deg);
}

.close-alert:after {
  transform: rotate(-45deg);
}
