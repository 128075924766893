.dashboard {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-auto-rows: minmax(50px, auto);
  gap: 16px;
  margin-top: 10px;

  @media screen and (min-width: $md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (min-width: $bs-lg) {
    grid-auto-rows: minmax(40px, 59px);
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.interval-selector {
  list-style-type: none;
  display: flex;
  align-items: center;

  &__option {
    color: $grayText;
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
    margin-right: 20px;

    &--active {
      color: $green;
    }

    &:last-child {
      margin-right: unset;
    }
  }
}

.portfolio-mover-item {
  display: flex;
  margin-bottom: 14px;
  overflow: hidden;
  height: 56px;
  cursor: pointer;

  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    border: 1px solid $grayOpacity40;
    object-fit: contain;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__title {
    color: $darkGray;
    font-weight: 600;
    font-size: 16px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: $bs-lg) {
      max-width: 150px;
    }
  }

  &__description {
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__balance {
    font-size: 14px;
    font-weight: 300;
    color: $grayText;
    line-height: 150%;
    align-items: center;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.dashboard-chart {
  background: $white;
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusS;
  padding: 16px;
  padding-left: 24px;
  grid-column-start: 1;
  grid-column-end: 2;

  @media screen and (min-width: $md) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @media screen and (min-width: $bs-lg) {
    grid-row-start: 1;
    grid-row-end: 7;
  }

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;

    .interval-selector {
      width: 100%;
      justify-content: space-between;
    }

    @media screen and (min-width: $med) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .interval-selector {
        width: unset;
        justify-content: flex-start;
      }
    }

    &-networth {
      background: $purple;
      border-top-right-radius: $radiusS;
      border-bottom-right-radius: $radiusS;
      padding: 12px 24px;
      color: $white;
      margin-left: -24px;
      margin-right: 24px;

      span {
        display: block;

        &:first-child {
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 4px;
        }

        &:last-child {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }

    &-comparison {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: right;

      @media screen and (min-width: $med) {
        text-align: left;
      }

      span {
        color: $grayText;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
      }

      p {
        color: $green;
        font-size: 24px;
        line-height: 30px;
        font-weight: 300;
        margin: 0;
      }

      .networth--negative {
        color: $redLosses;
      }
    }
  }
}

.portfolio-movers {
  padding: 16px;
  background: $white;
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusS;
  overflow-y: auto;
  overflow-x: hidden;
  grid-column-start: 1;
  grid-column-end: 2;

  @media screen and (min-width: $md) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @media screen and (min-width: $bs-lg) {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 9;
  }

  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;

    .interval-selector {
      width: 100%;
      justify-content: space-between;
    }

    @media screen and (min-width: $med) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 12px;

      &-title,
      .interval-selector {
        margin-bottom: 0 !important;
      }

      .interval-selector {
        width: unset;
        justify-content: flex-start;
      }
    }

    @media screen and (min-width: $bs-lg) {
      flex-direction: column;
      justify-content: unset;
      align-items: unset;

      &-title,
      .interval-selector {
        margin-bottom: 12px !important;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      h2 {
        color: $purple;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        margin: 0;
        margin-left: 8px;
      }
    }
  }

  &__buttons {
    border-bottom: 1px solid rgba($color: $gray, $alpha: 0.1);
    margin: 0 -16px;
    padding: 0 16px 24px;

    .mm-plan-radios {
      width: fit-content;

      label {
        padding: 8px 16px !important;
        height: 40px !important;
      }

      .mm-radio-bg {
        height: 40px !important;
      }
    }

    #mm-mover-account {
      &:checked ~ .mm-radio-bg {
        transform: translateX(0);
        transition: transform 0.2s ease-in-out;
      }
    }

    #mm-mover-holdings {
      &:checked ~ .mm-radio-bg {
        transform: translateX(138px);
        transition: transform 0.2s ease-in-out;
      }
    }

    #mm-mover-account:checked + label,
    #mm-mover-holdings:checked + label {
      color: $primary;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 16px 0 0;

    @media screen and (min-width: $med) {
      flex-direction: row;
    }

    &-gainers,
    &-losers {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-width: 0;

      h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 16px;
      }
    }

    &-gainers {
      margin-bottom: 24px;

      h3 {
        color: $green;
      }

      .portfolio-mover-item__description {
        color: $green;
      }

      @media screen and (min-width: $med) {
        border-right: 8px solid transparent;
        margin-bottom: 0;
      }
    }

    &-losers {
      h3 {
        color: $redAlert;
      }

      .portfolio-mover-item__description {
        color: $redAlert;
      }

      @media screen and (min-width: $med) {
        border-left: 8px solid transparent;
      }
    }

    .no-data {
      font-size: 16px;
      line-height: 20px;
      color: $grayText;
    }
  }
}

.dashboard-accounts {
  padding: 16px;
  background: $white;
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusS;
  grid-column-start: 1;
  grid-column-end: 2;

  @media screen and (min-width: $md) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @media screen and (min-width: $bs-lg) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 7;
    grid-row-end: 17;
  }

  &__header-container {
    position: relative;

    .interval-selector {
      width: 100%;
      justify-content: space-between;
    }

    @media screen and (min-width: $med) {
      .interval-selector {
        position: absolute;
        width: unset;
        justify-content: flex-start;
        right: 48px;
        top: calc(50% - 8px);
        transform: translateY(-50%);
      }
    }

    @media screen and (min-width: $bs-lg) {
      .interval-selector {
        position: static;
        width: 100%;
        justify-content: space-between;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba($color: $gray, $alpha: 0.1);
    margin: 0 -16px 16px;
    padding: 0 16px 16px;

    @media screen and (min-width: $med) {
      margin-bottom: 0;
    }

    @media screen and (min-width: $bs-lg) {
      margin-bottom: 16px;
    }

    &-title {
      display: flex;
      align-items: center;

      h2 {
        color: $purple;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        margin: 0;
        margin-left: 8px;
      }
    }

    &-action {
      border: none;
      background-color: transparent;
    }
  }

  &__active-accounts-container {
    padding: 8px 0;
    margin-left: -16px;
  }

  &__archived-accounts-container {
    border-top: 1px solid rgba($color: $gray, $alpha: 0.1);
    margin: 16px -16px 0;
    padding: 16px 16px 0 0;

    h3 {
      color: $grayText;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      margin-left: 16px;
    }
  }

  &__accounts-list {
    display: flex;
    flex-direction: column;
    padding: 4px 0;

    & > div {
      margin-left: 16px;
    }

    @media screen and (min-width: $med) {
      flex-direction: row;

      & > div {
        width: calc(50% - 16px);
      }

      & > div:first-child {
        padding-right: 16px;
      }

      & > div:last-child {
        padding-left: 16px;
      }
    }

    @media screen and (min-width: $bs-lg) {
      flex-direction: column;

      & > div {
        width: unset;
      }

      & > div:first-child {
        padding-right: unset;
      }

      & > div:last-child {
        padding-left: unset;
      }
    }

    &--top {
      margin-bottom: 12px;

      @media screen and (min-width: $med) {
        margin-bottom: 0;
      }

      @media screen and (min-width: $bs-lg) {
        margin-bottom: 12px;
      }
    }
  }

  &__body {
    max-height: 590px;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0 -16px;
    padding: 0 16px;
  }
}

.dashboard-account {
  &--status::before {
    content: '';
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  &--error::before {
    background: $redAlert;
  }

  &--warn::before {
    background: $yellow;
  }

  &--archived::before {
    background: $gray;
  }

  &--positive {
    .dashboard-account-item__balance-info {
      color: $green;
    }
  }

  &--negative {
    .dashboard-account-item__balance-info {
      color: $redAlert;
    }
  }
}

.dashboard-account-item {
  display: flex;
  margin-bottom: 20px;
  height: 40px;
  position: relative;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    border: 1px solid $grayOpacity40;
    border-radius: 50%;
    margin-right: 8px;
    width: 40px;
    height: 40px;
    object-fit: contain;
  }

  &__content {
    display: flex;
    flex: 1;
    justify-content: space-between;
    max-width: calc(100% - 40px);

    div {
      width: 50%;
    }

    &--full-width {
      max-width: 100%;
    }
  }

  &__name {
    color: $darkGray;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__updated {
    color: $gray;
    font-size: 12px;
    line-height: 150%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__balance-text {
    color: $darkGray;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
  }

  &__balance-info {
    font-size: 12px;
    line-height: 150%;
    text-align: right;
  }
}

.assets,
.liabilities {
  padding: 16px;
  background: $white;
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusS;

  &__header-container {
    position: relative;
    margin-bottom: 8px;

    .interval-selector {
      width: 100%;
      justify-content: space-between;
    }

    @media screen and (min-width: $med) {
      .interval-selector {
        position: absolute;
        width: unset;
        justify-content: flex-start;
        right: 18px;
        top: calc(50% - 8px);
        transform: translateY(-50%);
      }
    }

    @media screen and(min-width: $bs-lg) {
      margin-bottom: 0;

      .interval-selector {
        position: static;
        width: 100%;
        justify-content: space-between;
      }
    }

    @media screen and (min-width: $bs-xl) {
      .interval-selector {
        position: absolute;
        width: unset;
        justify-content: flex-start;
        right: 18px;
        top: calc(50% - 8px);
        transform: translateY(-50%);
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba($color: $gray, $alpha: 0.1);
    margin: 0 -16px 16px;
    padding: 4px 16px 24px;

    @media screen and (min-width: $med) {
      margin-bottom: 0;
    }

    @media screen and (min-width: $bs-lg) {
      margin-bottom: 16px;
    }

    h2 {
      color: $purple;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      margin: 0;
      margin-left: 8px;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 12px;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 8px;
    width: 100%;

    @media screen and (min-width: $list) {
      padding-top: 0;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media screen and (min-width: $bs-lg) {
      max-height: calc(100% - 104px);
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @media screen and(min-width: $bs-xl) {
      max-height: calc(100% - 80px);
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

.assets {
  grid-column-start: 1;
  grid-column-end: 2;

  @media screen and (min-width: $md) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @media screen and (min-width: $bs-lg) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 7;
    grid-row-end: 15;
  }
}

.asset-liability-item {
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(200px);
  border-radius: $radiusS;
  padding: 14px;
  background-image: linear-gradient(to right, rgba($purple, 0.1), rgba($purple, 0.01));
  overflow: hidden;

  @media screen and (min-width: $list) {
    &:nth-child(3n + 1) {
      background-image: linear-gradient(to right, rgba($purple, 0.01), rgba($purple, 0.1));
    }

    &:nth-child(3n) {
      background-image: linear-gradient(to right, rgba($purple, 0.1), rgba($purple, 0.01));
    }

    &:nth-child(3n + 2) {
      background-image: unset;
      background-color: rgba($color: $purple, $alpha: 0.1);
    }
  }

  @media screen and(min-width: $bs-lg) {
    background-image: linear-gradient(to right, rgba($purple, 0.1), rgba($white, 0.1)) !important;

    &:nth-child(3n + 1) {
      background-image: unset;
    }

    &:nth-child(3n) {
      background-image: unset;
    }

    &:nth-child(3n + 2) {
      background-color: unset;
    }
  }

  @media screen and(min-width: $bs-xl) {
    &:nth-child(odd) {
      background-image: linear-gradient(to right, rgba($purple, 0.01), rgba($purple, 0.1)) !important;
    }

    &:nth-child(even) {
      background-image: linear-gradient(to right, rgba($purple, 0.1), rgba($purple, 0.01)) !important;
    }
  }

  &__header {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &-emoji {
      font-size: 32px;
      margin-right: 8px;
    }

    &-name {
      color: $darkBg;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
    }
  }

  &__balance {
    color: $darkBg;
    font-size: 22px;
    line-height: 24px;
    display: block;
    margin-bottom: 8px;
  }

  &__balance-diff,
  &__balance-diff-per {
    font-size: 14px;
    line-height: 18px;
  }

  &__balance-diff-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__balance-diff {
    color: rgba($color: $darkBg, $alpha: 0.6);
    margin-right: 4px;
  }

  &__balance-diff-per {
    display: inline-block;
    margin-right: 4px;

    &--positive {
      color: $green;
    }

    &--negative {
      color: $redAlert;
    }
  }

  &__indicator {
    display: inline-flex;
  }
}

.liabilities {
  grid-column-start: 1;
  grid-column-end: 2;

  @media screen and (min-width: $md) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @media screen and (min-width: $bs-lg) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 15;
    grid-row-end: 21;
  }
}

.recent-activity {
  padding: 16px;
  background: $white;
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusS;
  grid-column-start: 1;
  grid-column-end: 2;

  @media screen and (min-width: $md) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  @media screen and (min-width: $bs-lg) {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 9;
    grid-row-end: 16;
  }

  &__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba($color: $gray, $alpha: 0.1);
    margin: 0 -16px 16px;
    padding: 4px 16px 24px;

    @media screen and (min-width: $med) {
      margin-bottom: 0;
    }

    @media screen and (min-width: $bs-lg) {
      margin-bottom: 16px;
    }

    h2 {
      color: $purple;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      margin: 0;
      margin-left: 8px;
    }
  }

  &__list {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 16px 0 0;

    @media screen and (min-width: $med) {
      flex-direction: row;

      & > div {
        flex: 1;
      }

      & > div:first-child {
        padding-right: 16px;
      }

      & > div:last-child {
        padding-left: 16px;
      }
    }

    @media screen and (min-width: $bs-lg) {
      flex-direction: column;
      height: calc(100% - 124px);
      padding: 0 0 8px;

      & > div {
        flex: unset;
      }

      & > div:first-child {
        padding-right: unset;
      }

      & > div:last-child {
        padding-left: unset;
      }
    }

    &--top {
      margin-bottom: 12px;

      @media screen and (min-width: $med) {
        margin-bottom: 0;
      }

      @media screen and (min-width: $bs-lg) {
        margin-bottom: 16px;
      }
    }
  }

  &__footer-text {
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    color: $white;
    border-radius: $radiusS;
    background-color: $pinkAlert;
    padding: 8px;
    margin: 16px 0 0;
  }
}

.recent-activity-item {
  display: flex;
  margin-bottom: 16px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &__content {
    flex: 1;
  }

  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    border: 1px solid $grayOpacity40;
    object-fit: contain;
  }

  &__description,
  &__amount {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $darkGray;
    margin: 0;
  }

  &__amount {
    display: inline-block;
    text-align: right;
    margin-left: 8px;
  }

  &__info {
    font-size: 12px;
    line-height: 150%;
    color: $gray;
  }

  &__type {
    text-transform: capitalize;
  }
}

.dashboard-invite-friends {
  padding: 24px;
  background: $purple;
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusS;
  grid-column-start: 1;
  grid-column-end: 2;
  align-self: flex-start;

  @media screen and (min-width: $bs-lg) {
    padding: 24px;
    grid-row-start: 17;
    grid-row-end: 22;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: $white;
    margin-bottom: 12px;
  }

  &__description {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: $white;
    margin-bottom: 24px;
  }

  &__body {
    padding: 24px 20px;
    border-radius: $radiusS;
    background: $white;

    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      color: $darkBg;
      margin-bottom: 16px;
    }
  }

  .referral-link-container {
    margin-bottom: 20px;
    position: relative;

    input {
      padding-right: 40px;

      @media screen and (min-width: $md) {
        padding-right: 20px;
      }
    }

    button {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  button.mm-btn-animate {
    width: 100%;
    font-weight: 400;
  }
}

.featured-partner {
  padding: 16px;
  background: $white;
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusS;
  grid-column-start: 1;
  grid-column-end: 2;
  align-self: flex-start;

  @media screen and (min-width: $md) {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  @media screen and (min-width: $bs-lg) {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 16;
    grid-row-end: 23;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 4px 0;
    margin-bottom: 20px;

    h2 {
      color: $purple;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      margin: 0;
      margin-left: 8px;
    }
  }

  &__content {
    a {
      display: block;
    }

    img {
      width: 100%;
      height: auto;
      max-height: 400px;
      border-radius: $radiusS;
    }
  }
}

.dashboard-container {
  .getting-started {
    display: flex;
    min-height: calc(100vh - 216px);
    background: $white;
    box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
    border-radius: $radiusS;
    padding: 16px;
    justify-content: center;
    align-items: center;
    height: unset;
    margin-top: unset;

    & > div {
      justify-content: center;
      flex-direction: column;
    }

    &__img-container {
      align-items: center;
      display: none;

      @media screen and (min-width: $bs-xl) {
        display: flex;
        width: 60%;
      }
    }

    &__content {
      align-items: flex-start;
      display: flex;

      @media screen and (min-width: $bs-xl) {
        width: 40%;
      }

      h1 {
        font-size: 36px;
        line-height: 45px;
        letter-spacing: -0.02em;
        font-weight: bold;
        color: $darkBg;
        margin-bottom: 12px;
      }

      .btn-add {
        padding: 8px 16px;
        font-size: 14px;
        line-height: 18px;
        width: 240px;
      }
    }

    &__description {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $grayText;
      margin-bottom: 32px;
    }

    &__steps {
      border-top: 1px solid rgba($color: $gray, $alpha: 0.1);
      margin-top: 24px;
      padding: 24px 16px 8px 0;

      &-title {
        display: inline-flex;
        align-items: center;
        margin-bottom: 24px;

        & > span {
          display: inline-block;
        }

        & span:first-child {
          font-size: 18px;
          margin-left: 8px;
        }

        & span:last-child {
          color: $purple;
          font-weight: 500;
          font-size: 20px;
          line-height: 25px;
          margin-left: 16px;
        }
      }
    }

    &__steps-actions {
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      & > span {
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }

      & span:nth-child(3) {
        margin-top: 28px;
      }

      & span:last-child {
        margin-top: 37px;
      }
    }

    &__step--completed {
      color: $green;
      margin-top: 9px;
    }

    &__step--current {
      color: $purple;
      margin-top: 30px;
      display: flex !important;
      align-items: center;

      .mm-btn-animate {
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;
        min-width: unset;
        height: unset;
        margin-left: 16px;
        width: 120px;
        padding: 8px 16px;
      }
    }

    &__step--remaining {
      color: $gray;
    }
  }
}
