.refer-container {
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 24px 0 24px;

  @media screen and (min-width: $md) {
    padding: 44px 36px;
  }
}

.invite-friends {
  display: flex;
  background-color: $purple;
  box-shadow: 0 2.47727px 9.90909px $whiteFadeShadow, inset 0px -1.23864px 0px #545a78;
  border-radius: $radiusS;
  padding: 20px 36px !important;
  margin-bottom: 24px;

  @media screen and (min-width: $md) {
    padding: 20px !important;
  }

  // Banner Container
  &__banner {
    position: relative;
    padding: 0;

    @media screen and (min-width: $lg) {
      padding: 0 20px;
    }

    &-img {
      position: static;
      height: 0;
      width: auto;
      visibility: hidden;
      max-width: 90%;

      @media screen and (min-width: $md) {
        visibility: visible;
        width: 90%;
        height: auto;
      }

      @media screen and (min-width: $lg) {
        top: unset;
        bottom: 0;
        left: 0;
        position: absolute;
        height: calc(100% + 40px);
      }
    }

    &-text {
      color: $white;
      padding: 32px 0 48px;

      @media screen and (min-width: $md) {
        position: absolute;
        left: 28px;
        top: 20%;
        padding: 0;
      }

      h4 {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 8px;

        @media screen and (min-width: $md) {
          font-size: 47px;
          line-height: 59px;
          margin: 0;
        }
      }

      span {
        font-weight: 300;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: 0.02em;

        @media screen and (min-width: $md) {
          font-size: 32px;
          line-height: 40px;
        }
      }
    }
  }

  // Form Container
  &__form {
    min-height: 100%;
    background: $white;
    box-shadow: 0 2.47727px 9.90909px #3d5b8f, inset 0px -1.23864px 0px $whiteInsetShadow;
    border-radius: $radiusS;
    padding: 24px 16px !important;

    @media screen and (min-width: $md) {
      max-width: 75%;
      margin-left: auto;
      margin-top: -20%;
      padding: 36px !important;
    }

    @media screen and (min-width: $bs-xl) {
      margin-top: unset;
    }

    @media screen and (min-width: $lg) {
      padding: 36px 24px !important;
      max-width: 640px;
    }

    h4 {
      color: $appleBlack;
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 16px;
    }

    .form-group {
      display: flex;

      .btn-preview {
        min-width: 140px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }

      .btn-share {
        border: none;
        border-radius: $radiusS;
        padding: 12px 16px;
        min-width: unset;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (min-width: $lg) {
          padding: 12px;
        }

        span {
          color: $white;
          letter-spacing: 0.02em;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;

          @media screen and (min-width: $bs-lg) {
            display: none;
          }
        }
      }

      .btn-facebook {
        background-color: $facebookBlue;
      }

      .btn-twitter {
        background-color: $twitterBlue;
      }

      .referral-link-container {
        position: relative;
        flex: 1;

        input {
          padding-right: 40px;

          @media screen and (min-width: $md) {
            padding-right: 20px;
          }
        }

        button {
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.invite-steps {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  margin-bottom: 24px;

  @media screen and (min-width: $md) {
    margin: 0px -15px;
  }

  @media screen and (min-width: $lg) {
    flex-direction: row;
  }

  &__step {
    margin-bottom: 24px;

    @media screen and (min-width: $lg) {
      margin-right: 48px;
      max-width: 280px;
    }

    &-head {
      display: flex;
      align-items: center;

      @media screen and (min-width: $md) {
        align-items: flex-start;
      }

      @media screen and (min-width: $lg) {
        margin-bottom: 12px;
        align-items: center;
      }
    }

    &-number {
      flex: 0 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $purple;
      box-shadow: 0 4px 12px rgba(83, 76, 234, 0.3);
      color: $white;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 16px;

      @media screen and (min-width: $md) {
        width: 60px;
        height: 60px;
      }
    }

    &-title {
      color: $purple;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      display: inline-block;
    }

    &-description {
      color: $grayText;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      padding-left: 64px;

      @media screen and (min-width: $md) {
        padding-left: 76px;
        margin-top: -20px;
      }

      @media screen and (min-width: $lg) {
        padding-left: 0;
        margin-top: 0;
      }
    }
  }
}

.invite {
  border-radius: $radiusS;
  background-color: rgba(35, 94, 231, 0.1);
  padding: 8px 12px;
  color: $blue;
  font-size: 12px;
  line-height: 12px;
  margin-right: 16px;
  margin-bottom: 8px;

  button {
    border: none;
    background: transparent;
    color: $blue;
    cursor: pointer;
    margin-left: 12px;
  }
}

.referred-users {
  background: $white;
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusS;
  padding: 24px 32px;
  margin: 0 20px;

  @media screen and (min-width: $md) {
    margin: 0;
  }

  h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: $black;
    margin-bottom: 16px;

    @media screen and (min-width: $md) {
      margin-bottom: 24px;
    }
  }

  &__user {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding: 12px 0;
    border-bottom: 1px solid rgba(150, 158, 172, 0.2);

    @media screen and (min-width: $md) {
      flex-direction: row;
      padding: 16px 0;
    }

    &-name {
      min-width: 240px;
      color: $black;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.02em;
      margin-bottom: 8px;

      @media screen and (min-width: $md) {
        margin-bottom: 0;
      }
    }

    &-status {
      flex: 1;
      color: $darkGray;
      line-height: 16px;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}
