.main-content {
  overflow: hidden;

  @media (min-width: $lg) {
    padding: 40px 0 1px 0;
  }

}

.wrapper {
  padding: 1rem;
}

.bg-light {
  background-color: $lightBg !important;
}

.b-primary-light {
  color: $primary;
  background-color: rgba(83, 76, 234, 0.13);
  &:hover {
    color: $primary;
  }
}

.notice-wrapper {
  p {
    color: $grayText;
    font-size: 1.25rem;
  }
  ul {
    li {
      color: $grayText;
      list-style: inside;
    }
  }
}

.notice-content {
  padding-top: 1.25rem;
}

.notice-content-title {
  color: $darkGray;
  padding-top: 2.5rem;
  font-weight: 300;
}

.notice-header {
  padding-bottom: 2rem;

  .notice-date {
    font-size: 1rem;
  }

  p {
    font-size: 1.25rem;
  }
}

.copyright-wrapper {
  opacity: 0.8;
}

.social-links-wrapper {
  padding: 26px 0;

  li {
    opacity: 0.8;
    padding-right: 20px;

  &:hover {
    opacity: 1;
  }
  }
}

/*Clickjacking Error Page*/
.unsecured-wrapper {
  .error-title {
    color: $red;
    font-size: 2.25rem;
    padding-bottom: 1.3rem;
    font-weight: 300;
    line-height: 120%;
  }
  span {
    font-weight: 300;
    font-size: 1.25rem;
    color: #697588;
  }
}
