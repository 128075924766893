.add-accounts-wrapper {
  max-width: 1204px;
  width: 100%;
  padding-right: 0.938rem;
  padding-left: 0.938rem;
  margin: 1rem auto 3.75rem auto;
  display: flex;

  @media (min-width: $med) {
    margin: 2.5rem auto 3.75rem auto;
  }

  .card {
    box-shadow: 0 2px 8px #e2e8f3, inset 0px -1px 0px #ebecf1;
    border-radius: $radiusM;
    margin-top: 1.5rem;

    .connect-options {
      display: block;

      @media (min-width: $md) {
        display: flex;
      }
    }

    h2 {
      font-size: 1.75rem;
      font-weight: 500;
    }

    .description {
      color: $grayText;
      font-size: 1rem;
    }
  }
  .connected-accounts {
    max-width: 732px;
    padding: 0;

    @media (min-width: $med) {
      padding: 1.25rem;
    }
  }
  .manual-accounts {
    max-width: 442px;
    padding: 0;

    @media (min-width: $med) {
      padding: 1.25rem;
    }
  }
  .card-bottom {
    padding: 0;

    @media (min-width: $med) {
      padding: 0 2.375rem 2.375rem 2.375rem;
    }

    p {
      color: $grayText;
      font-size: 1rem;
      max-width: 298px;
      padding: 0 10px;
      display: flex;

      .security-link {
        padding: 0;

        @media (min-width: $med) {
          padding-left: 0.5rem;
        }
      }
    }

    .trust-badges {
      display: block;
      padding: 0 0 1rem 1rem;

      @media (min-width: $med) {
        justify-content: space-between;
        display: flex;
        padding: 0;
      }
    }
  }
}

.credentials-content.connect-account .connect-account-btn {
  height: 48px;
  width: 100%;
  max-width: 470px;
  display: block;
  @media (min-width: $md) {
    width: 230px;
    display: inline-block;
    margin-right: 4px;
  }
}

.zillow-wrap {
  button {
    margin-right: 40px;
  }
}

span.manual-heading {
  color: $darkGray;
  font-size: 1rem;
}

.credentials-content.connect-account {
  max-width: 732px;
  margin-right: 20px;
}

.credentials-content.connect-account p {
  margin-bottom: 20px;
}

.manual-account-section {
  position: relative;
  padding-top: 26px;
}

.manual-account-section::before {
  content: '';
  background: $gray;
  height: 1px;
  width: 100%;
  position: absolute;
  opacity: 0.24;
  top: 0;
  left: 0;
}

.zillow-wrap {
  position: relative;
}

span.zillow-img {
  position: inherit;
  display: block;
}

.subscription-bottom-text.connect-account {
  padding: 8px 95px 15px 171px;
}

.account-mobile-content {
  display: block;
  .step {
    font-size: 0.68rem;
    font-weight: 600;
  }
  p {
    color: $primary;
    line-height: 98%;
    text-align: center;
  }
  @media (min-width: $med) {
    display: none;
  }
}

.connect-steps-wrap {
  max-width: 379px;
  overflow: hidden;
  display: none;
  width: 100%;
  @media (min-width: $med) {
    display: table;
  }
}

.footer-table-wrapper {
  padding: 20px 32px 0 32px;
}

@media (min-width: $lg+1) {
  .manual-account-section::before {
    width: 566px;
  }
  .subscription-bottom-text.connect-account {
    padding-left: 217px;
  }
}

@media (max-width: $md + $sm) and (min-width: $md + 1) {
  .mm-container.mm-container-final {
    margin: 0 40px;
  }
  .credentials-content {
    padding-left: 52px;
    max-width: 420px;
  }
  .subscription-bottom-text.connect-account {
    padding: 6px 50px;
  }
}

@media (max-width: $md) {
  .subscription-bottom-text.connect-account {
    padding: 6px 40px;
  }
  .subscription-bottom-text.connect-account {
    padding: 9px 31px !important;
  }
}

@media (max-width: $sm) {
  .connect-account .subs-content svg {
    display: none;
  }
  .subscription-bottom-text.connect-account {
    display: block;
  }
}

.step-content {
  display: table-cell;
  width: 30.33%;
  text-align: center;
}

.step-content {
  position: relative;
}

.step-content.left-border::before {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  background: $gray;
  top: 21px;
  right: -150%;
  z-index: 99;
  opacity: 0.4;
}

.step-content.left-border.bg-purple::before {
  background: $purple;
  opacity: 1;
}

.step-content.left-border::after {
  content: '';
  width: 100%;
  height: 2px;
  position: absolute;
  background: $primary;
  top: 21px;
  left: 33%;
}

.connect-text {
  margin-top: 41px;
  display: block;
  font-size: 0.75rem;
  color: $primary;
}

.step-icon {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
}

.step-icon.icon-two {
  left: 24%;
}

.step-icon.icon-three:not(.selected) {
  left: 48%;
  top: 11%;
}

.step-icon.icon-three {
  left: 40%;
}

.connect-text {
  margin-right: 24px;
}

.subscription-bottom-text.connect-account {
  padding: 8px 95px 15px 171px;
  position: fixed;
  bottom: 0;
  z-index: 999;
  background: white;
  height: auto;
  width: 100%;
  right: 0;
}

.main-table-wrapper {
  position: relative;
}

.action-next-step {
  color: $gray;
  opacity: 0.4;
}

.mm-manual-account-modal {
  .description {
    font-size: 1rem;
    line-height: 150%;
    color: $grayText;
  }
  &__title {
    font-weight: normal;
    font-size: 1.75rem;
    color: $black;
  }
  &__sub-title {
    font-weight: bold;
    font-size: 1rem;
  }
  .drop-box .dropdown-toggle {
    width: 100%;
    color: #495057;
    background-color: $white;
  }
  .dropdown-select-input .checkbox-list {
    max-height: 40vh;
  }
  .row-set {
    display: block;
    .child {
      width: 100%;
    }
    @media (min-width: $sm) {
      display: flex;
      justify-content: space-between;
      .child {
        width: 47%;
      }
    }
  }
  .action-wrapper {
    display: block;
    justify-content: flex-start;
    padding-top: 1rem;

    @media (min-width: $med) {
      display: flex;
    }

    button:nth-child(2) {
      margin-left: 0;
      margin-top: 10px;

      @media (min-width: $med) {
        margin-left: 1rem;
        margin-top: 0;
      }
    }
    button,
    .mm-brn-primary {
      position: relative;
      width: 100% !important;

      @media (min-width: $med) {
        width: initial;
        max-width: 200px;
      }
      .spinner {
        position: absolute;
        top: 8px;
        right: 0;
      }
    }
  }
}

.action-wrapper {
  .mm-btn-primary {
    width: 180px !important;
    height: 48px !important;
  }
}
button.card-button:active {
  color: $black;
}
.card-button {
  background: none;
  border: none;
  padding: 0;
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  &:hover {
    transform: scale(1.01);
  }
  .loading {
    width: 300px;
    height: 144px;
    margin: auto;
    :before {
      content: none !important;
    }
  }
}
.add-account-card {
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusM;
  margin-top: 1.5rem;
  background: $white;
  margin-bottom: 0.75rem;
  border: none;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  max-width: 100%;
  @media (min-width: $sm) {
    max-width: 317px;
  }
  &.purple {
    .arrow-icon {
      stroke: $purple;
    }
    :before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      border-radius: 10px 0 0 10px;
      background: $purple;
    }
  }
  &.green {
    .arrow-icon {
      stroke: $green;
    }
    :before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      border-radius: 10px 0 0 10px;
      background: $green;
    }
  }
  &.pink {
    .arrow-icon {
      stroke: $pink;
    }
    :before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      border-radius: 10px 0 0 10px;
      background: $pink;
    }
  }
  &.blue {
    .arrow-icon {
      stroke: $blue;
    }
    :before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      border-radius: 10px 0 0 10px;
      background: $blue;
    }
  }
  .card-body {
    min-height: 1px;
    .card-top {
      text-align: left;
      .arrow {
        float: right;
        margin-top: 4px;
      }
      img {
        width: 48px;
      }
    }
    .title {
      font-weight: 500;
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
      margin-left: 14px;
    }
    .description {
      font-weight: $lighter;
      font-size: 1rem;
      margin-top: 8px;
      min-height: 72px;
      text-align: left;
    }
  }
}

// Add manual account
.manual-account-form {
  input {
    width: 100%;
    height: 48px;
    font-size: 0.875rem;
    border: 1px solid rgba(150, 158, 172, 0.239216);
    border-radius: $radiusS;
    padding: 0 50px 0 20px;
    color: #434a55;
  }

  .form-check {
    display: flex;
    align-items: center;

    input[type='radio'] + label {
      padding-left: 0.5rem;
      width: 100%;
    }

    input[type='radio'] + label::after {
      cursor: pointer;
      content: '';
      height: 8px;
      width: 8px;
      top: 6px;
      left: 6px;
      background: $primary;
      position: absolute;
      border-radius: 100%;
      opacity: 0;
      z-index: 999;
      transition: opacity 0.2s ease-in-out;
    }

    input[type='radio'] {
      height: 20px !important;
      left: 0;
      opacity: 0.2;
      position: absolute;
      top: 0;
      width: 20px !important;
      z-index: 999;
    }

    input[type='radio'] + label::before {
      height: 20px;
      width: 20px;
      border: 1px solid $primary;
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      border-radius: 100%;
      opacity: 0.24;
      cursor: pointer;
    }

    input[type='radio']:checked + label::after {
      opacity: 1;
    }

    input[type='radio'] {
      position: absolute;
      padding: 1rem;
      opacity: 0;
      z-index: 9999;
      cursor: pointer;
    }
  }

  li.full-width {
    width: 100%;
  }

  .input-inline {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .form-group {
    margin: 0;
  }
}

.manual-account-holdings-form {
  min-height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.zestimate {
  .radio-label {
    font-weight: 500;
    color: $darkGray;
    padding-left: 1rem;
  }

  &-button {
    border-radius: $radiusS;
    margin-left: 28px;
    margin-bottom: 26px;
    padding: 8px 24px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;

    img {
      width: 22px;
      height: 22px;
    }
  }

  &-value {
    color: $grayText;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;

    &--not-found {
      color: $redAlert;
      font-weight: 300;
      font-size: 15px;
      line-height: 48px;
    }
  }

  &__provided-img img {
    width: 240px;
    height: auto;
  }
}
