@import 'node_modules/react-datepicker/src/stylesheets/datepicker.scss';
@import '../variables';

.react-datepicker-popper[data-placement^='bottom'] {
  margin-top: 0;
}

.react-datepicker-popper {
  z-index: 10000;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: transparent !important;
  display: none;
}

.react-datepicker {
  border: none;
  border-radius: $radiusS;

  &__navigation {
    width: 0 !important;
    height: 10px !important;

    &--previous {
      margin-top: 0.5rem;
      border-right-color: $gray !important;
    }

    &--next {
      margin-top: 0.5rem;
      border-left-color: $gray !important;
    }
  }

  &__day {
    color: #ffffff;
    &--keyboard-selected {
      background-color: $purple;
    }
    &:hover {
      background-color: $purple !important;
    }
  }

  &__month {
    padding: 1rem 0;

    &-container {
      background-color: #081833;
      color: #969eac;
      font-size: 1rem;
    }

    &-text {
      display: inline-block;
      width: 5rem;
      margin: 0.5rem;
      font-size: 1rem;
      padding: 0.2rem;
      &--keyboard-selected {
        background-color: $purple;
      }
      &:hover {
        background-color: $purple;
        color: $white;
      }
    }
  }

  &-wrapper {
    width: auto;
    margin: 0 12px;
  }
}

.react-datepicker__month-container {
  border-radius: $radiusS;
  box-shadow: 0 4px 28px $darkPurpleOpacity40;

  .react-datepicker__header {
    background-color: $darkBg;
    color: $white;
    font-size: 1rem;
    padding: 1rem;
    border: 0;
    .react-datepicker__current-month {
      color: $white;
    }
    .react-datepicker__day-name {
      color: $white;
    }
  }

  .react-datepicker__month,
  .react-datepicker__day {
    &--in-range {
      background-color: transparent;
      color: #ffffff;
      &:hover {
        background-color: $purple !important;
        color: $white;
      }
    }
    &--selected {
      background-color: $purple !important;
      color: $white;
      &:hover {
        background-color: $purple !important;
        color: $white;
      }
    }
  }
}
