.app-subheader-container.account {
  .providerLogo {
    height: 40px;
    position: absolute;
    left: 43%;
    max-width: 240px;
    @media (max-width: $desk) {
      left: 36%;
    }
  }
  > span {
    font-weight: bold;
    height: inherit !important;
  }
  .base-currency-toggle {
    position: absolute;
    right: 1rem;
    display: none;
    @media (min-width: 415px) {
      display: flex;
    }
  }
}

.s-providerLogo {
  height: 36px;
  @media (min-width: $med) {
    height: 48px;
  }
}

.mm-account {
  padding: 0;
  background: $lightBg;
  @media (min-width: $med) {
    padding: 0.25rem 1.125rem;
  }
  @media (min-width: $md) {
    padding: 0 1.125rem 2.063rem 1.125rem;
  }
  .account-ct-box {
    box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
    border-radius: $radiusL;
    padding: 20px;
    height: 100%;
    position: relative;
  }
  .ct-box-placeholder.sync-error {
    background-image: url('../../images/account/Blur.png');
    background-size: cover;
    height: inherit;
    @media (min-width: $desk) {
      height: 344px;
    }
  }
  .account-responsive-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 306px;
    & > div {
      min-height: 306px;
    }
    .bar-tooltip {
      background-color: $darkBg;
      color: $white;
      box-shadow: 0 4px 28px rgba($darkBg, 0.44);
      border-radius: $radiusS;
      padding: 20px 12px;
      .item-name {
        font-size: 0.875rem;
        line-height: 16px;
      }
      .item-value {
        font-style: normal;
        font-size: 1.5rem;
        font-weight: 300;
        line-height: 30px;
      }
    }
  }
  #mm-account-balance:checked ~ .mm-radio-bg {
    transform: translateX(0);
    transition: transform 0.2s ease-in-out;
  }
  #mm-account-holding:checked ~ .mm-radio-bg {
    transform: translateX(0);
    transition: transform 0.2s ease-in-out;
  }
  #mm-account-activity:checked ~ .mm-radio-bg {
    transform: translateX(138px);
    transition: transform 0.2s ease-in-out;
  }
  #mm-account-activity:checked:not(.second) ~ .mm-radio-bg {
    transform: translateX(276px);
    transition: transform 0.2s ease-in-out;
  }
  #mm-account-holding:checked + label,
  #mm-account-balance:checked + label,
  #mm-account-activity:checked + label {
    color: $primary;
  }

  &__selection {
    position: relative;

    @media (max-width: $desk) {
      display: flex;
      flex-direction: column;
    }
    .d-md-flex .d-flex {
      width: 100%;
      justify-content: space-between;
      flex-direction: row;
      @media (max-width: 480px) {
        flex-direction: column;
      }
    }
    &--info {
      display: inline-block;
      margin-right: 1rem;
      ul {
        background: $darkBlueOpacity03;
        border: 1px solid $grayOpacity24;
        box-sizing: border-box;
        border-radius: $radiusS;
        overflow-y: auto;
        white-space: nowrap;
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 0.75rem 0.25rem 0.75rem 0.25rem;
        min-height: 48px;
      }
      li {
        border-right: 1px solid $grayOpacity20;
        font-size: 0.875rem;
        font-weight: 500;
        color: $darkGray;
        text-align: center;
        width: 100%;
        margin: auto;
        padding-left: 20px;
        padding-right: 20px;
        @media (min-width: $desk) {
          padding-left: 28px;
          padding-right: 28px;
        }
        &:last-child {
          border-right: none;
        }
      }
    }
    &--filter {
      // d-md-flex d-lg-inline-block ml-lg-3  justify-content-between
      display: flex;
      justify-content: space-between;

      @media (min-width: 1430px) {
        display: inline-block;
      }
    }
    &--type {
      @media (min-width: 1428px) {
        position: absolute;
        top: 0.2rem;
        right: 0.5rem;
      }
      white-space: nowrap;
      @media (max-width: 480px) {
        text-align: right;
      }
      span {
        font-weight: 600;
        font-size: 1.125rem;
        color: $blue;
        display: inline !important;
        &.manual {
          color: $blue;
        }
        &.good {
          color: $green;
        }
        &.needsInfo {
          color: $yellow;
        }
        &.attention {
          color: $redAlert;
        }
      }
      .attention-section {
        position: relative;
        display: none;
        @media (min-width: 500px) {
          display: block;
        }
        .popup {
          width: 230px;
          padding: 1.25rem;
          background: $darkBg;
          box-shadow: 0 4px 28px $darkBgOpacity44, inset 0 1px 0 $redAlertOpacity50;
          border-radius: $radiusS;
          position: absolute;
          top: 40px;
          right: 0;
          z-index: 1000;
          display: flex;
          flex-direction: column;
          span:first-child {
            text-align: left;
            font-weight: 500;
            font-size: 1rem;
            line-height: 18px;
            letter-spacing: 0.02em;
            color: $white;
          }
          span:nth-child(2) {
            text-align: left;
            font-weight: 300;
            font-size: 0.75rem;
            line-height: 13px;
            letter-spacing: 0.02em;
            color: $gray;
            border-bottom: 1px solid $grayOpacity24;
          }
          span:nth-child(3) {
            white-space: normal;
            text-align: left;
            font-weight: 300;
            font-size: 0.875rem;
            line-height: 15px;
            letter-spacing: 0.02em;
            color: $white;
          }
          button {
            width: 100%;
            height: 40px;
            &:hover {
              box-shadow: none;
            }
          }
        }
      }
    }
    #dropdown-year,
    #dropdown-month {
      height: 3rem;
    }
  }
  &__btn {
    width: 100%;
    height: 3rem;
    @media (min-width: $md) {
      width: 10rem;
    }
  }
  table {
    width: 100%;
  }
  .mm-account-activity-block {
    p {
      font-weight: 500;
      font-size: 0.875rem;
      color: $grayText;
    }
    .mm-activity-table {
      width: 100%;
      background: $white;
      box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
      border-radius: $radiusL;
      padding: 2rem 1.25rem;
      &__head {
        font-weight: 600;
        font-size: 1rem;
        color: $gray;
        &--data {
          padding-bottom: 1.563rem;
          display: none;
          &:first-child {
            padding-left: 1.25rem;
          }
          &:last-child {
            text-align: right;
            padding-right: 1.25rem;
          }
        }
      }
      &__body {
        font-weight: 500;
        font-size: 0.875rem;
        color: $darkGray;
        &--data {
          padding-top: 1rem;
          padding-bottom: 1.25rem;
          &:last-child {
            display: none;
            text-align: right;
            padding-right: 1rem;
            @media (min-width: $md) {
              display: block;
            }
          }
        }
        &--data-edited {
          opacity: 0.2;
        }
        &--wrapper {
          border-bottom: 1px solid rgba($gray, 0.2);
          &:hover {
            background: $darkBg;
            color: $white;
            border-radius: $radiusS;
            cursor: pointer;
          }
          &:last-child {
            border-bottom: unset;
          }
          span {
            font-weight: 500;
            font-size: 0.75rem;
            color: $gray;
          }
        }
        &--sm-title {
          font-weight: 500;
          font-size: 1rem;
          color: $black;
        }
      }
    }
  }
  .no-data {
    margin-left: calc(50% - 62.21px);
  }
}

.settings-gear-button {
  border: 1px solid $purple;
  border-radius: $radiusS;
  padding: 12px;
  height: 48px;
  width: 48px;
  &:hover {
    background-color: $purple;
    color: $white;
    stroke: $white;
    cursor: pointer;
    .gear-color {
      stroke: $white;
    }
    path {
      stroke: $white;
    }
  }
}
.settings-gear-button-disabled {
  border: 1px solid $steelBlue;
  background-color: $steelBlue;
  border-radius: $radiusS;
  padding: 12px;
  height: 48px;
  width: 48px;
  .gear-color {
    stroke: $white;
  }
  path {
    stroke: $white;
  }
  &:hover {
    cursor: not-allowed;
  }
}
.refresh-button {
  border: 1px solid $darkGray;
  border-radius: $radiusS;
  padding: 12px;
  height: 48px;
  width: 48px;
  &:hover {
    background-color: $darkGray;
    color: $white;
    stroke: $white;
    .refresh-color {
      stroke: $white;
    }
  }
}

.setting-aside {
  position: fixed;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  max-width: 665px;
  margin: 0;
  background: $white;
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0 -1px 0px $whiteInsetShadow;
  transition: 0.3s all;
  z-index: 1001;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.1);
}

.mm-holdings-details-modal {
  div {
    color: $darkGray;
  }
  .table-heading {
    color: $gray;
  }
  .form-control {
    height: 48px;
    font-size: 0.875rem;
    border: 1px solid $grayOpacity24;
    padding: 0 20px;
    &:focus,
    &:active {
      border: 1px solid $gray;
    }
    &:disabled {
      -webkit-text-fill-color: $gray;
    }
  }
  .drop-box .dropdown-toggle {
    width: 100%;
    color: $darkGray;
    background-color: $white;
  }
  .dropdown-select-input .checkbox-list {
    max-height: 40vh;
  }
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: $darkGray;
    background-color: transparent;
    border-color: $gray;
  }
  .description {
    font-size: 1rem;
    line-height: 150%;
    color: $grayText;
  }
  &__title {
    font-weight: normal;
    font-size: 1.75rem;
    color: $black;
  }
  &__sub-title {
    font-weight: bold;
    font-size: 1rem;
  }
  .nav-tabs {
    border-bottom: 1px solid $white;
  }
  & > div > .nav-tabs {
    display: none;
  }
  .nav-tabs .nav-link,
  .nav-tabs .mm-nav-link {
    font-size: 1.125rem;
    line-height: 23px;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .active.mm-nav-link,
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .show.mm-nav-item .nav-link,
  .nav-tabs .nav-item.show .mm-nav-link,
  .nav-tabs .show.mm-nav-item .mm-nav-link {
    color: $darkGray;
  }
  .nav-tabs .nav-link:hover,
  .nav-tabs .mm-nav-link:hover,
  .nav-tabs .nav-link:focus,
  .nav-tabs .mm-nav-link:focus {
    border-color: $white;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .active.mm-nav-link,
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .show.mm-nav-item .nav-link,
  .nav-tabs .nav-item.show .mm-nav-link,
  .nav-tabs .show.mm-nav-item .mm-nav-link {
    border-color: $white;
    border-bottom: 1.5px solid $purple;
    margin-bottom: 0;
  }
  .nav-link,
  .mm-nav-link {
    padding: 0.5rem 0;
    margin-right: 2.75rem;
    color: $grayText;
    @media (min-width: $md) {
      margin-right: 6.25rem;
    }
  }
  .row {
    font-size: 1rem;
  }
  .row.pt-2.pb-2 {
    height: 90px;
    @media (min-width: $md) {
      height: 64px;
    }
  }
  .key {
    font-weight: 600;
    color: $darkGray;
  }
  .action-wrapper {
    display: flex;
    justify-content: flex-start;
    padding-top: 1rem;
    button:last-child {
      margin-left: 1rem;
    }
    button {
      position: relative;
      .spinner {
        position: absolute;
        top: 8px;
        right: 0;
      }
    }
  }
  .react-datepicker__input-container input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $darkGray;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: $radiusXS;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .classification-total {
    color: $purple;
    line-height: 19px;
  }
  .unclassified {
    color: $gray !important;
    font-size: 1rem;
    border-bottom: 1px solid rgba($gray, 0.24);
    margin: 15px 0 0 0;
    div.col-sm {
      padding-left: 0;
      width: 50%;
    }
  }
  .current-m {
    color: $green;
  }
  .liner-gradient {
    background: linear-gradient(90deg, $purpleOpacity04 0%, $purpleOpacity03 100%);
  }
  .monthly-values-sub-tabs {
    .nav-tabs {
      position: relative;
      overflow: auto;
      overflow-y: hidden;
      flex-wrap: nowrap;
      border: 1px solid $grayOpacity24;
      border-radius: $radiusS;
    }
    .nav-tabs .nav-link,
    .nav-tabs .mm-nav-link {
      font-size: 0.875rem;
      line-height: 16px;
      padding: 15px 49px;
      margin-right: 0;
      color: $gray;
      border-color: $white;
    }
    .nav-tabs .nav-link.active,
    .nav-tabs .active.mm-nav-link,
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .show.mm-nav-item .nav-link,
    .nav-tabs .nav-item.show .mm-nav-link,
    .nav-tabs .show.mm-nav-item .mm-nav-link {
      color: $purple;
      border: 1.5px solid $white;
    }
    #monthly-value-sub-tab-tab-null {
      border: 1.5px solid $primary;
      border-radius: $radiusS;
      width: 133.61px;
      height: 48px;
      position: absolute;
      display: inline-block;
    }
    .nav-tabs a:first-child.active ~ #monthly-value-sub-tab-tab-null {
      transform: translateX(0);
      transition: transform 0.2s ease-in-out;
    }
    .nav-tabs a:nth-child(2).active ~ #monthly-value-sub-tab-tab-null {
      transform: translateX(133.61px);
      transition: transform 0.2s ease-in-out;
    }
    .nav-tabs a:nth-child(3).active ~ #monthly-value-sub-tab-tab-null {
      transform: translateX(267.22px);
      transition: transform 0.2s ease-in-out;
    }
    .nav-tabs a:nth-child(4).active ~ #monthly-value-sub-tab-tab-null {
      transform: translateX(400.83px);
      transition: transform 0.2s ease-in-out;
    }
  }
  .classifications-sub-tabs {
    .nav-tabs {
      border-bottom: 1px solid $white;
      position: relative;
      overflow: auto;
      overflow-y: hidden;
      flex-wrap: nowrap;
      border: 1px solid rgba($gray, 0.24);
      border-radius: $radiusS;
      max-width: 707.64px;
    }
    .nav-tabs .nav-link,
    .nav-tabs .mm-nav-link {
      font-size: 0.875rem;
      line-height: 16px;
      width: 176.41px;
      min-width: 176.41px;
      display: flex;
      justify-content: center;
      color: $gray;
      border-color: $white;
      padding: 15px 49px;
      margin-right: 0;
      white-space: nowrap;
    }
    .nav-tabs .nav-link.active,
    .nav-tabs .active.mm-nav-link,
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .show.mm-nav-item .nav-link,
    .nav-tabs .nav-item.show .mm-nav-link,
    .nav-tabs .show.mm-nav-item .mm-nav-link {
      color: $purple;
      border: 1.5px solid $white;
    }
    #classifications-sub-tab-tab-null {
      border: 1.5px solid $primary;
      border-radius: $radiusS;
      width: 176.41px;
      height: 48px;
      position: absolute;
    }
    .nav-tabs a:first-child.active ~ #classifications-sub-tab-tab-null {
      transform: translateX(0);
      transition: transform 0.2s ease-in-out;
    }
    .nav-tabs a:nth-child(2).active ~ #classifications-sub-tab-tab-null {
      transform: translateX(176.41px);
      transition: transform 0.2s ease-in-out;
    }
    .nav-tabs a:nth-child(3).active ~ #classifications-sub-tab-tab-null {
      transform: translateX(352.82px);
      transition: transform 0.2s ease-in-out;
    }
    .nav-tabs a:nth-child(4).active ~ #classifications-sub-tab-tab-null {
      transform: translateX(529.23px);
      transition: transform 0.2s ease-in-out;
    }
  }
}

.mm-activity-details-modal {
  div {
    color: $darkGray;
  }
  .table-heading {
    color: $gray;
  }
  .form-control {
    height: 48px;
    font-size: 0.875rem;
    border: 1px solid $grayOpacity24;
    padding: 0 20px;
    &:focus,
    &:active {
      border: 1px solid $gray;
    }
  }
  .drop-box .dropdown-toggle {
    width: 100%;
    color: $darkGray;
    background-color: $white;
  }
  .drop-box .dropdown-toggle:focus,
  .drop-box .dropdown-toggle:hover,
  .drop-box .dropdown-toggle:active {
    background: $white !important;
    box-shadow: none !important;
    color: $darkGray !important;
  }
  .dropdown-select-input .checkbox-list {
    max-height: 40vh;
  }
  &__title {
    font-weight: normal;
    font-size: 1.75rem;
    color: $black;
  }
  &__sub-title {
    font-weight: bold;
    font-size: 1rem;
  }
  .row {
    font-size: 1rem;
  }
  .one-row {
    justify-content: center;
    @media (min-width: $md) {
      justify-content: space-between;
    }
  }
  .key {
    font-weight: 600;
    color: $darkGray;
  }
  .action-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    & > span {
      color: $gray;
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 18px;
      display: flex;
      align-items: center;
      .checkbox-item .geekmark {
        color: $lightBg;
        border: 1px solid $lightBg;
      }
    }
    button:last-child {
      margin-left: 1rem;
    }
    button {
      position: relative;
      .spinner {
        position: absolute;
        top: 8px;
        right: 0;
      }
    }
  }
  .react-datepicker__input-container input {
    display: block;
    width: 100%;
    font-weight: 400;
    line-height: 1.5;
    color: $darkGray;
    background-color: $white;
    background-clip: padding-box;
    border-radius: $radiusS;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 48px;
    font-size: 0.875rem;
    border: 1px solid $grayOpacity24;
    padding: 0 20px;
    min-width: 120px;
  }
  .feedback {
    font-size: 0.75rem;
    font-weight: $bold;
    color: $redAlert;
  }
}

#holdings-details-modal-tabpane-classifications {
  max-width: 752px;
  .dropdown-select-input {
    max-width: 300px;
    margin-bottom: 0;
  }
  .classification-percentage {
    max-width: 174px;
  }
}

.classification-row {
  justify-content: space-between;
  margin-bottom: 0;
  .dropdown-toggle {
    max-width: 300px;
    @media (min-width: $md) {
      min-width: 288px;
    }
  }
}

.classify-percentage {
  text-align: right;
  margin-right: 70px;
}

.classify-total-percentage {
  text-align: left;
  @media (min-width: $md) {
    text-align: right;
  }
  margin-right: 60px;
}

.radio-custom input[type='radio'] {
  opacity: 0;
  height: 20px;
  width: 20px;
  position: absolute;
  z-index: 999;
}

.radio-custom label {
  position: relative;
  padding-left: 30px;
  padding-right: 20px;
}

.radio-custom input + label::before {
  content: '';
  position: absolute;
  height: 20px;
  width: 20px;
  border: 1px solid $primary;
  border-radius: 100%;
  left: 0;
  opacity: 0.24;
  top: 0;
}

.radio-custom input + label::after {
  background: $primary;
  height: 8px;
  width: 8px;
  content: '';
  position: absolute;
  left: 6px;
  border-radius: 100%;
  opacity: 0;
  top: 6px;
}

.radio-custom input:checked + label::after {
  opacity: 1;
}

.delete-account-modal .modal.mm-modal-backdrop.modal-show {
  @media (min-width: $med) {
    width: 530px;
  }
  @media (min-width: $md) {
    width: 665px;
  }
}

.account-chart-skeleton-wrapper {
  width: 100%;
  height: 306px;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  justify-content: space-around;
  span {
    margin: 0 30px;
  }
}

// Connection Issue Area
.connection-issue-container {
  background-color: $darkBg;
  border-radius: 0 0 $radiusS $radiusS;
  color: $white;
  display: flex;
  width: 100%;
  padding: 13px 8px 8px 8px;
  margin-top: -5px;

  .connection-issue-left {
    margin-bottom: 4px;
    width: 94%;
    @media (min-width: $med) {
      margin-bottom: 0;
    }
  }

  button {
    height: 40px;
    @media (min-width: $med) {
      margin-top: 6px;
    }
  }
  &.error {
    border-bottom: 4px solid $redAlert;
  }

  &.warning {
    border-bottom: 4px solid $yellow;
    .warning-icon-svg {
      fill: $yellow;
    }
    .warning-icon-dot-svg {
      stroke: $yellow;
    }
  }

  &.success {
    border-bottom: 4px solid $green;

    .connection-status-icon {
      fill: $green;
      stroke: $green;

      path {
        stroke: $green;
        fill: $green;
      }
    }

    .connection-error-msg {
      max-width: unset;
      span {
        max-width: unset;
      }
    }
  }

  .connection-issue-left {
    @media (min-width: $med) {
      display: flex;
      padding-left: 20px;
    }
  }
  .connection-status-icon {
    padding: 8px 28px 0 20px;
    @media (min-width: $med) {
      padding: 8px 0 0 0;
      margin-left: 20px;
    }
  }
  .connection-label-container {
    padding-right: 40px;
    @media (min-width: $med) {
      border-right: 1px solid $whiteOpacity20;
    }
  }

  .label {
    font-size: 1.25rem;
    display: flex;
  }
  .time {
    font-size: 0.625rem;
    line-height: 0.8125rem;
    display: flex;
    letter-spacing: 0.02em;
  }
  .connection-error-msg {
    @media (min-width: $med) {
      max-width: 268px;
      padding-left: 40px;
      margin: 0 auto 0 0;
    }
    span {
      font-size: 0.75rem;
      line-height: 0.9375rem;
      letter-spacing: 0.02em;
      max-width: 248px;
      display: block;
      margin: 12px 0;
      @media (min-width: $med) {
        max-width: 338px;
        display: flex;
      }
    }
  }
  .connection-issue-right {
    padding-right: 20px;
    button {
      height: 40px;
    }
  }
}

.balance-modal-form {
  &__action-wrapper {
    display: flex;
    justify-content: flex-start;
    margin-top: 2rem;
  }
  button:last-child {
    margin-left: 1rem;
  }
}

.balance-modal-wrapper {
  .description {
    font-size: 1rem;
    line-height: 150%;
    color: $grayText;
  }
  .form-control {
    height: 44px;
    font-size: 0.875rem;
    border: 1px solid $grayOpacity24;
    padding: 0 20px;
    &:focus,
    &:active {
      border: 1px solid $gray;
    }
    &:disabled {
      -webkit-text-fill-color: $gray;
    }
  }
  .balance-value {
    font-size: 0.875rem;
  }
  // segmented control
  .nav-tabs {
    border-bottom: 1px solid $white;
    position: relative;
    overflow: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    border: 1px solid rgba($gray, 0.24);
    border-radius: $radiusS;
  }
  .nav-tabs .nav-link,
  .nav-tabs .mm-nav-link {
    font-size: 0.875rem;
    line-height: 16px;
    padding: 15px 49px;
    margin-right: 0;
    color: $gray;
    border-color: $white;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .active.mm-nav-link,
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .show.mm-nav-item .nav-link,
  .nav-tabs .nav-item.show .mm-nav-link,
  .nav-tabs .show.mm-nav-item .mm-nav-link {
    color: $purple;
    border: 1.5px solid $white;
  }
  #yearly-balance-tab-null {
    border: 1.5px solid $primary;
    border-radius: $radiusS;
    width: 133.61px;
    height: 48px;
    position: absolute;
  }
  .nav-tabs a:first-child.active ~ #yearly-balance-tab-null {
    transform: translateX(0);
    transition: transform 0.2s ease-in-out;
  }
  .nav-tabs a:nth-child(2).active ~ #yearly-balance-tab-null {
    transform: translateX(133.61px);
    transition: transform 0.2s ease-in-out;
  }
  .nav-tabs a:nth-child(3).active ~ #yearly-balance-tab-null {
    transform: translateX(267.22px);
    transition: transform 0.2s ease-in-out;
  }
  .nav-tabs a:nth-child(4).active ~ #yearly-balance-tab-null {
    transform: translateX(400.83px);
    transition: transform 0.2s ease-in-out;
  }
}

.yearly-form-wrapper {
  .form-elements-wrapper {
    margin-top: 20px;
    display: block;
    @media (min-width: $med) {
      display: flex;
    }
    .form-row {
      @extend .form-row;
      align-items: center;
      justify-content: space-between;
      min-width: 260px;
      .input-add-on {
        padding-top: 12px;
        &.left {
          position: absolute;
          left: -6px;
          top: -9px;
        }
      }
      .form-control {
        @extend .form-control;
        &:disabled {
          border: 0;
          background: none;
        }
      }
    }
  }
}
.modal-form-row {
  display: block;
  li {
    width: 100%;
    padding: 8px 12px;
  }
  li:nth-child(odd) {
    background: linear-gradient(90deg, $purpleOpacity04 0%, $purpleOpacity03 100%);
  }
}

.show > .btn-primary.dropdown-toggle {
  color: $darkGray;
  background-color: transparent;
  border-color: $gray;
}

.current-price-section {
  display: block;
  margin-bottom: 24px;
  @media (min-width: $med) {
    display: flex;
    align-items: center;
    float: right;
  }
  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: $black;
    white-space: nowrap;
    margin-right: 20px;
  }
  .form-field-group {
    margin-right: 12px;
    @media (min-width: $md) {
      width: 137px;
    }
    input {
      width: 100%;
      height: 48px;
      margin-top: 10px;
      margin-bottom: 10px;
      @media (min-width: $med) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}

.zillow-provided-img {
  width: 240px;
  height: auto;
}

.add-coin-modal {
  &__description {
    font-size: 0.875rem;
    line-height: 150%;
    color: $darkGray;
    padding-right: 40%;
    margin-bottom: 24px;
  }

  &__body {
    min-height: 300px;
  }

  &__label {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: $appleBlack;
  }
  .coin-market-value {
    display: block;
    @media (min-width: $md) {
      display: flex;
    }
  }
}

.gecko-coin-container {
  list-style-type: none;
  padding: 16px 0;
}

.gecko-coin {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 24px;

  &:hover {
    background-color: rgba($purple, 0.3);
  }

  &__logo {
    width: 28px;
    height: 28px;
    margin-right: 14px;
  }

  &__symbol {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: white;
    margin-right: 8px;
    min-width: 48px;
  }

  &__name {
    color: $gray;
    font-size: 12px;
    line-height: 18px;
    vertical-align: middle;
  }
}

// Extra Info

.account-more-info {
  margin-bottom: 1rem;
  margin-right: 0;
  display: none;
  @media (min-width: $desk + 1) {
    margin-right: 1rem;
    display: inline-block;
  }
  &__list {
    margin: 0;
    display: flex;
    overflow-y: auto;
    min-height: 48px;
    border-radius: $radiusS;
    white-space: nowrap;
    background: $darkBlueOpacity03;
    border: 1px solid $grayOpacity24;
    justify-content: space-between;
    padding: 0.75rem 0.2rem 0.75rem 0.2rem;

    &-item {
      border-right: 1px solid $grayOpacity20;
      font-size: 0.875rem;
      width: 100%;
      margin: auto;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
      @media (min-width: $desk) {
        padding-left: 28px;
        padding-right: 28px;
      }
      &:first-child {
        border-right: unset;
        color: $darkGray;
        font-weight: 500;
      }

      &:last-child {
        border-right: unset;
      }

      &--label {
        color: $gray;
        margin-right: 0.8rem;

        &.active {
          color: $darkGray;
          font-weight: 500;
        }
      }
      &--value {
        color: $darkGray;
        font-weight: 500;
      }
    }
  }
}

.add-metals-modal {
  &__description {
    font-size: 12px;
    line-height: 150%;
    color: $darkGray;
    padding-right: 40%;
    margin-bottom: 24px;
  }

  &__body {
    min-height: 300px;

    .dropdown {
      margin: 0px;

      button {
        width: 100%;
      }

      .mm-dropdown-menu {
        min-width: unset;

        .checkbox-list {
          max-height: 300px;
        }
      }
    }
  }

  &__label {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: $appleBlack;
  }

  &__market-value-container {
    display: block;
    @media (min-width: $md) {
      display: flex;
    }
  }
}

.account-bar-sidecard {
  color: $darkGray;
  display: block;

  @media (min-width: $bs-lg) {
    display: flex;
  }

  .account-sidecard-wrapper {
    width: 100%;
    position: relative;
    margin-bottom: 40px;
    border-radius: $radiusL;
    background-color: $white;
    padding: 20px 17px 20px 24px;
    box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;

    @media (min-width: $bs-lg) {
      margin-left: 1.7rem;
      max-width: 302px;
    }
  }

  .account-sidecard {
    max-height: 320px;
    min-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    &.hidden {
      display: none;
    }

    &--title {
      color: $purple;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
    }

    &--subtitle {
      color: $gray;
      font-size: 16px;
      line-height: 20px;
    }

    &--address {
      color: $darkGray;
      font-weight: 500;
      font-size: 1rem;

      &:hover {
        color: $purple;
      }

      &-street {
        display: block;
      }
    }

    &--action {
      @media (min-width: $bs-lg) {
        bottom: 18px;
        position: absolute;
      }

      button {
        color: $primary;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-weight: 500;
        padding-left: 0;
      }
    }

    &__list {
      border-radius: 6px;
      margin-bottom: 0px;

      &-item {
        display: flex;
        justify-content: space-between;
        padding: 8px 0;

        &--title {
          font-weight: 500;
        }

        &--value {
          background: transparent;
          border: none;
          padding: 0px;
          color: $purple;
        }

        &--placeholder {
          color: $darkGray;
        }

        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}

.associate-card-wrapper {
  position: relative;
  h2 {
    color: $primary;
    font-size: 25px;
    line-height: 25px;
  }
  p {
    color: $gray;
    font-size: 16px;
  }

  button.dropdown-toggle {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dropdown-menu.show {
    max-height: 250px;
    overflow-y: scroll;

    @media (min-width: $md) {
      max-width: 260px;
    }
  }

  .close-icon {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.associate-card {
  &.hidden {
    display: none;
  }

  &__form {
    margin-top: 32px;

    &--action-wrapper {
      margin-top: 20px;
      text-align: center;
      @media (min-width: $bs-lg) {
        margin-top: 100px;
      }
      button {
        width: 100%;
      }
    }

    .dropdown-select-input .checkbox-list {
      max-height: unset;
    }
  }
}

.app-modal {
  padding: 8px 0px;

  &__header {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 28px;
    line-height: 35px;
    color: $appleBlack;
    margin: 0;
  }

  &__description {
    color: $grayText;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 24px;

    @media screen and (min-width: $md) {
      padding-right: 25%;
      font-size: 16px;
    }
  }
}

.choose-account,
.separate-holdings {
  padding-top: 8px;

  button {
    background-color: transparent;
    border: none;
  }

  &__button {
    padding: 20px 16px 16px;
    background-color: $white;
    box-shadow: 0px 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
    border-radius: $radiusS;
    position: relative;
    width: 100%;
    height: 180px;
    margin-bottom: 16px;
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.3s box-shadow;

    &:hover {
      transform: scale(1.01);
    }

    @media screen and (min-width: $md) {
      margin-bottom: 0px;
    }

    svg {
      width: 32px;
      height: 32px;
    }

    &--connected::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 4px;
      border-top-left-radius: $radiusS;
      border-bottom-left-radius: $radiusS;
      background-color: $green;
    }

    &--manual::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 4px;
      border-top-left-radius: $radiusS;
      border-bottom-left-radius: $radiusS;
      background-color: $blue;
    }

    &--detailed::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 4px;
      border-top-left-radius: $radiusS;
      border-bottom-left-radius: $radiusS;
      background-color: #00c1cd;
    }

    &--simple::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 4px;
      border-top-left-radius: $radiusS;
      border-bottom-left-radius: $radiusS;
      background-color: #235ee7;
    }

    &-content {
      display: flex;
      flex-direction: column;
      max-width: 90%;
      align-items: flex-start;
      padding-left: 16px;

      span {
        color: $darkGray;
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 8px;
      }

      p {
        color: $grayText;
        font-size: 16px;
        line-height: 150%;
        text-align: left;
      }
    }

    .loading {
      width: 100%;
    }
  }

  &__help {
    border-radius: $radiusS;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $white;
    background-color: $pinkAlert;
    display: block;
    max-width: 440px;

    button {
      color: $white;
      border: none;
      text-decoration: underline;
      outline: none;
    }
  }
}

.account-options {
  padding-top: 8px;

  &__header {
    h2 {
      padding-left: 48px;
    }

    button {
      background-color: transparent;
      border: none;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 150%;
    color: $grayText;
    margin-bottom: 14px;
  }

  &__col {
    width: 80px;

    span {
      font-size: 14px;
      line-height: 16px;
      color: $gray;
    }
  }

  &__items {
    list-style: none;
    margin: 16px 0 48px;
  }

  &__item {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, rgba($purple, 0.04) 0%, rgba($purple, 0.04) 100%);
    padding: 10px 0 10px 16px;
    border-bottom: 1px solid rgba($purple, 0.1);

    span {
      flex: 1;
      color: $grayText;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.02em;
    }

    &-check {
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__item:last-child {
    border-bottom: unset;
    border-bottom-left-radius: $radiusS;
    border-bottom-right-radius: $radiusS;
  }

  &__item:first-child {
    border-top-left-radius: $radiusS;
    border-top-right-radius: $radiusS;
  }

  &__footer {
    p {
      color: $grayText;
      font-size: 16px;
      line-height: 19px;
      padding-left: 16px;
      padding-right: 48px;
      margin: 0px;
    }
  }

  .manual-img {
    width: 32px;
    height: 44px;
  }

  .connected-label {
    margin-top: -10px;
  }
}

.connected-accounts {
  padding-top: 8px;

  &__header {
    h2 {
      padding-left: 48px;
    }

    button {
      background-color: transparent;
      border: none;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 150%;
    color: $grayText;
    margin-bottom: 14px;
  }

  .security-container {
    display: flex;
    align-items: center;

    @media screen and (min-width: $md) {
      max-width: 50%;
    }

    .security-link {
      color: $grayText;
      font-size: 16px;
      line-height: 19px;
      padding-left: 18px;
    }
  }
}

button.connected-account-option:disabled,
button.connected-account-option[disabled] {
  &:hover {
    transform: none;
  }
}
.opacity-40 {
  opacity: 0.4;
}
.connected-account-option {
  display: flex;
  background-color: $white;
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusS;
  border: none;
  min-height: 150px;
  width: 100%;
  position: relative;
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12), 0.3s box-shadow;

  &:hover {
    transform: scale(1.01);
  }

  img {
    width: 40px;
    height: 40px;
  }

  svg {
    max-width: 36px;
  }
  
  &__title {
    color: $darkGray;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    margin: 0;
    text-align: left;
  }

  &__description {
    color: $grayText;
    font-size: 16px;
    line-height: 150%;
    text-align: left;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: $radiusS;
    border-bottom-left-radius: $radiusS;
    width: 3px;
    height: 100%;
  }

  &--green::before {
    background-color: $green;
  }

  &--blue {
    .arrow path {
      stroke: $royalBlue;
    }

    &::before {
      background-color: $royalBlue;
    }
  }

  &--orange {
    .arrow path {
      stroke: $pinkAlert;
    }

    &::before {
      background-color: $pinkAlert;
    }
  }

  .loading {
    width: 100%;
  }
}

.manual-account {
  padding-top: 8px;

  &__header {
    h2 {
      padding-left: 48px;
    }

    button {
      background-color: transparent;
      border: none;
    }
  }

  &__body {
    font-weight: normal;
    font-size: 1.75rem;
    color: $black;
  }
}

.existing-account {
  display: flex;
  flex-direction: column;

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: $darkGray;
  }

  &__body {
    padding-left: 48px;
  }

  &__item {
    padding: 12px 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $darkGray;
    border: none;
    background-color: transparent;
    width: 100%;
    border-radius: $radiusS;

    &:hover {
      color: $purple;
    }
  }

  .mm-btn-primary {
    align-self: center;
    margin-top: 36px;
    padding: 8px 36px;
  }
}

.real-estate-details {
  display: none;

  &--visible {
    display: flex;
  }
}

.leases {
  min-height: 100%;
  background-color: $white;
  box-shadow: 0 2px 8px #e2e8f3, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusL;

  .nav {
    background: $lightBg;
    border: none;
    border-bottom: 1px solid #eff2f8;
    flex-wrap: wrap;
    overflow: unset;
    border-top-left-radius: $radiusL;
    border-top-right-radius: $radiusL;

    &.nav-tabs {
      .nav-link {
        border-top-left-radius: $radiusL;
        border-top-right-radius: $radiusL;
      }
    }
  }

  .nav-link {
    font-size: 24px;
    font-weight: $lighter;
    line-height: 32px;
    color: $gray;
    padding: 16px 24px;
    border: none;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }

  .nav-link.active {
    color: $purple;
    background-color: $white;
    border-left: 1px solid #eff2f8;
    border-right: 1px solid #eff2f8;
  }
}

.lease-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__description {
    align-self: stretch;
    display: inline-block;
    color: $gray;
    font-size: 12px;
    line-height: 150%;
    padding: 16px 24px;
  }

  &__label {
    font-size: 16px;
    line-height: 19px;
    color: $darkGray;
    font-weight: $lighter;
    margin-bottom: 0;
  }

  &__btn-add {
    margin: 48px 0;
    width: calc(100% - 48px);
    border: none;
    background-color: rgba($purple, 0.1);
    border-radius: $radiusS;
    padding: 16px;
    font-size: 16px;
    line-height: 19px;
    color: $purple;
    align-self: center;
    font-weight: $lighter;

    span {
      margin-left: 8px;
    }
  }

  &__submit-container {
    padding: 24px;
    margin-bottom: 16px;
    border-top: 1px solid transparent;
  }

  .btn-delete {
    width: 18px !important;
    height: 18px !important;
    cursor: pointer;
  }

  .react-datepicker-wrapper input {
    width: 100%;
    color: $darkGray;
    height: 48px;
    font-size: 0.875rem;
    border: 1px solid $grayOpacity24;
    border-radius: 8px;
    padding: 0 50px 0 20px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      border: 1px solid $gray;
    }
  }
}

.real-estate-input-group {
  margin: 0;
  padding: 8px 24px;
  align-self: stretch;

  &:nth-of-type(odd) {
    background: linear-gradient(90deg, rgba($purple, 0.04) 0%, rgba(43, 36, 164, 0) 100%);
  }
}

.monthly-expense {
  min-height: 100%;
  background-color: $white;
  box-shadow: 0 2px 8px #e2e8f3, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusL;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__title {
    font-size: 24px;
    line-height: 32px;
    padding: 16px 24px;
    margin: 0;
  }

  &__description {
    color: $gray;
    display: inline-block;
    font-size: 12px;
    line-height: 150%;
    padding: 16px 24px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &-label {
      font-size: 16px;
      line-height: 19px;
      color: $darkGray;
      font-weight: $lighter;
      margin-bottom: 0;
    }
  }

  &__total-container {
    border-top: 1px solid rgba($color: $gray, $alpha: 0.24);
    display: flex;
    justify-content: space-between;
    padding-top: 32px;
    margin: 24px 24px 0;

    span {
      font-weight: $normal;
      font-size: 18px;
      line-height: 23px;
      color: $darkGray;
    }

    span:last-child {
      text-align: right;
    }
  }

  &__submit {
    margin: 40px 24px 16px;
    width: fit-content;
  }
}

.associated-mortgage {
  min-height: 100%;
  background-color: $white;
  box-shadow: 0 2px 8px #e2e8f3, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusL;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-bottom: 20px;

  &__title {
    font-size: 24px;
    line-height: 32px;
    padding: 16px 24px;
    margin: 0;
  }

  &__description {
    color: $gray;
    display: inline-block;
    font-size: 12px;
    line-height: 150%;
    padding: 16px 24px;
  }

  &__form {
    margin: 0 24px 8px;

    .dropdown-toggle {
      width: 100%;
    }

    .form-subheading {
      margin-right: 0;
    }
  }

  &__submit {
    margin-top: 32px;
  }

  &__detail {
    margin: 0 24px 16px;
    background: rgba(31, 50, 173, 0.03);
    border: 1px solid rgba(150, 158, 172, 0.24);
    border-radius: $radiusS;
    display: flex;
    flex-direction: column;
    padding: 16px;
    cursor: pointer;

    h6 {
      color: $darkGray;
      font-weight: bold !important;
      font-size: 16px;
      line-height: 20px;
      margin: 0;
    }

    .btn-delete {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    &-label {
      color: $gray;
      font-size: 14px;
      line-height: 16px;
      text-align: left;
      margin: 0;
    }

    &-value {
      color: $darkGray;
      font-size: 14px;
      line-height: 18px;
      text-align: right;
    }

    &-img {
      width: 48px;
      height: 48px;
    }

    &-provider {
      color: $darkGray;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
    &-account-name {
      color: $darkGray;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      padding-bottom: 16px;
    }

    &-item {
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__btn-add {
    margin: 16px 0;
    width: calc(100% - 48px);
    border: none;
    background-color: rgba($purple, 0.1);
    border-radius: $radiusS;
    padding: 16px;
    font-size: 16px;
    line-height: 19px;
    color: $purple;
    align-self: center;
    font-weight: $lighter;

    span {
      margin-left: 8px;
    }
  }
}

.account-sidecard .associated-mortgage {
  padding: 0;
  box-shadow: unset;

  .associated-mortgage__title {
    padding: 0;
  }

  .associated-mortgage__description {
    padding: 8px 0 16px;
  }

  .associated-mortgage__form {
    margin: 8px 0;
  }

  .associated-mortgage__detail {
    margin: 0 0 16px;
  }

  .associated-mortgage__detail-item {
    margin-bottom: 1rem;
  }

  .associated-mortgage__btn-add {
    width: 100%;
  }

  .associated-mortgage__detail-account-name {
    font-size: 16px;
  }
}

.associated-real-estate {
  &__detail {
    margin-bottom: 16px;
    background: rgba(31, 50, 173, 0.03);
    border: 1px solid rgba(150, 158, 172, 0.24);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    cursor: pointer;

    ul {
      list-style-type: none;
      margin: 0;
    }

    h6 {
      color: $darkGray;
      font-weight: bold !important;
      font-size: 16px;
      line-height: 20px;
      margin: 0px;
    }

    .btn-delete {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    &-label {
      color: $gray;
      font-size: 14px;
      line-height: 16px;
      text-align: left;
      margin: 0px;
    }

    &-value {
      color: $darkGray;
      font-size: 14px;
      line-height: 18px;
      text-align: right;
    }
  }

  &__btn-add {
    margin: 8px 0px;
    width: 100%;
    border: none;
    background-color: rgba($purple, 0.1);
    border-radius: $radiusS;
    padding: 16px;
    font-size: 16px;
    line-height: 19px;
    color: $purple;
    align-self: center;
    font-weight: $lighter;

    span {
      margin-left: 8px;
    }
  }

  &__form {
    margin: 0;

    .dropdown-toggle {
      width: 100%;
    }

    .form-subheading {
      margin-right: 0px;
    }

    .drop-box .dropdown-toggle {
      width: 100% !important;
    }
  }

  &__submit {
    margin-top: 32px;
  }
}

.real-estate-locked {
  min-height: 400px;
  padding: 40px;
  text-align: center;
  border-radius: $radiusS;
  position: relative;
  height: 78%;
  background-size: contain;
  background-image: url('../../images/networth/chart-blur.png');
  .mm-btn-primary {
    font-size: 1rem;
    display: block;
    width: 90%;
    margin-top: 1rem;
  }
}
.currency-input {
  position: relative;

  &__symbol {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: $gray;
    font-size: 14px;
    line-height: 16px;
  }
}

.radio-group {
  input {
    width: 100%;
    height: 48px;
    font-size: 0.875rem;
    border: 1px solid rgba(150, 158, 172, 0.239216);
    border-radius: $radiusS;
    padding: 0 50px 0 20px;
    color: $darkGray;
  }

  .form-check {
    display: flex;
    align-items: center;

    input[type='radio'] + label {
      padding-left: 0.5rem;
      width: 100%;
    }

    input[type='radio'] + label::after {
      cursor: pointer;
      content: '';
      height: 8px;
      width: 8px;
      top: 6px;
      left: 6px;
      background: $primary;
      position: absolute;
      border-radius: 100%;
      opacity: 0;
      z-index: 999;
      transition: opacity 0.2s ease-in-out;
    }

    input[type='radio'] {
      height: 20px !important;
      left: 0;
      opacity: 0.2;
      position: absolute;
      top: 0;
      width: 20px !important;
      z-index: 999;
    }

    input[type='radio'] + label::before {
      height: 20px;
      width: 20px;
      border: 1px solid $primary;
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      border-radius: 100%;
      opacity: 0.24;
      cursor: pointer;
    }

    input[type='radio']:checked + label::after {
      opacity: 1;
    }

    input[type='radio'] {
      position: absolute;
      padding: 1rem;
      opacity: 0;
      z-index: 9999;
      cursor: pointer;
    }
  }
}

.update-account-value {
  &__description {
    font-size: 12px;
    line-height: 150%;
    color: $gray;
    margin-bottom: 24px;
  }

  .form-wrap .checkbox-item .check-box {
    color: $appleBlack;
    letter-spacing: 0.02em;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  .form-wrap .checkbox-item .geekmark {
    top: 50%;
    transform: translateY(-50%);
  }

  &__label,
  label {
    color: $appleBlack;
    letter-spacing: 0.02em;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
    display: inline-block;
  }

  .currency-input__symbol {
    right: 16px;
  }

  .radio-group label {
    color: $darkGray;
  }
}

.update-account-value .date-wrapper,
.daily-balances .date-wrapper {
  position: relative;

  .icon {
    position: absolute;
    right: 16px;
    top: 50%;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
  }
}

.daily-balances {
  &__description {
    font-size: 12px;
    line-height: 150%;
    color: $gray;
    margin-bottom: 24px;
  }

  &__label,
  label {
    color: $appleBlack;
    letter-spacing: 0.02em;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
    display: inline-block;
  }

  &__date {
    color: $grayText;
    font-size: 14px;
    line-height: 16px;
    display: block;
  }

  &__values {
    margin-bottom: 16px;
    max-height: 380px;
    overflow: scroll;
  }

  &__footer {
    margin-top: 20px;
  }
}

.closed-marker {
  .closed {
    color: $gray;
  }
}

.closed-account {
  color: $gray !important;
}

.locked-area {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 19;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: $radiusL;
  width: 98%;
  margin: auto;

  a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
