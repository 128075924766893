.not-found-content-wrapper {
  width: 100%;
  height: 700px;
  text-align: center;
  position: relative;
  .not-found-svg-wrapper {
    width: 100%;
    margin-bottom: -40%;
    & > svg {
      margin-left: -10%;
      width: 100%;
      max-height: 100%;
    }
  }
  .nf-text-btn-wrapper {
    position: absolute;
    bottom: 30%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: $md) {
  .not-found-content-wrapper {
    width: 100%;
    height: 500px;
    text-align: center;
    position: relative;
    .not-found-svg-wrapper {
      width: 100%;
      height: 100%;
      margin-bottom: -10%;
      & > svg {
        width: 100%;
        max-height: 100%;
      }
    }
    .nf-text-btn-wrapper {
      position: absolute;
      bottom: 30%;
    }
  }
}

//Recently added
@media (max-width: $lg+1) and (min-width: $md+1) {
  .not-found-content-wrapper .nf-text-btn-wrapper {
    position: absolute;
    bottom: 16%;
    left: 50%;
  }
}
