.mm-profile-overview {
  p {
    font-weight: 400;
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 0;
    color: $darkGray;

    @media (min-width: $md) {
      margin-bottom: 1rem;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.25rem;

    .mm-radio-block {
      font-size: 1rem;
      min-width: 11rem;
    }

    .mm-asset-allocation {
      p {
        @media (max-width: $md + 224) {
          max-width: 27rem;
        }
      }
    }
  }

  &__title-pp {
    padding-top: 20px;
  }

  &__profile-page {
    &--tag {
      background: $purpleOpacity16;
      border-radius: 6px;
      font-weight: 400;
      display: inline-block;
      float: right;
      padding: 2px 5px;
      font-size: 1rem;

      @media (min-width: $sm) {
        float: none;
      }

      @media (max-width: $md - 1) {
        margin-left: 1rem;
      }
    }
  }

  &__form {
    font-weight: 400;
    font-size: 1rem;

    .mm-prepend-text {
      position: absolute;
      top: 8px;
      left: 28px;
    }

    .mm-username-input-form {
      padding: 0.375rem 0.76rem 0.375rem 1.75rem;
    }

    .mm-form-textarea {
      resize: none;
      height: 7.75rem;
      padding: 16px 20px;
    }

    .mm-form-website {
      color: $primary !important;
    }

    .mm-setting-form-info {
      @media (max-width: $sm) {
        float: unset;
      }
    }

    ::placeholder {
      color: $placeholder;
    }
  }

  .mm-radial {
    &__progress-bar {
      border-radius: 50%;
      width: 3.75rem;
      height: 3.75rem;
      display: flex;

      &--overlay {
        border-radius: 50%;
        width: 3.438rem;
        height: 3.438rem;
        margin: 2px 3px;
        background: $white;
        text-align: center;
        padding-top: 25%;
        color: $green;
      }
    }

    &__progress-bar-progress {
      background-image: linear-gradient(35deg, $green 50%, transparent 50%),
        linear-gradient(-90deg, $green 50%, $grayOpacity20 50%);
    }
  }
}

.card-sub-title {
  color: $grayText;
  margin-bottom: 1.6875rem;
  margin-top: 0.75rem;
  font-size: 1rem;
  font-weight: 400;
}
.card-description {
  color: $grayText;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 10px 0 20px 0;
}
.card-section {
  margin-bottom: 2.5rem;

  @media (min-width: $md) {
    margin-bottom: 0;
  }

  &--last {
    margin-bottom: 0.375rem;

    @media (min-width: $md) {
      margin-bottom: 0;
    }
  }
}
.profile-pic-large {
  border: 4px solid $grayBorder20;

  &:hover {
    -webkit-filter: brightness(90%);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
}
.mm-button-container {
  max-width: 830px;
  width: 100%;
  padding-right: 0.938rem;
  padding-left: 0.938rem;
  margin: 0 auto;
}
