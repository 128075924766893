body {
  font-family: 'Mulish', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  overflow-x: hidden;
  background-color: $lightBg;
}

p {
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 150%;
}

h1 {
  font-size: 3.3rem;
  font-weight: 300;
}

h3 {
  font-weight: 300;
  font-size: 2.25rem;
  line-height: 2.8125rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-weight: 300 !important;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.form-group {
  @extend .form-group;
  position: relative;
}

.form-control {
  height: 48px;
  font-size: 0.875rem;
  border: 1px solid $grayOpacity24;
  border-radius: $radiusS;
  padding: 0 20px 0 20px;
  color: $darkGray;
  width: 100%;
  &:active,
  &:focus {
    border: 1px solid $gray;
  }
  &::placeholder {
    color: $placeholder;
  }

  &__no-icon {
    background-image: unset !important;
  }
  @media (min-width: $med) {
    padding: 0 30px 0 20px;
  }
}

textarea.form-control {
  min-height: 100px;
}

.link-red {
  color: $redText;
  &:hover {
    color: $redText;
  }
}
.link-gray {
  color: $grayText;
  &:hover {
    color: $darkGray;
  }
}
.light {
  opacity: 0.6;
}

.light-80 {
  opacity: 0.8;
}

.mm-container {
  padding: 0;
  margin-left: 120px;
  margin-right: 120px;
}

.mm-container-right {
  margin-left: 120px;
}

// Buttons
.btn {
  border-radius: $radiusS;
}

.mm-btn {
  @extend .btn;
  min-width: 180px;
  height: 48px;
  border-radius: $radiusS;
}

.mm-btn-xs {
  @extend .btn;
  width: 76px;
  height: 29px;
  padding: 3px;
  font-size: 1.125rem;
  line-height: 23px;
}

.mm-btn-signup {
  @extend .mm-btn;
  width: 160px;
  min-width: 100px;
  &:hover {
    box-shadow: 0 4px 12px $purpleShadow;
  }
}

.mm-btn-spinner {
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;

  svg {
    height: 28px !important;
    width: 28px !important;
    margin-top: unset !important;
  }
}

.mm-btn-animate {
  @extend .mm-btn;
  transition: 0.3s;
  border-radius: $radiusS;
  &:hover {
    transform: translateY(-6px);
    color: $white;
  }
}

.mm-btn-txt-delete {
  @extend .mm-btn;
  color: $redText;
  &:hover {
    text-decoration: underline;
    color: $redText;
  }
}

.mm-btn-animate.add-manual-account:hover {
  color: inherit;
}

.mm-btn-primary {
  color: $white;
  background: $purple;
  &:hover {
    box-shadow: 0 4px 12px $purpleShadow;
    background: $purple;
    border: $purple;
  }
  &:disabled {
    background: $gray;
    border: $gray;
  }
  &[disabled]:hover {
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  &.plus-btn {
    height: 40px;
    width: 120px;
    min-width: 100px;
    font-size: 0.875rem;
    display: table;
  }
  &.improve-comparison {
    width: 100%;
    border-radius: 0 0 $radiusS $radiusS;
    bottom: 0;
    position: absolute;
  }
}

.btn-danger {
  &:hover {
    box-shadow: 2px 4px 8px $pinterestRedGlow;
    background-color: $redAlert;
    border-color: $redAlert;
  }
}

.mm-btn-primary-outline {
  color: $lightBg;
  border: 1.5px solid $white;
  box-sizing: border-box;
  border-radius: $radiusS;
  &:hover {
    background: $lightBg;
    color: $purple;
    box-shadow: 0 4px 12px $whiteOutlineButtonShadow;
  }
  &:disabled {
    opacity: 0.7;
  }
  &[disabled]:hover {
    box-shadow: none;
    cursor: not-allowed;
  }
}

.mm-btn-white {
  color: $purple;
  background: $white;
  font-size: 0.875rem;
  &:hover {
    color: $white;
    background: $purple;
  }
}

.mm-btn-crypto {
  background: $pinkAlert;
  display: inline !important;
  margin-right: 5px;
}

.btn-icon-purple {
  border-radius: 50%;
  &:hover {
    box-shadow: 0 4px 12px $purpleShadow;
    border-radius: 50%;
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: $white;
  background-color: $purple;
  border-color: $purple;
}
.btn-primary:focus,
.btn-primary.focus {
  color: $white;
  background-color: $purple;
  border-color: $purple;
  box-shadow: none;
}
.clear-filter {
  margin-right: 1rem;
  background: none;
  font-weight: 500;
  font-size: 0.875rem;
  color: $redAlert;
  border-radius: $radiusS;
  border-color: $redAlertOpacity24;
  width: auto;
  height: 48px;
  padding: 0 20px;
  min-width: 122px;
  &:focus,
  &:hover,
  &:active {
    background: none !important;
    box-shadow: none !important;
    color: $red !important;
    border-color: $red;
  }

  @media (max-width: $md) {
    display: none;
  }
}

.container-mm {
  max-width: 1200px;
  width: auto !important;
  margin: auto;
}

.container-fluid {
  padding: 0 40px;
}

.drop-box.dropdown {
  border-radius: $radiusS;
  color: $grayText;
  width: 100%;
  &:hover,
  &:focus,
  &:active {
    color: $darkGray;
    box-shadow: none;
  }
  &:after {
    color: $gray;
    &:hover {
      color: $darkGray;
    }
  }
  @media (min-width: $md) {
    width: unset;
  }
}

// Forms
.mm-form-row {
  width: 100%;
  justify-content: space-between;
  display: block;
  @media (min-width: $md) {
    display: flex;
  }
}

.mm-form-row-double {
  width: 100%;
  justify-content: space-between;
  @media (min-width: $md) {
    width: 47%;
  }
}

.mm-form-field {
  height: 48px;
  font-size: 0.875rem;
  border: 1px solid $grayOpacity24;
  border-radius: $radiusS;
  padding: 0 20px;
  color: $darkGray;
  width: 100%;
  &:active,
  &:focus {
    border: 1px solid $gray;
  }
  &::placeholder {
    color: $placeholder;
  }
}

.mm-form-field-read {
  display: block;
  font-weight: 500;
  font-size: 1rem;
  color: $grayText;
  padding-top: 10px;
}

.mm-form-field-error {
  font-size: 0.75rem;
  padding-top: 10px;
}

button.dropdown-toggle {
  text-align: left;
  padding-left: 20px;
}

.data-label,
.data-value {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  color: $grayText;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.data-label {
  font-weight: 600;
  min-width: 111px;
}

// message wrapper
.message-container {
  color: $white;
  box-shadow: 0 2px 8px $whiteFadeShadow;
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 3;
  left: 0;
}

.message-container.success {
  background-color: $green;
}

.message-container.error {
  background-color: $redAlert;
}

.message-wrapper {
  padding: 1rem 1rem;
  @media (min-width: $sm) {
    padding: 1rem 2rem;
  }
  @media (min-width: $md) {
    padding: 1rem 4rem;
  }
  @media (min-width: $lg) {
    padding: 1rem 6rem;
  }
}

.icon {
  cursor: pointer;
}

@media (max-width: $lg) {
  .mm-container-right {
    overflow: hidden;
  }
}

@media (min-width: ($lg+1)) {
  .mm-container {
    max-width: 100%;
  }
  .mm-container-right {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 36px 120px 74px 56px;
  }
}

@media (max-width: $md) {
  .mm-container {
    margin-left: 80px;
    margin-right: 80px;
  }
}

@media (max-width: $sm) {
  h1 {
    font-size: 2.25rem !important;
  }
  .mm-container {
    margin-left: 20px;
    margin-right: 20px;
  }
  .mm-container-right {
    margin-left: 20px;
  }
}

.mm-overview-wrapper {
  background-image: url('../images/background-shadow.svg');
  background-size: 50%;
  background-position: top right;
  background-repeat: no-repeat;
  @media (max-width: ($md + 400)) {
    background-size: 100%;
  }
}

// switch checkbox
.mm-switch-block {
  .mm-switch-input {
    display: none;
  }

  .mm-switch-text {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 18px;
    text-align: center;
    color: $gray;
    white-space: nowrap;
  }
  .mm-switch {
    width: 2.5rem;
    height: 1.375rem;
    border-radius: 12.5px;
    margin: 0;
    border: 1px solid $grayOpacity20;
    box-sizing: border-box;
    display: block;
    position: relative;
    &:hover {
      cursor: pointer;
    }
    &:after {
      content: '';
      height: 1.125rem;
      width: 1.125rem;
      border-radius: 9px;
      display: block;
      position: absolute;
      top: 1px;
      left: 1px;
      background: $grayOpacity70;
      box-shadow: 0 2px 2px $purpleOpacity30;
      transition: ease-out 0.2s;
    }
  }
  input[type='radio'] {
    &:checked {
      & + label {
        &:after {
          left: calc(100% - 19px);
          background: $primary;
        }
      }
    }
  }
  input[type='checkbox'] {
    &:checked {
      & + label {
        &:after {
          left: calc(100% - 19px);
          background: $primary;
        }
      }
    }
  }
}

// custom radio button
.mm-radio-block {
  display: flex;
  .mm-radio {
    position: relative;
    padding-left: 2.188rem;
    cursor: pointer;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      &:checked {
        & ~ .mm-checkmark {
          &:after {
            display: block;
          }
        }
      }
    }
    .mm-checkmark {
      &:after {
        top: 5px;
        left: 5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $primary;
      }
    }
  }
  .mm-checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    background-color: $white;
    border: 1px solid $purpleOpacity20;
    border-radius: 50%;
    &:after {
      content: '';
      position: absolute;
      display: none;
    }
  }
}

.form-control:focus {
  box-shadow: none;
}

.form-control-lg {
  font-size: 1rem;
}

.form-control:disabled {
  color: $gray !important;
}

// hide block, div for small screen
.sm-hide {
  @media (max-width: ($sm + 1)) {
    display: none !important;
  }
}
.med-hide {
  @media (max-width: ($med + 1)) {
    display: none !important;
  }
}
.med-show {
  @media (min-width: ($med + 1)) {
    display: none !important;
  }
}
.md-hide {
  @media (max-width: ($md - 1)) {
    display: none !important;
  }
}
.md-show {
  @media (min-width: $md) {
    display: none !important;
  }
}
.mm-md-hide {
  @media (min-width: $md) {
    display: none;
  }
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0;
}

.mm-overview-block {
  background: $lightBg;
}

// action overlay
.action-overlay {
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
  border: none;
  position: fixed;
  height: 80px;
  width: 100%;
  left: 0;
  bottom: 0;
  margin: 0;
  z-index: 2;
  word-wrap: break-word;
  background-color: $white;
  background-clip: border-box;
  border-radius: $radiusS;
  padding: 1rem 2.5rem;
  button {
    @extend .mm-btn-animate;
    @extend .mm-btn-primary;
    align-self: flex-end;
    width: 180px;
    height: 48px;
    &:hover {
      background-color: $purple;
    }
  }
}

.hide-me {
  display: none;
}

.hide-sm {
  display: none;
  @media (min-width: $med) {
    display: inline;
  }
}

// coming soon badge
.mm-coming-soon {
  font-size: 0.75rem;
  color: $purple;
  background-color: $purpleOpacity16;
  font-weight: 700;
  border-radius: 6px;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.1em;
  padding: 4px 10px;
}
.tag {
  font-size: 0.625rem !important;
  color: $purple !important;
  background-color: $purpleOpacity10;
  font-weight: 600;
  border-radius: $radiusXS;
  line-height: 18px;
  letter-spacing: 0.1em;
  padding: 4px 10px;

  @media (min-width: $med) {
    font-size: 0.75rem !important;
  }
}
// dropdowns
select {
  appearance: none;
  /* Some browsers will not display the caret when using calc, so we put the fallback first */
  background: url('../images/signup/arrow.svg') white no-repeat 98.5% !important;
  /* !important used for overriding all other customisations */
  background: url('../images/signup/arrow.svg') white no-repeat calc(100% - 10px) !important;
  /* Better placement regardless of input width */
  display: table;
  width: 100%;
  height: 48px;
  padding: 1px 16px;
  border: 1px solid $grayOpacity24;
  border-radius: $radiusS;
  font-size: 0.875rem;
  color: $darkGray;
  &:focus,
  &:hover {
    outline: none;
    border: 1px solid $gray;
  }
}

.dropdown-select-input {
  .checkbox-list {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 500px;
    margin-bottom: 0;
    li {
      width: 100%;
      margin: 0;
    }
  }
}
.account-types-dropdown {
  max-height: 370px;
  overflow-y: scroll;
}

// Remove Safari Arrows on Number Fields
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type='number'] {
  -moz-appearance: textfield !important;
}

.balance-table {
  min-height: 200px;
  &.table-account-xls {
    min-height: 320px;
  }
  .no-hover {
    pointer-events: none;
  }
  .more-icon-dropdown {
    position: absolute;
    display: block;
    right: 0;
    border-bottom: none;
    .no-arrow.dropdown-toggle {
      display: flex;
      float: right;
      &:after {
        border: 0;
      }
      &:focus,
      &.focus {
        box-shadow: none !important;
      }
    }
    .dropdown-menu {
      transform: none !important;
      right: 0 !important;
      left: auto !important;
      .dropdown-item {
        color: $white;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        padding: 12px 26px;
        &:hover {
          background-color: rgba(83, 76, 234, 0.3);
        }
        &:first-child {
          margin-top: 12px;
        }
        &:last-child {
          margin-bottom: 12px;
        }
      }
    }
  }
}

.search-input {
  position: relative;

  &__logo {
    width: 28px;
    height: 28px;
    margin-right: 8px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  &--highlighted {
    padding-left: 56px;
  }

  &__list {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    background: $darkBg;
    box-shadow: 0 4px 28px rgba(8, 24, 51, 0.44);
    border-radius: $radiusS;
    max-height: 200px;
    overflow-y: auto;
    z-index: 100;
  }
}

.currency-control {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    vertical-align: middle;
    width: 8px;
    height: 8px;
    border: 1px solid $gray;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg);
    transition: all 0.3s;
    top: 1.125rem;
    right: 1.125rem;
  }

  &--highlight::after {
    top: 1.25rem;
    transform: rotate(-135deg);
  }

  &__list {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    min-width: 240px;
    max-height: 240px;
    overflow-y: auto;
    z-index: 10;
    background-color: $darkBg;
    padding: 16px 0px;
    border-radius: $radiusS;

    &-item {
      display: flex;
      width: 100% !important;
      align-items: center;
      cursor: pointer;
      color: $darkGray;
      padding: 12px 16px;
      margin: 0px !important;

      img {
        width: 24px;
        height: 24px;
      }

      .currency-abbr {
        padding: 0 8px;
        display: inline-block;
        color: $white;
        font-weight: 500;
        font-size: 16px;
      }

      .currency-name {
        display: inline-block;
        font-size: 13px;
        font-weight: 600;
      }

      &:hover {
        background-color: rgba(83, 76, 234, 0.3);
      }
    }
  }

  &__img-load {
    width: 24px;
    height: 24px;
  }

  &__img-container {
    width: 24px;
    height: 24px;
    display: flex;
  }
}

// CUSTOM CHECK-BOX

.checkbox-wrapper {
  position: relative;
  font-size: 0.875rem;
  color: $black;
  font-weight: 400;
  vertical-align: middle;
  display: flex;
  align-items: center;

  .custom-checkbox {
    cursor: pointer;
    font-size: 1.375rem;
    position: absolute;
    top: 2px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input:checked ~ .checkmark {
      background-color: #fff;
      &:after {
        display: block;
        color: $primary;
        margin-left: 6px;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      font-size: 0.875rem;
      border: 1px solid rgba(150, 158, 172, 0.239216);
      border-radius: $radiusS;
      padding: 0 50px 0 20px;
      color: #434a55;
    }

    .checkmark {
      position: absolute;
      top: -2px;
      left: 0;
      height: 1.25rem;
      width: 1.25rem;
      border: 1px solid rgba(83, 76, 234, 0.160784);
      box-sizing: border-box;
      border-radius: 0.25rem;
      font-size: 0.875rem;
      color: #000;
      font-weight: 600;

      &:after {
        content: '';
        left: 6px;
        top: 1px;
        width: 6px;
        height: 0.75rem;
        border: solid #534cea;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        display: none;
      }
    }
  }

  .checkbox-text {
    padding-left: 32px;
  }
}

.gradient-spinner {
  animation: spin 1s linear infinite;
  width: 32px;
  height: 32px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn-copy {
  border: none;
  background: transparent;

  span {
    color: $green;
    margin-right: 4px;
    font-size: 0.875rem;
  }
}

.show {
  display: block;
}

.mm-loader {
  border: 2px solid $gray;
  border-top: 2px solid $grayOpacity24;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 2s linear infinite;
  position: absolute;
  left: -20px;
  top: 18px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mm-range-selector {
  padding: 32px 24px 48px;

  * {
    font-family: 'Mulish', sans-serif !important;
  }

  .input-range__track {
    height: 0.2rem;
    background-color: rgba($color: $white, $alpha: 0.2);
  }

  .input-range__track--active {
    background: $purple;
  }

  .input-range__slider {
    background: $purple;
    border: none;
    box-shadow: 0 0 0 6px rgba($color: $purple, $alpha: 0.3);
    width: 10px;
    height: 10px;
    margin-top: -0.4rem;
  }

  .input-range__label-container {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: rgba($color: $white, $alpha: 0.5);
  }

  .input-range__track .input-range__label-container {
    display: none;
  }

  .input-range__label--max .input-range__label-container {
    margin-right: 16px;
  }

  .current-values-container {
    margin-bottom: 32px;
    font-size: 18px;
    line-height: 23px;
    color: $white;
    display: flex;
    justify-content: center;

    .separator {
      display: inline-block;
      margin: 0 8px;
    }
  }
}

.radio--scroll {
  max-height: 370px;
  overflow-y: auto;
}

.hov-disabled-primary {
  &:disabled {
    &:hover {
      color: $primary;
    }
  }
}
