.modal-xs,
.modal-sm,
.modal-md,
.modal-mdx,
.modal-lg,
.modal-xl,
.modal-xxl,
.modal-fastlink,
.modal-saltedge {
  overflow: hidden;
}

.modal-xs {
  max-width: 100%;

  @media (min-width: $md) {
    max-width: 372px;
  }
}

.modal-sm {
  max-width: 430px;
}

.modal-md {
  max-width: 520px;
}

.modal-mdx {
  max-width: 650px;
}

.modal-lg {
  max-width: 700px;
}

.modal-xl {
  max-width: 740px;
}
.modal-xxl {
  max-width: 1140px;
}
.modal-fastlink {
  max-width: 372px;

  .modal-content {
    padding: 0;
    height: 100%;
  }
  .modal-body {
    padding: 0;
  }
}
.modal-saltedge {
  max-width: 480px;

  .modal-content {
    padding: 0;
    height: 100%;
  }
  .modal-body {
    padding: 0;
  }
}

.modal-show {
  display: block;
}

.mm-modal-backdrop {
  @extend .modal-backdrop;
  background-color: transparent;
  overflow-y: scroll;
}

.modal-btn-wrapper {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.mm-modal-backdrop {
  backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.1);
}

.mm-modal-backdrop.in {
  opacity: 1 !important;
}

button.hidden {
  display: none;
}

//Associate modal
.modal-content {
  border: none;
  background: #ffffff;
  box-shadow: 2px 2px 16px #c2c9d6, inset 0px -1px 0px #ebecf1;
  border-radius: $radiusS;
  margin: 0 auto;
  padding: 1rem 0.75rem 2.5rem 0.75rem;

  &.loading {
    padding: 0;
  }

  @media (min-width: $sm) {
    padding: 1rem 0.75rem 2.5rem 0.75rem;
  }

  @media (min-width: $md) {
    padding: 1rem 1.5rem 2.5rem 1.5rem;
  }
}

.modal-body {
  padding: 0;

  @media (min-width: $sm) {
    padding: 0 1rem;
  }
}

.modal-wrapper .modal-btn-wrapper {
  padding: 0;
  margin: 0;
}

.modal-wrapper .modal-btn-wrapper button {
  width: 100%;
  display: block;
}

.close {
  position: absolute;
  right: 24px;
  top: 24px;
  width: 24px;
  height: 24px;
  padding: 0 !important;
  margin: 0 !important;

  @media (min-width: $md) {
    right: 24px;
    top: 24px;
  }
}

.modal-close {
  color: $gray;

  &:hover {
    color: $darkGray;
  }
}

.modal-header {
  border-bottom: none;
  padding: 1rem 0;

  @media (min-width: $sm) {
    padding: 1rem 0.5rem;
  }

  @media (min-width: $md) {
    padding: 1rem;
  }

  &.has-back {
    justify-content: flex-start;
    display: block;

    .modal-title {
      margin-left: 50px;
    }

    .back {
      position: absolute;
      left: 8px;
      top: 38px;
      padding: 0 !important;
      margin: 0 !important;

      @media (min-width: $sm) {
        left: 24px;
      }

      @media (min-width: $md) {
        left: 32px;
      }
    }
  }
}

.close:hover {
  color: $darkGray;
}

.modal-title {
  font-size: 1.625rem;

  @media (min-width: $sm) {
    font-size: 1.75rem;
  }
}

.modal-wrapper p {
  font-size: 1rem !important;
  color: $grayText;
}

.modal-wrapper .create-new-btn p {
  font-size: 0.87rem;
  color: $gray;
  text-align: center;
  margin-top: 28px;
  display: inline-block;
  font-weight: 400;
  padding-bottom: 0;

  &:hover {
    color: $darkGray;
  }
}
.help-text {
  color: $darkGray;
  font-size: 0.875rem;
  background-color: $purpleOpacity10;
  padding: 20px;
  border-radius: $radiusS;
  font-weight: 400;
  max-width: 350px;
}

//signup modal

.modal-wrapper.signup-modal .modal-btn-wrapper button {
  display: inline-block;
  width: auto;
  padding: 11px 22px;
  border-radius: $radiusS;
}
.signup-done-modal-logo {
  margin-bottom: 20px;
  max-height: 40px;
  display: flex;
  text-align: left;

  img {
    max-height: 40px;
  }
}
.modal-wrapper.signup-modal h4 {
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 25px;
}
.modal-wrapper.signup-modal .modal-btn-wrapper {
  padding: 0;
  margin: 0;
  align-content: center;
  justify-content: flex-start;
  align-items: baseline;
}
.modal-wrapper.signup-modal .modal-btn-wrapper button {
  margin-right: 40px;
}

@media (max-width: $sm) {
  .modal-wrapper.signup-modal .modal-btn-wrapper {
    display: block;
    width: 100%;
  }
  .modal-wrapper.signup-modal .modal-btn-wrapper button {
    margin-right: 0;
    width: 100%;
  }
  .modal-dialog {
    width: 94%;
  }
}
.mm-form {
  input {
    height: 48px;
    font-size: 0.875rem;
    border: 1px solid $grayOpacity24;
    border-radius: $radiusS;
    padding: 0 20px;
    color: $darkGray;

    &::placeholder {
      color: $placeholder;
    }
  }

  select {
    background: none;
    font-weight: 500;
    font-size: 0.875rem;
    color: $grayText;
    border: 1px solid $grayOpacity24;
    border-radius: $radiusS;
    height: 48px;
    padding: 0 20px;
  }
}
.modal-button-and-text-link {
  align-content: center;
  justify-content: flex-start;
  align-items: baseline;

  .mm-btn-primary,
  button {
    margin-right: 2.5rem;
    width: auto !important;
    padding: 0 30px;
  }
}
#fastlinkContainer {
  max-height: 670px !important;
  border-radius: $radiusS;
  overflow-y: auto;

  iframe {
    border-radius: $radiusS;
  }
}
.fastlink-modal-container {
  min-height: 50px;
  max-height: 670px;
  overflow-x: scroll;
  border-radius: $radiusS;
}
.saltedge-modal-wrapper {
  iframe {
    height: 100%;
    width: 100%;
    min-height: 590px;
  }
}
.account-added-modal {
  h1 {
    font-size: 32px;
  }
  .account-modal-img-wrapper {
    width: 187px;
    height: 35px;
    position: relative;

    img {
      position: absolute;
      left: -30%;
      top: -70%;
      width: 100%;
      height: 100%;
    }
  }
  .modal-btn-wrapper {
    margin-top: 3.5rem !important;
  }
}

.mm-modal-two-buttons {
  display: block;

  @media (min-width: $med) {
    display: flex;
  }

  button {
    min-width: 100px;
  }
}

.crypto-connection-modal-wrapper {
  .wallet-wrapper {
    margin-top: 20px;
    max-height: 452px;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
  }

  .security-images-container {
    margin-top: 40px;

    .security-images-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  .wallet-card {
    display: inline-flex;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    &:not(.disabled) {
      @media (min-width: $list) {
        width: 50%;
      }
    }

    &.disabled {
      pointer-events: none;
    }

    .logo-wrapper {
      width: 125px;
      min-width: 125px;
      height: 80px;
      padding: 4px;
      border-radius: $radiusS;
      border: 1.5px solid $purpleOpacity24;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      &.contain {
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }

      &:hover {
        border: 1.5px solid $purple;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .wallet-info {
      span {
        display: block;
      }
      padding-left: 14px;

      .wallet-name {
        font-size: 1.125rem;
        font-weight: 500;
      }

      .wallet-website {
        color: $grayText;
      }
    }
  }

  .rest-info {
    margin-top: 40px;

    .rest-info-title {
      color: $primary;
      font-size: 1rem;
      font-weight: 500;
    }

    .rest-info-text {
      margin-top: 6px;
      font-size: 1rem;
      color: $grayText;
      max-width: 560px;
    }
  }

  .connect-crypto-wrapper {
    max-width: 400px;
    margin-top: 20px;

    .key-info {
      color: $darkGray;
      font-size: 0.875rem;
      background-color: $purpleOpacity10;
      padding: 20px;
      border-radius: $radiusS;
      font-weight: 400;
      max-width: 350px;
      margin-top: 26px;
    }

    .connect-button-wrapper {
      margin-top: 40px;

      &.multiple {
        button {
          min-width: 50px;
        }
      }

      &.multiple-connections {
        display: flex;
        button {
          min-width: 50px;
          height: auto !important;
        }
      }

      button + button {
        margin-left: 20px;
      }
    }

    .error-block {
      background-color: $pink;
      color: $white;
      margin-top: 2rem;
      padding: 1rem;
      border-radius: 0.5rem;
    }

    .crypto-action-wrapper {
      display: flex;
      margin-top: 40px;
      flex-direction: column;

      button {
        width: 100%;
      }

      button + button {
        margin-top: 20px;
      }

      @media (min-width: $med) {
        flex-direction: row;

        button {
          width: 45%;
        }

        button + button {
          margin: unset;
          margin-left: 30px;
        }
      }
    }

    .terra-disconnect-button {
      margin-top: 20px;
      width: 100%;
      text-align: center;
    }
  }

  .terra-station-wallet-loading {
    margin-block: 2rem;
  }

  .sub-title {
    font-size: 1rem;
    line-height: 150%;
    color: $grayText;
  }

  .chain-accounts-wrapper {
    margin-top: 20px;
    .key-info {
      margin-bottom: 1rem;
    }

    .chain-title {
      font-size: 1rem;
      color: $primary;
      margin-top: 0.5rem;
    }

    p {
      font-size: 0.875rem;
      padding-top: 0;
      margin-bottom: 0.1rem;
      color: $grayText;
    }
  }
}

.crypto-connection-modal-iframe {
  width: 100%;
  min-height: 500px;
}

.crypto-connection-details {
  max-width: 364px;
  font-size: 1rem;
  color: $grayText;

  .key-steps {
    margin-top: 26px;

    li {
      display: flex;
      .step-text {
        padding-left: 8px;
      }
    }
  }
}
