.card {
  border: none;
}

.mm-setting {
  background: $lightBg;
  .mm-settings-container {
    max-width: 830px;
    width: 100%;
    padding-right: 0.938rem;
    padding-left: 0.938rem;
    margin: 1.375rem auto 3rem auto;
    @media (min-width: $md) {
      margin: 2.5rem auto 3.75rem auto;
    }
    .mm-settings-title {
      max-width: 830px;
      font-weight: 400;
      font-size: 1.125rem;
      overflow: hidden;
      margin: auto;
      color: $gray;
      @media (max-width: $md - 1) {
        overflow-x: scroll;
        scroll-snap-type: x proximity;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      &--list {
        display: flex;
        flex-direction: row;
        align-items: center;
        list-style: none;
        padding: 0;
        overflow: auto;
      }
      &--type {
        margin-right: 5rem;
        padding: 0 0.125rem 0.875rem 0.125rem;
        height: 41px;
        &.active,
        &:hover {
          border-bottom: 1.5px solid $primary;
          a {
            color: $darkGray;
          }
        }
      }
      &--link {
        color: $grayText;
      }
      &--bar {
        margin-top: -1rem;
        border-bottom: 1px solid $grayOpacity24;
      }
    }
  }
  .mm-setting-card {
    box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
    border-radius: $radiusM;
    margin-top: 1.5rem;
    padding: 1rem 1rem 1rem 1rem;
    margin-bottom: 0.75rem;
    @media (min-width: $med) {
      padding: 1.5rem 2.5rem 1.5rem 2.5rem;
    }
    svg {
      cursor: pointer;
    }
    &--title {
      font-weight: $normal;
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
      span {
        font-weight: $lighter;
        font-size: 0.875rem;
      }
    }
    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
      color: $darkGray;
      background-color: transparent;
      border-color: $gray;
    }
    .drop-box .dropdown-toggle:after {
      right: 1.813rem;
    }
    &--subscription {
      color: $redText;
      font-weight: 500;
      font-size: 1.125rem;
    }
    .card-body {
      padding: 1.5rem 1.25rem;
      @media (min-width: $md) {
        padding: 1.5rem 2.5rem;
      }
      &.connected {
        border-left: 3px solid $green;
        border-radius: $radiusS;
      }
      &.manual {
        border-left: 3px solid $blue;
        border-radius: $radiusS;
      }
    }
    input {
      color: $darkGray;
    }
  }
  .mm-button {
    width: 200px;
    font-size: 0.875rem;
    font-weight: 600;
    border: 1.5px solid $purple;
    height: 48px;
    &__flat {
      padding: 0;
      border: 0;
      &:focus {
        box-shadow: none;
      }
    }
    &:disabled {
      cursor: not-allowed;
      background: none;
      color: $grayText;
      border: 1.5px solid $grayText;
    }
    @media (max-width: $md - 1) {
      width: 100%;
      height: 48px;
    }
  }
  .mm-setting-form-info {
    font-size: 0.875rem;
    color: $grayText;
    margin-left: 2rem;
    font-weight: 400;
    line-height: 1.625rem;
    text-align: right;
    @media (min-width: $md) {
      text-align: left;
    }
  }
  .mm-setting-form-pw {
    border: none;
    color: $gray;
  }
  .custom-checkbox {
    cursor: pointer;
    font-size: 1.375rem;
    position: absolute;
    top: 2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    border: 1px solid $purpleOpacity16;
    box-sizing: border-box;
    border-radius: 0.25rem;
  }
  .custom-checkbox input:checked ~ .checkmark {
    background-color: $white;
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  .custom-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  .custom-checkbox .checkmark:after {
    left: 6px;
    top: 1px;
    width: 5px;
    height: 0.75rem;
    border: solid $purple;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
  }
  .mm-setting-footer {
    border-top: 1px solid $grayOpacity24;
    font-weight: $lighter;
    font-size: 0.938rem;
    height: 4.75rem;
    clear: both;
    position: relative;
    color: $gray;
    &--block {
      display: flex;
      padding: 1.75rem 1.5rem;
      justify-content: space-between;
      @media (max-width: $sm) {
        display: block;
      }
      @media (max-width: $md - 1) {
        display: block;
      }
    }
    &--list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      list-style: none;
      margin: 0;
      @media (max-width: $md) {
        flex-wrap: wrap;
        justify-content: start;
        padding: 0;
        margin-top: 0.5rem;
      }
      li {
        margin: 0 1.25rem;
        @media (max-width: $lg - 1) {
          margin: 0 0.5rem;
        }
        @media (max-width: $md) {
          margin: 0 0.5rem;
        }
        @media (max-width: $sm) {
          margin: 0.5rem 1.5rem 0.5rem 0;
        }
        a {
          color: $grayText;
        }
      }
    }
  }

  .mfa-container {
    display: flex;
    padding-top: 1rem;
    align-items: flex-start;
    justify-content: space-between;

    .mfa-text-container {
      p {
        color: $grayText;
        font-size: 0.875rem;

        @media (min-width: $sm) {
          max-width: 75%;
        }
      }
    }
  }

  .add-mfa-modal-wrapper {
    p {
      color: $grayText;
    }
    .mfa-error-resend-wrapper {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      align-items: center;

      .error-text-wrapper {
        color: $redAlert;
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;
      }

      .resend-code {
        text-align: right;
        color: $primary;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .check-inbox-wrapper {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin-top: 6px;
        color: $green;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .modal-btn-wrapper {
      margin-top: 40px;
    }
  }

  .disable-mfa-modal-wrapper {
    color: $grayText;
    .mm-btn-text {
      color: $grayText;
      background: none;
      border: none;
      &:hover {
        color: $darkGray;
      }
    }
    p {
      color: $grayText;
    }
    .modal-btn-wrapper {
      margin-top: 32px;
    }
  }
}

.mm-btn-settings {
  @extend .mm-btn-signup;
  @extend .btn-outline-primary;
  height: 40px;
  width: 100%;
  float: right;
  font-size: 0.875rem;
  font-weight: 400;
  @media (min-width: $md) {
    width: 200px;
  }
  &:disabled {
    &:hover {
      box-shadow: none;
    }
  }
}

.mt-vl {
  border-left: 2px solid $grayOpacity24;
  height: 2.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.bottom-fixed {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  margin: 0;
  z-index: 2;
}

.change-password-modal {
  max-width: 100%;
  h4 {
    font-size: 1.75rem;
  }
  .input-wrapper {
    padding-top: 1rem;
    label {
      display: block;
    }
    .input-wrap {
      position: relative;
      input {
        height: 48px;
        font-size: 0.875rem;
        border: 1px solid $grayOpacity24;
        border-radius: $radiusS;
        padding: 0 50px 0 20px;
        color: $darkGray;
        width: 100%;
        &:active,
        &:focus {
          border: 1px solid $gray;
        }
        &::placeholder {
          color: $placeholder;
        }
      }
      .visibility-icon {
        position: absolute;
        right: 1rem;
      }
    }
    .feedback {
      text-align: right;
      padding-top: 0.5rem;
    }
  }
  button {
    width: 100%;
    display: block;
    height: 3rem;
    cursor: pointer;
    border: 0;
    border-radius: $radiusS;
    &:hover {
      color: $white;
    }
  }
}

.subscription-cancel-modal {
  button {
    min-width: 164px;
  }
}

.mm-setting-modal {
  &__title {
    font-weight: normal;
    font-size: 1.75rem;
    color: $black;
  }
  &__sub-title {
    font-weight: bold;
    font-size: 1rem;
  }
  &__card {
    background: $modalCardBg;
    border-radius: $radiusS;
  }
  .mm-copy-link {
    position: absolute;
    right: 1.875rem;
    top: 5.5rem;
    cursor: pointer;
  }
  .mm-url-link {
    padding: 0.5rem 2.813rem 0.5rem 1rem;
  }
  .mm-setting-modal-checkbox {
    position: relative;
  }
  .mm-setting-coming-soon {
    background: $purpleOpacity20;
    border-radius: $radiusS;
    p {
      font-weight: 600;
      font-size: 1.125rem;
      color: $purple;
    }
  }
  #mm-allocation-type-modal:checked + label,
  #mm-allocation-asset-modal:checked + label,
  #mm-allocation-country-modal:checked + label,
  #mm-allocation-risk-modal:checked + label {
    color: $primary;
  }
  #mm-allocation-type-modal:checked ~ .mm-radio-bg {
    transform: translateX(0);
    transition: transform 0.2s ease-in-out;
  }
  #mm-allocation-asset-modal:checked ~ .mm-radio-bg {
    transform: translateX(138px);
    transition: transform 0.2s ease-in-out;
  }
  #mm-allocation-country-modal:checked ~ .mm-radio-bg {
    transform: translateX(276px);
    transition: transform 0.2s ease-in-out;
  }
  #mm-allocation-risk-modal:checked ~ .mm-radio-bg {
    transform: translateX(412px);
    transition: transform 0.2s ease-in-out;
  }
  .action-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
    button:nth-child(2) {
      margin-left: 1rem;
    }
  }
}

.subscription-cancel-confirmation {
  z-index: 3;
}

.currency-select {
  button {
    width: 100% !important;
    text-transform: uppercase;
  }
}

.investing-entity {
  font-weight: 400;
  color: $grayText;
  font-size: 1rem;
}

.add-edit-entity-modal-wrapper {
  input {
    height: 48px;
    font-size: 0.875rem;
    border: 1px solid $grayOpacity24;
    border-radius: $radiusS;
    padding: 0 50px 0 20px;
    color: $darkGray;
    width: 100%;
    &:active,
    &:focus {
      border: 1px solid $gray;
    }
    &::placeholder {
      color: $placeholder;
    }
  }
  .action-wrapper {
    display: flex;
    margin-top: 3rem;
    button:first-child {
      margin-right: 1rem;
    }
    button {
      min-width: 168px;
    }
  }
}

.add-entity-section {
  display: block;
  span {
    margin-bottom: 1rem;
  }
  @media (min-width: $md) {
    display: flex;
  }
}

.closed-accounts-description {
  font-weight: 300;
  color: $grayText;
  font-size: 0.875rem;
  margin-top: 8px;

  @media screen and (min-width: $md) {
    width: 45%;
  }
}
