.add-edit-holding-modal {
  .sub-title-wrapper {
    padding-bottom: 2rem;
    span {
      color: $grayText;
    }
  }
  .holding-modal {
    min-height: 200px;

    &__body {
      display: grid;
      column-gap: 2rem;
      grid-template-columns: 1fr;

      .react-datepicker-wrapper {
        color: $grayText;
        width: 100%;
        position: relative;

        input {
          height: 48px;
          font-size: 0.875rem;
          border: 1px solid $grayOpacity24;
          border-radius: 8px;
          padding: 0 50px 0 20px;
          color: $darkGray;
          width: 100%;

          &:read-only {
            opacity: 1;
            background-color: $lightBg;
            border: 1px solid $grayOpacity10;
            color: $gray;
          }
        }
      }

      .dropdown-select-input {
        button {
          width: 100%;
        }
      }

      @media (min-width: $md) {
        grid-template-columns: 1fr 1fr;
      }
    }

    &__action {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @media (min-width: $md) {
        flex-direction: row;
      }
    }
  }

  .financial-input-group {
    position: relative;

    span.input-add-on {
      position: absolute;
      top: 2.25rem;
    }
  }

  .btn-primary {
    &.disabled,
    &:disabled {
      opacity: 1;
      background-color: $lightBg;
      border: 1px solid $grayOpacity10;
      &::after {
        border-color: $grayOpacity40;
      }
      &:hover,
      &:hover {
        opacity: 1;
        background-color: $lightBg;
        border: 1px solid $grayOpacity10;
      }
    }

    &:hover:disabled,
    &:hover.disabled {
      opacity: 1;
      background-color: $lightBg;
      border: 1px solid $grayOpacity10;
    }
  }
}

.add-edit-holding-skeleton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-height: 284px;

  .react-loading-skeleton + .react-loading-skeleton {
    margin-top: 1rem;
    width: 100% !important;
  }
}
