.pincode-input-container {
  width: 100%;
  text-align: center;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .pincode-input-text {
    border-color: $grayOpacity24 !important;
    border-radius: 8px;
    font-weight: 600;
    line-height: 30px;
    color: $darkGray;

    width: 40px !important;
    height: 60px !important;

    @media (min-width: $sm) {
      width: 48px !important;
      height: 60px !important;
      font-size: 24px;
    }
  }
}
