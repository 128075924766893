.large-heading-light {
  font-size: 2.25rem;
  font-weight: 300;
  line-height: 2.8125rem;

  @media (min-width: $md) {
    font-size: 3.5rem;
    line-height: 3.6875rem;
  }
}

.medium-heading-light {
  font-size: 2.25rem;
  font-weight: 300;
  line-height: 2.8125rem;
}

.highlighted-text {
  font-size: 0.75rem;
  line-height: 0.9375rem;
  letter-spacing: 0.3em;
  color: $purple;
  text-transform: uppercase;
  font-weight: 700;
  display: block;

  @media (min-width: $sm) {
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  @media (min-width: $md) {
    font-size: 1.125rem;
    line-height: 1.4375rem;
    margin: auto;
  }
  &.metrics-heading {
    font-size: 1rem;
    line-height: 2.25rem;
    letter-spacing: 0.3em;
    color: $white;
    text-align: center;

    @media (min-width: $sm) {
      font-size: 1.5rem;
    }
    @media (min-width: $med) {
      font-size: 1.75rem;
    }
  }

  &.text-on-darkBG {
    color: $purpleTextLight;
    text-align: center;
  }
}

.highlighted-text-light {
  font-size: 0.875rem;
  letter-spacing: 0.125em;
  color: $purple;
  text-transform: uppercase;
  font-weight: 500;
}

.purple-links {
  &:hover {
    border-bottom: 1.5px solid $purple;
    transition-duration: 0s;
    color: $purple;
  }
  &.small-bold {
    font-size: 14px;
    font-weight: 600;
  }
}

.red-links {
  color: $redText;
  font-weight: 500;
  font-size: 1rem;

  &:hover {
    border-bottom: 1.5px solid $redText;
    transition-duration: 0s;
    color: $redText;
  }
}
.pink-links {
  color: $pinkAlert;
  font-size: 1rem;
  border-bottom: 1px solid $pinkAlert;

  &:hover {
    border-bottom: 1.5px solid $pinkAlert;
    transition-duration: 0s;
    color: $pinkAlert;
  }
}
.text-warning {
  color: $yellow !important;
}
.gray-links {
  color: $darkGray;

  &:hover {
    color: $darkGray;
    text-decoration: underline !important;
  }
}
