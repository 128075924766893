.rtl {
  direction: rtl;
}
.feature-links h2 {
  color: $black;

  &:hover {
    color: $purple;
  }
}
.home-top {
  h1 {
    padding-bottom: 1.3rem;
  }

  p {
    color: $grayText;
  }
}

.networth-section {
  h1 {
    padding-bottom: 1.3rem;
  }

  p {
    color: $grayText;
  }
}

.home-list ul {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
}

.home-list ul li {
  margin: 0 10px;
  list-style: none;
  flex: 1 0 31.5%;
  font-size: 1.25rem;
  color: $grayText;
}

.home-list ul li h2 {
  font-size: 1.75rem;
  line-height: 2.1875rem;
  color: $black;
}

.mm-container-right.home-banner-container {
  padding-top: 7px;
}

//Banner section
.home-left-banner {
  max-width: 602px;
}

.home-left-banner p {
  margin-bottom: 58px;
}

.home-left-banner .scroll-down-btn a .scroll-dot {
  transition: 0.3s;
}

.home-left-banner .scroll-down-btn a:hover .scroll-dot {
  transform: translateY(15px);
}

.home-left-banner .scroll-down-btn {
  padding-top: 44px;
}

.home-banner-right {
  margin-top: -57px;
}

.home-cta-section {
  padding-top: 180px;
  text-align: center;
}

//Early adopter Section
.early-adopter-section {
  padding-top: 120px;
  padding-bottom: 181px;

  @media (max-width: $md) {
    padding-top: 0;
  }

  h2 {
    font-weight: 300;
    font-size: 3.3rem;
    line-height: 106%;
    margin-bottom: 2rem;
  }
}

//Feature Section
.mm-container-right.home-feature-section {
  background-color: $darkBg;
  padding-top: 107px;
  padding-bottom: 0;
}

.home-feature-section .feature-content {
  max-width: 85%;
}

.home-feature-section li {
  margin-bottom: 89px !important;
}

.home-feature-section li .feature-icon {
  margin-bottom: 29px;
}
.features-manual-account {
  width: 800px;
}

//Blog Section
.home-blog-section {
  padding-bottom: 6px;
}

.home-blog-section .blog-top-content {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 44px;
}

.home-blog-section .blog-content-left {
  width: 85%;

  h4 {
    max-width: 748px;
    line-height: 40px;
    margin-bottom: 20px;
    font-weight: 300;
    font-size: 2.25rem;

    @media (min-width: $md) {
      font-size: 3.5rem;
      margin-bottom: 24px;
      line-height: 54px;
    }
  }

  p {
    color: $grayText;
  }
}

.home-blog-section .blog-content h2 {
  font-size: 2rem;
  font-weight: 200;
  overflow: hidden;
  margin-bottom: 18px;
  line-height: 2.56rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  letter-spacing: 0.02em;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  color: $black;

  a {
    color: $black;
  }
}

.home-blog-section .blog-content {
  max-width: 369px;
}

.home-blog-section .blog-img {
  margin-bottom: 23px;
  width: 384px;
  height: 257px;

  img {
    width: 97%;
    height: 100%;
    border-radius: $radiusS;
  }
}

.home-blog-section .blog-content {
  max-width: 384px;
  overflow: hidden;
}

.home-list.blog-list li {
  flex: auto;
  float: left;
  width: 30.33%;
}

.blog-header {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.blog-content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

//allocation section

.allocation-section .networth-section {
  padding-top: 73px;
}

.allocation-section .networth-text {
  padding-top: 155px;
}

//performance section
.performance-section .networth-section {
  padding-top: 70px;
}

.performance-section .networth-text {
  padding-top: 100px;
}

.performance-section .networth-chart {
  width: 86%;
}

span.above-tag-text {
  color: $primary;
  font-size: 1.125rem;
  line-height: 1.437rem;
  background: rgba(83, 76, 234, 0.2);
  padding: 0 7px;
  border-radius: 6px;
  position: absolute;
  top: 8px;
  right: -141px;
}

.performance-section .networth-section h1,
.minxwinks-section .networth-section h1,
.minxmeasure-section .networth-section h1 {
  position: relative;
  display: inline-block;
}

//minx winks section
.minxwinks-section .networth-section {
  padding-top: 93px;
}

.minxwinks-section .networth-text {
  padding-top: 155px;
}

.mm-container-right.minxwinks-section {
  padding-left: 1px;
}

.minxwinks-section .networth-chart {
  width: 95%;
}

//minx measure section
.minxmeasure-section .networth-section {
  padding-top: 84px;
}

.minxmeasure-section .networth-text {
  padding-top: 90px;
}

.minxmeasure-section .networth-chart {
  float: right;
  width: 72%;
}

.home-networth.networth-section .networth-text p span {
  position: absolute;
  top: -94px;
}

// coming soon
.home-coming-soon {
  text-align: center;
  margin: auto;
  padding: 40px 0 40px 0;

  @media (min-width: $md) {
    padding: 80px 0 80px 0;
  }
}

@media (max-width: $lg+1) {
  .mm-container-right.home-feature-section {
    margin-left: 0;
    padding-left: 120px;
  }

  .allocation-section .networth-text {
    padding-right: 25px;
  }

  .home-blog-section .blog-top-content {
    padding-right: 15px;
  }
}

@media (max-width: $md) {
  .scroll-down-btn {
    position: static;
    display: none;
  }

  .mm-container-right.home-banner-container {
    margin-right: 0;
  }

  .mm-container-right.home-feature-section {
    padding-left: 80px;
  }

  .home-feature-section .feature-content {
    max-width: none;
  }

  .home-feature-list ul li {
    flex: 1 0 47% !important;
  }

  .home-banner-right {
    float: right;
  }

  .home-list ul li {
    flex: auto;
    margin-bottom: 31px;
  }

  .mm-container-right.home-feature-section {
    margin-right: 0;
  }

  .mm-container-right.home-feature-section {
    margin: 0 !important;
  }

  .mm-container-right {
    margin: 0 20px !important;
  }

  .minxmeasure-section .networth-chart {
    float: none;
    width: 100%;
  }

  .performance-section .networth-chart {
    width: 100%;
  }

  .home-blog-section .blog-top-content {
    display: block;
  }

  .home-blog-section ul li:last-child {
    display: none;
  }

  .home-blog-section .blog-content-left {
    width: 100%;
  }

}

@media (max-width: $sm) {
  .early-adopter-section .earlyadopter-list li {
    padding: 51px 28px;
  }

  .home-list ul li {
    margin-right: 27px;
  }

  .home-feature-list ul li {
    flex: auto !important;
  }

  .mm-container-right.home-feature-section {
    padding-left: 21px;
  }

  .home-feature-section .feature-content {
    max-width: 100%;
  }

  .home-list.blog-list ul {
    margin-right: 20px;
  }

  .home-list ul li {
    margin: 0;
  }

  .home-list.blog-list li {
    float: none;
    width: 100%;
  }

  .home-networth.networth-section .networth-text p span {
    top: -44px;
  }
}

.mm-btn-primary-outline:hover {
  color: $primary;
  background-color: $white !important;
  border-color: $white;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: $primary !important;
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.5);
}

.mm-above-tag-text {
  color: $purple;
  font-size: 1.125rem;
  line-height: 1.437rem;
  background: rgba(83, 76, 234, 0.2);
  padding: 0 7px;
  border-radius: 6px;
  height: 24px;
  top: 140px;
  width: 130px;
  position: absolute;
  left: 260px;

  @media (min-width: $lg - 101) and (max-width: $lg - 28) {
    // must rare cases
    left: 264px;
  }
}

.coming-soon-wrapper {
  @media (min-width: $md + 224) {
    padding-left: 4.5rem;
  }

  @media (min-width: $lg - 100) {
    padding-left: 9.5rem;
  }
}

.mm-home-banner-img {
  margin-right: -88px;

  @media (min-width: $lg - 300) {
    margin-right: -185px;
  }

  svg {
    width: 30rem;

    @media (min-width: $md) {
      width: 55rem;
    }

    @media (min-width: $md) {
      width: 55rem;
    }

    @media (min-width: $md + 224) and (max-width: $lg - 140) {
      width: 28rem;
    }
  }
}

.mm-home-left-block {
  max-width: 600px;
}

.mm-home-top-section {
  padding: 1rem;

  @media (min-width: $md) {
    padding: 3rem;
  }

  @media (min-width: $lg) {
    padding: 0 120px;
  }

  .text,
  .info-text {
    color: $grayText;
  }

  .mm-scroll-down {
    margin-top: 5rem;
  }
}

.mm-mobile-hide {
  @media (max-width: $md) {
    display: none;
  }
}

.mm-container-right-custom {
  padding: 36px 0 74px 120px;

  @media (max-width: $md) {
    padding-left: 21px;
  }

  .networth-chart {
    width: unset;
  }
}

.networth-chart {
  float: unset;

  @media (max-width: $md) {
    padding-left: 0 !important;
  }

}

@media (min-width: $lg) {
  .mm-container-right {
    padding: 36px 0 74px 56px;
  }

  .networth-chart {
    float: unset;
    width: unset !important;
  }
}

@media (min-width: $md + 101) {
  .blog-content-right button {
    margin-top: 10rem;
  }

  .home-list.blog-list {
    margin-bottom: 10rem;
  }
}

@media (max-width: $md + 100) {
  .adopter-top-wrap {
    margin-top: 7rem;
    padding-left: 2rem;
  }

  .earlyadopter-list {
    padding-left: 2rem !important;
  }

  .home-networth.networth-section .networth-text p span {
    position: absolute;
    top: 0;
  }

  .networth-chart {
    padding-left: 3rem;
    padding-right: 3rem;
    text-align: center;
    float: unset;
    width: unset;
  }

  .networth-chart.text-right {
    padding-left: 2.6rem;
  }
}

@media (max-width: $sm + 300) {
  .adopter-top-wrap,
  .earlyadopter-list {
    padding-left: 0 !important;
  }

  .home-list.blog-list {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .networth-text {
    padding-left: 0;
    padding-right: 0;
  }

  .networth-chart.text-right {
    padding-left: 0;
  }

  .minxmeasure-section .networth-chart {
    padding-left: 0;
  }
}

.blog-content a {
  color: $black;
}

// scrolling mouse and media features
.mm-scroll-container-parent {
  display: flex;
  align-items: center;
}
.mouse {
  width: 24px;
  height: 36px;
  border-radius: 16px;
  border: 1.5px solid $gray;
  animation: intro 1s;
  display: inline-block;
}

.scroll {
  display: block;
  width: 4px;
  height: 4px;
  margin: 6px auto;
  border-radius: 4px;
  background: $gray;
  -webkit-animation: finger 2s infinite;
  animation: finger 2s infinite;
}

@-webkit-keyframes intro {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes intro {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes finger {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}

@keyframes finger {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
}
.media-features {
  color: $grayText;
  display: flex;
  align-items: center;
  margin: auto;

  svg {
    margin-left: 12px;
  }
  .media-outlet {
    margin-left: 30px;
  }
}
.mm-new-container {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 16px;

  @media (min-width: $md) {
    padding-left: 80px;
    padding-right: 80px;
  }

  @media (min-width: $md + 372) {
    padding-left: 120px;
    padding-right: 120px;
  }

  section {
    margin-bottom: 80px;

    @media (min-width: $md) {
      margin-bottom: 150px;
    }
  }

  .mm-home-left-banner {
    @media (min-width: $md + 372) {
      margin-right: calc(100% - 604px);
    }

    h1 {
      margin-bottom: 32px;
      font-size: 2.25rem;

      @media (min-width: $md) {
        font-size: 3.3rem;
      }

    }

    button {
      margin-top: 30px;

      @media (min-width: $md + 372) {
        margin-top: 60px;
      }
    }

    p {
      color: $grayText;
      font-weight: 300;
      font-size: 1.25rem;
      max-width: 100%;

      @media (min-width: $md + 372) {
        max-width: 80%;
      }
    }

    small {
      color: $grayText;
      font-weight: 300;
      font-size: 0.875rem;
    }
  }

  .mm-home-right-img-banner {
    svg {
      margin-left: -180px;

      @media (min-width: $md) {
        margin-left: 0;
      }

      @media (min-width: $lg) {
        margin-left: calc(100% - 510px);
      }
    }
  }

  .mm-scroll-container-parent {
    margin-top: 80px;
  }

  .mm-mb-hide {
    display: none;

    @media (min-width: $md + 372) {
      display: block;
    }
  }

  .adopter-top-wrap {
    margin-bottom: 60px;

    p {
      font-weight: 300;
    }

    @media (min-width: $md + 372) {
      margin-bottom: 110px;
    }
  }

  .mm-home-feature-section {
    background-color: $darkBg;
    padding: 100px 100px 40px 100px;
    margin-left: -120px;
    margin-right: -120px;

    .mm-home-feature-wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }
    &.metrics {
      min-height: 740px;

      @media (min-width: $sm) {
        min-height: 690px;
      }
      @media (min-width: $med) {
        min-height: 440px;
      }
    }
    &.ss {
      min-height: 520px;
      padding: 100px 0;
    }
    .feature-content {
      width: 100%;
      margin-bottom: 120px;
      height: 100%;

      @media (min-width: $md + 372) {
        padding-right: 80px;
      }

      h3, p {
        color: $white;
        font-weight: 300;
      }

      h3 {
        font-size: 1.75rem;
      }

      p {
        font-size: 1.125rem;
        max-width: 300px;
      }
    }
  }

  // Home Page - Account Types
  .account-types-heading {
    font-weight: 500;
    font-size: 1.125rem;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: $white;
    padding: 0 10px;
  }
  .account-types-subheading {
    font-size: 1.125rem;
    line-height: 150%;
    text-align: center;
    color: $whiteOpacity60;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 10px;
  }
  .account-types-list {
    margin-top: 60px;
    padding-bottom: 60px;
    display: flex;
    overflow: scroll;
    overscroll-behavior-x: contain;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
    &::after {
      content: "";
      display: block;
      flex: 0 0 var(--space);
    }
  }
  .account-types-single {
    background-image: url('../../images/backgrounds/account-type-background.png');
    border-radius: $radiusS;
    width: 180px;
    min-width: 180px;
    height: 220px;
    color: $white;
    font-weight: 500;
    font-size: 1.125rem;
    text-align: center;
    position: relative;
    display: inline-block;
    margin: 0 8px;

    &.background-two {
      background-image: url('../../images/backgrounds/account-type-background-2.png');
    }

    .account-types-single-emoji {
      font-size: 3.75rem;
      padding: 36px 0 20px 0;
    }
    p {
      color: $white;
      font-weight: 500;
    }
  }

  // Home Page - Connections
  .connections {
    padding: 100px 0 80px 0;
  }
  .connections-heading {
    font-size: 2.125rem;
    line-height: 132%;
    text-align: center;
    color: $white;
    max-width: 98vw;
    margin: 10px auto;
    padding: 0 10px;

    @media (min-width: $med) {
      font-size: 2.75rem;
      max-width: 788px;
    }
  }
  .connections-subheading {
    font-size: 1.25rem;
    line-height: 150%;
    text-align: center;
    color: $whiteOpacity60;
    max-width: 96vw;
    margin: 22px auto 60px auto;

    @media (min-width: $med) {
      font-size: 1.75rem;
      max-width: 788px;
    }
  }
  .connections-logos {
    opacity: 0.60;
    text-align: center;
    overflow: hidden;
  }
  .connections-logo-row {
    animation: scroll-connections 100s linear infinite;
    display: flex;
    margin: 30px 0;
    width: 100vw;

    &.reversed {
      animation: scroll-connections-reversed 100s linear infinite;
    }
  }
  .connections-logo {
    margin: 2px 50px;
    width: 200px;
    display: flex;
    text-align: center;
    vertical-align: middle;
    svg {
      display: block;
    }
  }

  @keyframes scroll-connections {
    0% {
      transform: translate3d(0, 0, 0);
    }

    100% {
      transform: translate3d(-1800px, 0, 0);
    }
  }
  @keyframes scroll-connections-reversed {
    0% {
      transform: translate3d(-1800px, 0, 0);
    }

    100% {
      transform: translate3d(0, 0, 0);
    }
  }

  .home-blog-section {
    .mm-blog-details {
      h2,
      a {
        font-weight: 300;
        font-size: 2rem;
        color: $black;
      }
    }

    .mm-blog-img {
      width: 100%;
      height: auto;
      overflow: hidden;
      border-radius: $radiusS;

      img {
        width: 100%;
        height: auto;
      }
    }

    .mm-blog-btn {
      float: right;
      margin-top: -125px;
    }

    .home-list.blog-list {
      margin-top: 60px;

      .mm-link-blog-btn {
        display: none;

        @media (min-width: $md + 432) {
          display: block !important;
        }
      }
    }
  }

  .mm-networth-section-overview {
    margin-top: 40px;

    @media (min-width: $sm) {
      margin-top: 60px;
    }

    p {
      margin-bottom: 60px;
    }

    .networth-text {
      padding-top: 0;

      @media (min-width: $md + 432) {
        margin-right: calc(100% - 444px);
      }

      h1 {
        margin-bottom: 32px;
      }
    }

    .mm-networth-chart {
      text-align: center;

      @media (min-width: $md + 432) {
        margin-left: calc(100% - 610px);
      }
    }
  }

  .networth-section {
    padding-top: 0;
  }

  .mm-home-performance-section {
    .mm-home-performance-section-chart {
      text-align: center;

      @media (min-width: $md + 432) {
        margin-left: calc(100% - 590px);
      }
    }

    span.mm-above-tag-text {
      color: $purple;
      font-size: 1.125rem;
      line-height: 1.437rem;
      background: rgba(83, 76, 234, 0.2);
      padding: 0 7px;
      border-radius: 6px;
      position: absolute;
      top: 50px;
      left: 330px;
    }

    .mm-mb-hide {
      display: none;

      @media (min-width: $md) {
        display: block;
      }
    }
  }

  .mm-winks-section {
    .mm-above-tag-text {
      color: $purple;
      font-size: 1.125rem;
      line-height: 1.437rem;
      background: rgba(83, 76, 234, 0.2);
      padding: 0 7px;
      border-radius: 6px;
      height: 24px;
      top: 35px;
      position: absolute;
      left: 305px;
    }

    .mm-mb-hide {
      display: none;

      @media (min-width: $md) {
        display: block;
      }
    }

    .mm-custom-networth-chart {
      @media (max-width: $md - 1) {
        margin-right: -14rem;
      }
    }

    .networth-chart {
      padding-right: 0;
      padding-left: 0;

      @media (min-width: $md) and (max-width: $md + 400) {
        width: 100%;
        height: 100%;
      }

      svg {
        @media (min-width: $md) and (max-width: $md + 400) {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .mm-measure-section {

    .mm-mb-hide {
      display: none;

      @media (min-width: $md) {
        display: block;
      }
    }

    .mm-networth-chart {
      text-align: center;

      @media (min-width: $md + 432) {
        margin-left: calc(100% - 495px);
      }
    }

    .mm-above-tag-text {
      color: $purple;
      font-size: 1.125rem;
      line-height: 1.437rem;
      background: rgba(83, 76, 234, 0.2);
      padding: 0 7px;
      border-radius: 6px;
      height: 24px;
      top: 50px;
      position: absolute;
      left: 440px;
    }
  }
}
p.feature-heading {
  color: $black;
  font-size: 2.25rem;
  padding-bottom: 1.3rem;
  font-weight: 300;
  line-height: 130%;
  display: block;

  @media (min-width: $med) {
    font-size: 2.6875rem;
  }
}
.adopter-top-wrap h3 {
  font-weight: 300;
  font-size: 2rem;
  padding-bottom: 14px;

  @media (min-width: $md) {
    font-size: 3.5rem;
  }
}

.mm-networth-chart-legend {
  margin-right: calc(100% - 590px);

  @media (min-width: $md) {
    margin-right: calc(100% - 54rem);
  }
}

.mm-sm-md-hide {
  @media (max-width: $md + 432) {
    display: none;
  }
}

@media (max-width: $sm + 200) {
  .networth-section.rtl {
    margin-top: -130px;
  }

  .mm-blog-details {
    margin-bottom: 32px;
  }

  .mm-new-container .mm-home-feature-section .feature-content {
    margin-bottom: 50px;
  }

  .mm-winks-section .networth-text {
    margin-top: 120px;
  }
}

@media (max-width: $md + 432) {
  .networth-text {
    margin-bottom: 40px;
  }
}

/* Testimonials */

.testimonial {
  margin-top: 200px;
}

.cards-row {
  padding-top: 40px;
  margin-top: 80px;
  margin-bottom: 100px;
  text-align: center;
}

.cards-single {
  margin: 0;
  position: relative;
  display: inline-block;
  max-width: 374px;
  text-align: center;

  @media (min-width: $med) {
    margin: 0 10px;
  }
}

.testimonial-card {
  background: $white;
  box-shadow: 0 2px 8px #E2E8F3, inset 0 -1px 0 #EBECF1;
  border-radius: $radiusS;
  font-size: 0.875rem;
  padding: 30px;
  min-height: 190px;
  width: 250px;
  @media (min-width: $sm) {
    width: 320px;
  }
  @media (min-width: $med) {
    width: 384px;
  }

  p {
    font-size: 1.125rem;
    text-align: center;
    color: $grayText;
  }
}

.testimonial-quote {
  position: relative;
  top: 20px;
}

.testimonial-user {
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.1));
  position: relative;
  bottom: 35px;
  height: 60px;
  border-radius: 50%;
  border: 1.5px solid $purple;
}

.card-caption {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  align-items: center;
  color: $purple;
  text-align: center;
  margin-top: -24px;
}

.card-caption span {
  color: $grayText;
  font-size: 18px;
  padding-left: 5px;
}

// Home Page and Feature Pages Features
.feature-section {
  height: 730px;
  margin-bottom: 260px;

  @media (min-width: $md) {
    height: 500px;
    margin-bottom: 200px;
  }

  h2 {
    color: $black;
    font-size: 2.25rem;
    padding-bottom: 1.3rem;
    font-weight: 300;
    line-height: 120%;

    @media (min-width: $md) {
      font-size: 2.75rem;
    }
  }

  a h2 {
    &:hover {
      color: $purple;
    }
  }
  .feature-text-left svg {
    position: absolute;
    left: calc(100% - 520px);
  }
  .insights-section {
    vertical-align: middle;
    margin: auto;

    h2 {
      margin-top: 36px;
      margin-bottom: 10px;
    }
  }
}
.feature-section-reversed {
  .feature-content {
    order: 1;

    @media (min-width:$bs-lg) {
      order: 2
    }
  }
  .feature-image {
    order: 2;

    @media (min-width:$bs-lg) {
      order: 1
    }
  }
  svg, img {
    position: absolute;
    max-width: 100%;
    @media (min-width:$med) {
      right: calc(100% - 520px);
    }
  }
}
.video-embed {
  margin-top: 80px;
  background: #FFFFFF;
  box-shadow: 0 2px 8px #E2E8F3, inset 0 -1px 0 #EBECF1;
  border-radius: $radiusS;
  font-size: 0.875rem;
  padding: 30px;

  iframe {
    width: 100%;
    max-width: 100%;
    height: 160px;
    @media (min-width: $sm + 1) {
      height: 260px;
    }
    @media (min-width: $med) {
      height: 480px;
    }
    @media (min-width: $md) {
      height: 400px;
    }
    @media (min-width: $desk) {
      height: 290px;
    }
  }
}
.metrics-content {
  display: flex;
  text-align: center;
}
.metrics-group {
  margin: auto;
}
.metric-qualifier {
  font-weight: 600;
  font-size: 2rem;
  line-height: 0;
  color: $whiteOpacity80;
  padding-bottom: 40px;
  display: inline-block;
  vertical-align: middle;
}
.metric-number {
  font-weight: 400;
  font-size:  4rem;
  line-height: 6.25rem;
  color: $white;
  @media (min-width: $sm) {
    font-size: 4.5rem;
  }
  @media (min-width: $med) {
    font-size: 3.2rem;
  }
  @media (min-width: $md) {
    font-size: 4.5rem;
  }
}
p.metric-item {
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: $lightBg;
  text-align: center;
}
.feature-text {
  font-weight: 300;
  font-size: 1.25rem;
  color: $grayText;
}
.trusted-by {
  height: 90px;
  margin-bottom: 40px;
  text-align: center;
  display: inline-block;
  span {
    padding-left: 0;
  }
  @media (min-width: $med) {
    text-align: left;
    display: flex;
  }
}
.assets-tracked {
  @media (min-width: $med) {
    float: right;
  }
  .amount {
  font-size: 64px;
  line-height: 132%;
  color: $purple;
    @media (min-width: $med) {
      font-size: 74px;
    }
}
  .metric {
    font-size: 14px;
    line-height: 170%;
    color: $grayTextOpacity80;
  }
}

.pros-cons {
  display: block;
  justify-content: center;

  @media (min-width: $list) {
    display: flex;
  }
}
.pros-cons-container {
  display: block;

  @media (min-width: $list) {
    display: flex;
  }
}
.cons-list, .pros-list {
  padding-right: 0;
  padding-left: 0;
  @media (min-width: $list) {
    padding-right: 15px;
    padding-left: 15px;
  }
  li {
    background-size: cover;
    background-repeat: no-repeat;
    height: 84px;
    width: 300px;
    color: $darkGray;
    line-height: 150%;
    font-size: 1rem;
    margin-bottom: 16px;
    display: flex;
    padding-top: 16px;
    padding-left: 10px;
    @media (min-width: $header) {
      width: 408px;
    }
    @media (max-width: $list) {
      margin-left: auto;
      margin-right: auto;
    }

    span {
      padding-left: 4px;
      max-width: 246px;
      font-size: 0.95rem;
      @media (min-width: $list) {
        font-size: 1rem
      }
    }

    &::before{
      content: '';
      display: inline-block;
      height: 48px;
      width: 48px;
      margin-right: 4px;
    }
  }
}
.cons-list {
  li {
    background-image: url('../../images/backgrounds/cons.png');

    &::before{
      background-image: url('../../images/account/Not Linked.svg');
    }
  }
}
.pros-list {
  li {
    background-image: url('../../images/backgrounds/pros.png');

    &::before{
      background-image: url('../../images/account/check-circle-green.svg');
    }
  }
}

// Home page security section
.home-security {
  .mm-home-feature-section {
    padding-bottom: 100px;
    padding-top: 130px;
    @media (min-width: $med) {
      padding-top: 100px;
    }
  }
  .sub-heading, p {
    color: $whiteOpacity60;
  }
  .row {
    padding-left: 30px;

    @media (min-width: $med) {
      padding-left: 100px;
    }
  }
  .security-icon {
    stroke: $white;
  }
  .security-link {
    color: white;
    font-size: 1.125rem;

    a {
      color: white;
      text-decoration: underline;
    }
  }
}
// Home advisors
.home-advisors {
  padding-top: 10px;
  padding-bottom: 10px;
  @media (min-width: $med) {
    margin-left: 40px;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  p {
    font-size: 2rem !important;
    line-height: 132% !important;
    color: $black;
    max-width: 780px;
    @media (min-width: $med) {
      font-size: 2.75rem !important;
    }
  }

  .wall-of-emojis {
    display: none;
    @media (min-width: $desk) {
      display: block;
      animation: float 6s ease-in-out infinite;

      :nth-child(1) {
        transform: translate(25vw, -43vh) rotate(-81deg);
        display: inline-block;
        font-size: 45px;
      }
      :nth-child(2) {
        transform: translate(50vw, -35vh) rotate(0deg);
        display: inline-block;
        font-size: 45px;
      }
      :nth-child(3) {
        transform: translate(62vw, -17vh) rotate(17deg);
        display: inline-block;
        font-size: 45px;
      }
      :nth-child(4) {
        transform: translate(66vw, -7vh) rotate(9deg);
        display: inline-block;
        font-size: 45px;
      }
      :nth-child(5) {
        transform: translate(50vw, 7vh) rotate(-18deg);
        display: inline-block;
        font-size: 45px;
      }
      :nth-child(6) {
        transform: translate(29vw, 12vh) rotate(-25deg);
        display: inline-block;
        font-size: 45px;
      }
      :nth-child(7) {
        transform: translate(-11vw, 5vh) rotate(-25deg);
        display: inline-block;
        font-size: 45px;
      }
      :nth-child(8) {
        transform: translate(4vw, 18vh) rotate(8deg);
        display: inline-block;
        font-size: 45px;
      }
      :nth-child(9) {
        transform: translate(28vw, -3vh) rotate(-25deg);
        display: inline-block;
        font-size: 45px;
      }
    }

    @keyframes float {
      0% {
        transform: translatey(0px);
      }
      50% {
        transform: translatey(-20px);
      }
      100% {
        transform: translatey(0px);
      }
    }
  }
}

// Home Performance
.feature-section {
  margin-bottom: 110px !important;
  .performance-image {
    margin-top: 80px;
    @media (min-width: $med) {
      margin-top: 0;
    }
  }
}
