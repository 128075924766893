.insights {
  position: fixed;
  bottom: 0;
  left: -4px;
  z-index: 20;

  @media screen and (min-width: $md) {
    left: 8px;
    bottom: 5px;
  }

  &__button {
    border: none;
    background: transparent;
    visibility: visible;
    position: relative;

    &--highlight::after {
      content: '';
      position: absolute;
      top: 8px;
      right: 19px;
      background-color: $redAlert;
      border: 1.5px solid $white;
      border-radius: 50%;
      width: 14px;
      height: 14px;
    }
  }

  &__button img {
    width: 80px;
    height: 80px;
  }

  &__content {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    bottom: 85px;
    left: 12px;
    box-shadow: 0 4px 12px rgba(7, 0, 141, 0.16);
    background-color: $white;
    border-radius: $radiusS;
    min-width: 280px;
    transition: all 0.15s ease-in-out !important;

    @media screen and (min-width: $md) {
      bottom: 90px;
      left: 24px;
      min-width: 320px;
    }

    &--visible {
      opacity: 1;
      pointer-events: all;
    }

    &-header {
      background-color: $lightBg;
      box-shadow: inset 0px -1px 0px $whiteInsetShadow;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 12px 8px 12px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (min-width: $md) {
        padding: 16px 16px 16px 20px;
      }

      & h4 {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 18px;
        margin: 0;
        color: $purple;

        @media screen and (min-width: $md) {
          font-size: 1rem;
          line-height: 20px;
        }
      }

      & button {
        background: transparent;
        border: none;
      }
    }

    &-body {
      padding: 12px 10px 0 12px;
      max-height: 240px;
      max-width: 280px;
      overflow-y: auto;

      @media screen and (min-width: $md) {
        padding: 16px 20px 0 20px;
        max-width: 320px;
      }
    }

    &-footer {
      padding: 8px;
      border-top: 1px solid rgba(150, 158, 172, 0.24);

      @media screen and (min-width: $md) {
        padding: 16px;
      }

      & button {
        width: 100% !important;
        background-color: $purple;
        border: none;
        border-radius: $radiusS;
        color: $white;
        text-align: center;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        height: 40px;
      }
    }
  }
}

.message-bubble {
  margin-bottom: 16px;

  &__text {
    background-color: rgba(83, 76, 234, 0.1);
    border-radius: 16px 16px 16px 0;
    padding: 14px;
    color: $darkGray;
    font-weight: $lighter;
    font-size: 14px;
    line-height: 150%;
    margin: 0;

    @media screen and (min-width: $md) {
      font-size: 16px;
    }

    &--empty {
      background-color: rgba(236, 85, 85, 0.1);
    }
  }

  &__timestamp {
    color: $gray;
    font-weight: $lighter;
    font-size: 12px;
    line-height: 150%;
  }
}
