.discover-heading {
  margin-top: 36px;
  h2 {
    font-weight: 400;
    font-size: 1.75rem;
    line-height: 2.25rem;
    color: $black;
  }
  h3 {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    color: $darkGray;
  }
}
.discover-list {
  margin-top: 2rem;
  display: grid;
}
.discover-row {
  display: flex;
  flex-wrap: wrap;
}
.discover-card {
  background: $white;
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0 -1px 0 $whiteInsetShadow;
  border-radius: $radiusM;
  padding: 1rem;
  display: block;
  justify-content: space-between;
  position: relative;
  margin-top: 20px;

  @media (min-width: $md) {
    display: flex;
    padding: 1.75rem;
  }
  :before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 6px;
    border-radius: 10px 0 0 10px;
    background: $purple;
  }
}
.discover-logo {
  border: 1px solid $steelBlue;
  width: 56px;
  height: 56px;
  border-radius: 50%;
}
.discover-company-name {
  margin-left: 0;
  margin-bottom: 20px;
}
.discover-name {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: $darkGray;
  display: inline-block;
}
.discover-description {
  font-size: 1rem;
  line-height: 150%;
  color: $darkGray;
  opacity: 0.8;
  max-width: 464px;
  display: inline-block;
  margin-top: 20px;
}
.discover-type {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: $purple;
  font-size: 0.6875rem;
  font-weight: 500;
  background-color: $purpleOpacity20;
  border-radius: $radiusS;
  padding: 2px 8px;
}
.discover-classification {
  letter-spacing: -0.02em;
  color: $purple;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: $purpleOpacity20;
  border-radius: $radiusXS;
  padding: 2px 8px;
  line-height: 150%;
  align-items: center;
  text-align: center;
  width: 122px;
  display: block;
  margin-top: 16px;

  &.blue {
    color: $blue;
    background-color: $blueOpacity20;
  }
  &.red {
    color: $red;
    background-color: $redOpacity20;
  }
  &.green {
    color: $green;
    background-color: $greenOpacity20;
  }
}
.discover-rating {
  margin-right: 4px;
}
.discover-star-off {
  opacity: 30%;
}
.discover-card-metrics {
  margin: auto;
  display: block;
  justify-content: space-between;

  @media (min-width: $sm) {
    display: flex;
  }
  @media (min-width: $md) {
    display: block;
  }

  li {
    margin-top: 12px;
  }

  li:nth-child(even) {
    margin-top: 12px;
    @media (min-width: $med) {
      margin-top: 16px;
    }
  }
}
.discover-metric-label {
  font-size: 0.75rem;
  line-height: 150%;
  color: $grayText;
  display: block;
}
.discover-metric {
  font-size: 1.5rem;
  line-height: 30px;
  color: $black;
  display: block;
}
.discover-card-classification-section {
  text-align: left;
  margin-top: auto;
  .arrow-icon {
    stroke: $purple;
  }
  .arrow {
    float: right;
    margin-top: 4px;
  }
}
.discover-card-classification {
  margin-top: auto;

  a {
    display: block;
    height: 40px;
    margin-top: 12px;
    &:before {
      content: none !important;
    }
  }
}

.discover-details-container {
  margin-top: 28px;

  h1 {
    color: $darkGray;
    font-size: 1.75rem;
    line-height: 2.125rem;
    margin-top: 40px;
  }

  p {
    font-size: 1rem;
    line-height: 170%;
    color: $grayText;
  }
}
.discover-gallery-main {
  border-radius: $radiusS;
}

.discover {
  &__heading {
    display: flex;
    justify-content: space-between;
    margin-top: 36px;

    h2 {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $black;

      @media screen and (min-width: $sm) {
        font-size: 28px;
        line-height: 35px;
      }
    }

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: $darkGray;

      @media screen and (min-width: $sm) {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  &__filter-btn {
    border: 1px solid $purple;
    border-radius: $radiusS;
    outline: none;
    background: transparent;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
  }

  &-search {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid rgba($color: $gray, $alpha: 0.24);
    padding: 12px 16px;
    margin-right: 12px;
    flex: 1;

    svg {
      margin-right: 4px;
      width: 20px;
      height: 20px;
    }

    input {
      border: none;
      color: $grayText;
      font-size: 14px;
      line-height: 18px;
      background-color: transparent;
      flex: 1;
    }

    @media screen and (min-width: $md) {
      max-width: 400px;
    }

    @media screen and (min-width: $lg) {
      max-width: 600px;
    }
  }
}

.discover-top-section {
  .filter__row {
    padding-left: 16px;
  }

  .desktop-filters {
    display: none;

    @media screen and (min-width: $md) {
      display: flex;
    }
  }
}

.opportunity-header-container {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $md) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (min-width: $bs-lg) {
    flex-direction: column;
  }
}

.opportunity {
  background-color: $white;
  box-shadow: 0 2.47727px 9.90909px $whiteFadeShadow, inset 0px -1.23864px 0px $whiteInsetShadow;
  border-radius: $radiusM;
  padding: 32px 24px;
  min-height: 100%;

  @media screen and (min-width: $md) {
    margin-bottom: 24px;
  }

  &__header {
    display: flex;
    margin-bottom: 16px;

    img {
      width: 48px;
      height: 48px;
      margin-right: 16px;
      border-radius: 50%;
    }
  }

  &__company {
    color: $darkGray;
    font-weight: 600 !important;
    font-size: 20px;
    line-height: 25px;
  }

  &__type {
    display: inline-block;
    background-color: rgba(83, 76, 234, 0.2);
    border-radius: $radiusS;
    padding: 4px 8px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: $purple;
    font-weight: bold;
    font-size: 11px;
    line-height: 150%;
  }

  &__description {
    color: $grayTextOpacity80;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 32px;

    @media screen and (min-width: $md) {
      max-width: 50%;
      margin-bottom: 24px;
    }

    @media screen and (min-width: $bs-lg) {
      max-width: unset;
      margin-bottom: 32px;
    }
  }

  &__info-wrapper {
    flex-wrap: wrap;

    @media screen and (min-width: $md) {
      flex-wrap: nowrap;
    }

    @media screen and (min-width: $bs-lg) {
      flex-wrap: wrap;
    }
  }

  &__info {
    flex: 1 0 calc(100% / 3);
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding-right: 8px;

    &:nth-child(3n) {
      padding-right: 0;
    }

    &-label {
      font-size: 12px;
      line-height: 150%;
      color: rgba($color: $grayText, $alpha: 0.8);
      margin-bottom: 4px;
    }

    &-value {
      font-weight: 300;
      font-size: 22px;
      line-height: 30px;
      color: $black;
    }

    .return-type {
      color: $blue;
      text-align: center;
      letter-spacing: -0.02em;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      border-radius: $radiusS;
      background-color: rgba($color: $blue, $alpha: 0.2);
      padding: 8px 4px;
    }

    .investor-type {
      color: $red;
      text-align: center;
      letter-spacing: -0.02em;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      border-radius: $radiusS;
      background-color: rgba($color: $red, $alpha: 0.2);
      padding: 8px 4px;
    }

    @media screen and (min-width: $md) {
      flex: unset;
    }

    @media screen and (min-width: $bs-lg) {
      flex: 1 0 calc(100% / 3);
    }
  }

  &__labels {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (min-width: $md) {
      justify-content: flex-start;
    }

    @media screen and (min-width: $bs-lg) {
      justify-content: space-between;
    }
  }

  &__label {
    @media screen and (min-width: $md) {
      flex: 1 0 calc(100% / 3);
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-start;
    margin: 0 -24px;
    padding: 24px 24px 0;
    border-top: 1px solid rgba($color: $gray, $alpha: 0.24);
  }

  &__action {
    flex: 1 0 calc(100% / 3);
    width: 33%;
    padding-right: 8px;

    a {
      min-width: unset;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__action,
  &__info {
    max-width: unset;

    @media screen and (min-width: $md) {
      max-width: 200px;
    }

    @media screen and (min-width: $bs-lg) {
      max-width: unset;
    }
  }

  &__insight {
    display: flex;
    margin-top: 32px;

    img {
      width: 48px;
      height: 48px;
      border: 2px solid rgba(165, 172, 184, 0.2);
      border-radius: 50%;
    }

    &-text {
      margin: 0;
      margin-left: 8px;
      font-size: 16px;
      line-height: 20px;
      color: $grayText;
      padding: 16px;
      background: $lightBg;
      box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
      border-radius: 6px;
      border-top-left-radius: 0;
      position: relative;
    }
  }
}

.opportunity-risk {
  display: flex;
  flex-direction: column;

  &__label {
    color: rgba($color: $grayText, $alpha: 0.8);
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
    line-height: 150%;
  }

  &__bar {
    width: 6px;
    display: inline-block;
    border-radius: $radiusS;
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }
  }

  .risk-low {
    background-color: $green;
  }

  .risk-low--faded {
    background-color: rgba($color: $green, $alpha: 0.1);
  }

  .risk-medium {
    background-color: #ffd911;
  }

  .risk-medium--faded {
    background-color: rgba($color: #ffd911, $alpha: 0.1);
  }

  .risk-high,
  .risk-high--faded {
    background-color: $redAlert;
  }
}
