/** include scss **/

@import 'datatables';
@import '../datepicker/datepicker';
@import '../variables';
/** Custom CSS **/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a,
a:hover {
  transition: 0.2s all;
  cursor: pointer;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Mulish', sans-serif;
  background: $lightBg;
}

ol,
ul,
li {
  list-style: none;
}
/**====================================
bootstrap styles
====================================**/

.row {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
}

.navbar-collapse {
  -webkit-flex-basis: 100%;
  -webkit-flex-grow: 1;
  -webkit-align-items: center;
}

.d-flex {
  display: -webkit-flex;
}

input:focus,
button:focus {
  outline: 0 !important;
}

.align-items-center {
  align-items: center;
}
/** =============================== **/
/** Header **/
/** =============================== **/

.money-minx-header {
  background: $white;
  border-radius: 0 0 $radiusS $radiusS;
  padding: 0;
  align-items: inherit;
  height: 60px;
  position: relative;
  z-index: 999;
  flex-wrap: nowrap;
  &.mm-shadow {
    box-shadow: 0 2px 8px $whiteShadow, inset 0px -1px 0px $whiteInsetShadow;
  }
}

.money-minx-header.menu-fixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

.money-minx-header .container {
  max-width: 100vw;
  padding-left: 10px;
  padding-right: 10px;
  height: 60px;
}

.money-minx-header .navbar-brand {
  margin-top: 4px;
  margin-right: auto;
  @media (min-width: $md) {
    margin-right: 0;
  }
}

.money-minx-header .navbar-brand {
  img,
  svg {
    max-width: 110px;
  }
}

.money-minx-header .headtab {
  margin-left: 60px;
  display: none;
  height: 100%;
  @media (min-width: $header) {
    display: flex;
  }
}

.money-minx-header .navbar-brand + .headtab {
  margin-left: 60px;
}

.headtab a {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: $grayText;
  border-bottom: 1.5px solid transparent;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.headtab a:hover,
.headtab a.active {
  color: $darkGray;
  border-bottom: 2px solid $purple;
  box-sizing: border-box;

  .icon-refer {
    stroke: $darkGray;
  }
}

.headtab a + a {
  margin-left: 40px;
}

.head-right {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  padding-bottom: 4px;
  @media (min-width: $md) {
    padding-bottom: 0;
  }
}

.upgrader-btn {
  border: 1.5px solid $purple;
  border-radius: $radiusS;
  position: relative;
  width: 110px;
  height: 40px;
  font-weight: 500;
  font-size: 0.875rem;
  text-align: center;
  color: $purple;
  background: $white;
  padding: 0;
  transition: 0.3s all;
  margin: auto 0;
  &:hover {
    background: $purple;
    color: $white;
    box-shadow: 0 4px 12px $purpleShadow;
  }
}

a.upgrader-btn {
  display: none;
  align-items: center;
  justify-content: center;
  @media (min-width: $bs-lg) {
    display: flex;
  }
}

.upgrader-btn:before {
  content: '';
  position: absolute;
  top: -7px;
  right: -7px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: $purple;
  border: 4px solid $white;
}

.badge-box {
  position: relative;
  margin: auto 0;
  margin-right: 15px;
  display: none;
  @media (min-width: $med) {
    display: inline-block;
  }
}

.upgrader-btn + .badge-box {
  margin-left: 0;
  @media (min-width: $bs-lg) {
    margin-left: 20px;
  }
}

.upgrader-btn + .badge-box:before {
  content: '';
  width: 1px;
  height: 32px;
  background: $gray;
  opacity: 0.2;
  margin-right: 18px;
  vertical-align: middle;
  display: none;
  @media (min-width: $bs-lg) {
    display: inline-block;
  }
}

.badge-box img,
.badge-box span img {
  width: 56px;
  height: 56px;
  margin-top: 4px;
}

.money-minx-header .btn-group .profile-toggle {
  padding: 0;
  border: 0;
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $grayText;
}

.money-minx-header .btn-group .profile-toggle.open:after {
  transform: rotate(225deg);
  vertical-align: -2px;
}

.money-minx-header .btn-group .profile-toggle:after {
  content: '';
  width: 8px;
  height: 8px;
  border: 1.5px solid $gray;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg);
  display: inline-block;
  vertical-align: 3px;
  margin-left: 6px;
  transition: 0.3s all;
  &:hover {
    color: $darkGray;
  }
}

.money-minx-header .btn-group .profile-toggle span + span,
.money-minx-header .btn-group .profile-toggle:after {
  display: none;
  @media (min-width: $med) {
    display: inline-block;
  }
}

.money-minx-header .btn-group .profile-toggle.show:after {
  transform: rotate(225deg);
  vertical-align: 0;
}

.money-minx-header .btn-group .profile-toggle span + span {
  margin-left: 10px;
  &:hover {
    color: $darkGray;
  }
}

.money-minx-header .btn-group .profile-toggle span img {
  width: 46px;
  height: 46px;
  border: 2px solid $grayBorder20;
  border-radius: 50%;
  &:hover {
    border: 2px solid $purpleOpacity50;
  }
}

.money-minx-header .btn-group {
  position: static;
  display: inline-block;
  padding-top: 6px;
}

// App sidebar
.profilemenu {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 280px;
  margin: 0;
  z-index: 600;
  background: $white;
  box-shadow: 0 8px 8px $whiteShadow;
  border-radius: $radiusS 0 0 $radiusS;
  padding-top: 75px;
  transition: 0.3s all;
  right: -300px;
}

.profilemenu.open {
  right: 0;
}

.profilemenu ul li a {
  font-weight: 500;
  font-size: 15px;
  color: $grayText;
  padding: 14px 25px;
  display: block;
}

.sidebar-custom-hr {
  width: calc(100% - 50px);
  margin-top: 0;
  margin-bottom: 0;
}

.profilemenu ul li:hover {
  background: linear-gradient(90deg, rgba(83, 76, 234, 0.14) 0%, rgba(83, 76, 234, 0) 100%);
  a {
    color: $purple;
  }
  .sidebar-icon {
    stroke: $purple;
  }
}

.profilemenu ul li img {
  max-width: 18px;
  vertical-align: -2px;
  margin-right: 18px;
}

.profilemenu ul li i {
  font-size: 18px;
  vertical-align: -2px;
  margin-right: 18px;
  font-weight: 600;
}

.profilemenu ul.prlist-up li a {
  color: $purple;
}

.profilemenu ul.prlist-log li a {
  color: $redText;
}

.sidebackdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 400;
  transition: 0.2s all;
}

.mobmenu.collapsing {
  height: 100% !important;
}

.mobmenu {
  display: block !important;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 280px;
  margin: 0;
  background: $white;
  box-shadow: 0 2px 8px $whiteShadow, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusS 0 0 $radiusS;
  padding: 25px 0;
  padding-top: 75px;
  transition: 0.3s all;
  left: -300px;
  z-index: 10;
}

.mobmenu.show {
  left: 0;
}

.mobmenu .headtab a {
  padding: 14px 25px;
  border-bottom: 0;
  border-left: 2px solid transparent;
}

.mobmenu .headtab a + a {
  margin-left: 0;
}

.mobmenu .headtab a:hover,
.mobmenu .headtab a.active {
  color: $darkGray;
  border-left-color: $purple;
}
/** =============================== **/
/** contents **/
/** =============================== **/

.content-container {
  position: relative;
}
.app-subheader-container {
  padding: 0.75rem 1.125rem;
  display: block;
  max-width: 1440px;
  margin: auto;
  @media (min-width: $sm) {
    display: flex;
    padding: 0.75rem 1.125rem;
  }
  @media (min-width: $md) {
    padding: 0.75rem 1.25rem;
  }
  @media (min-width: $desk) {
    padding: 0.75rem 2.25rem;
  }
  .middle-box {
    margin: auto;
  }
  .view-in-base-currency {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 18px;
    text-align: center;
    color: #969eac;
    white-space: nowrap;
  }
}

.app-subheader-container .container {
  max-width: 1440px;
  padding-left: 25px;
  padding-right: 25px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  flex-wrap: wrap;
}

.app-subheader-container .left-box {
  display: flex;
  align-items: center;
  @media (max-width: 414px) {
    width: 100%;
    justify-content: space-between;
  }
}

.app-subheader-container .right-box {
  margin-left: auto;
  display: none;
  order: 2;
  justify-content: flex-end;
  @media (min-width: 415px) {
    display: flex;
  }
  @media (min-width: 905px) {
    order: 3;
  }
}

.app-subheader-container .center-box {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  order: 3;
  margin-top: 12px;
  @media (min-width: 905px) {
    order: 2;
    margin-top: 0;
  }
}

.myaccount-drop {
  position: relative;
  color: $gray;
  &:hover {
    color: $darkGray;
    border: none;
  }
  .dropdown-toggle {
    padding: 0;
    border: 0 !important;
    background-color: transparent !important;
    font-weight: 400;
    font-size: 0.9375rem;
    color: $grayText !important;
    box-shadow: none !important;
    &:hover,
    &:active,
    &:focus {
      color: $darkGray !important;
      border: none !important;
    }
  }
  .drop-box {
    width: 300px;
  }
}

.hr-darkBg {
  border-top: 1px solid $grayOpacity24;
  max-width: 80%;
  text-align: center;
  margin: auto;
}

.myaccount-drop .dropdown-toggle:after {
  width: 8px;
  height: 8px;
  border: 1.5px solid $gray;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg);
  margin-left: 10px;
  transition: 0.3s all;
  &:hover {
    color: $darkGray;
  }
}

.myaccount-drop .show .dropdown-toggle:after {
  transform: rotate(-135deg);
  vertical-align: -2px;
}

.download-btn {
  font-weight: 500;
  font-size: 15px;
  color: $gray;
  border: 0;
  background-color: transparent;
  svg {
    margin-right: 12px;
    &:hover {
      color: $darkGray;
      stroke: $darkGray;
    }
  }
  &:hover {
    color: $darkGray;
    .icon-link {
      stroke: $darkGray;
    }
  }
}

.download-btn img {
  margin-right: 5px;
  max-width: 18px;
}

.download-btn i {
  margin-right: 5px;
  font-size: 18px;
  font-weight: 600;
  vertical-align: -2px;
}

.dropdown-menu {
  background: $darkBg !important;
  border: 0;
  box-shadow: 0 4px 28px rgba(8, 24, 51, 0.44);
  padding: 0 !important;
  margin-top: 10px;
  border-radius: $radiusS !important;
}

.myaccount-drop .dropdown-menu {
  min-width: 240px;
}

.dropdown-head {
  padding: 20px 24px 10px;
}

.dropdown-head h4 {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: $redAlert;
  &.attention {
    color: $yellow;
  }

  &.closed {
    color: $gray;
  }
}

.dropdown-box {
  padding-top: 10px;
  padding-bottom: 120px;
  max-height: 520px;
  overflow-y: auto;
  hr {
    margin: 12px 24px;
  }
}

.dropdown-box ul li {
  padding: 10px 24px;
  position: relative;
  display: flex;
  align-items: flex-start;
  a {
    display: flex;
    width: 100%;
    align-items: baseline;
  }
}

.dd-my-accounts {
  /*width: 400px !important;*/
}

.account-filter-dd-row {
  display: contents;
  width: 100%;
  align-items: baseline;
}

.account-filter-dd-balance {
  margin-left: auto;
}

.dropdown-box ul li:hover {
  background: rgba(83, 76, 234, 0.3);
}

.dropdown-box ul li:before {
  content: '';
  position: absolute;
  left: 10px;
  top: 14px;
  width: 6px;
  height: 6px;
  border-radius: 2px;
}

.dropdown-box ul.refreshing {
  .mm-loader {
    left: 4px;
  }
}

.dropdown-box ul.pending li:before {
  margin-top: 3px;
  background: $redAlert;
}

.dropdown-box ul.success li:before {
  margin-top: 3px;
  background: $green;
}

.dropdown-box ul.warning li:before {
  margin-top: 3px;
  background: $yellow;
}

.dropdown-box ul.error li:before {
  margin-top: 3px;
  background: $redAlert;
}

.dropdown-box ul.closed li::before {
  margin-top: 3px;
  background: $gray;
}

.dropdown-box ul li h5 {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: $white;
}

.dropdown-box ul li span {
  font-weight: 500;
  font-size: 12px;
  color: $gray;
  display: block;
  margin-top: 3px;
}

.dropdown-box ul li > div,
.dropdown-box ul li a > div {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: $white;
}

.dropdown-box ul li > div + div,
.dropdown-box ul li a > div + div {
  margin-left: auto;
}

.dropdown-box ul + ul {
  margin-top: 10px;
  margin-bottom: 5px;
  padding-top: 10px;
  border-top: 1px solid $grayOpacity24;
}

.content-wrapper {
  padding: 15px 0;
  min-height: calc(100vh - 194px);
}

.content-wrapper .container {
  max-width: 1440px;
  padding-left: 40px;
  padding-right: 40px;
}

.drop-box .dropdown-toggle {
  background: none;
  font-weight: 500;
  font-size: 0.875rem;
  color: $grayText;
  border: 1px solid $grayOpacity24;
  border-radius: $radiusS;
  width: 100%;
  height: 48px;
  padding: 0 2.25rem 0 1.25rem;
  &:focus,
  &:hover,
  &:active {
    background: none !important;
    box-shadow: none !important;
    color: $darkGray !important;
  }
  &:hover,
  &:active {
    border: 1px solid $gray;
  }
  @media (min-width: $md) {
    width: auto;
  }
}

.drop-box .dropdown-toggle:after {
  width: 8px;
  height: 8px;
  border: 1.5px solid $gray;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg);
  transition: 0.3s all;
  top: 1.125rem;
  right: 1.25rem;
  position: absolute;
}

.drop-box .dropdown-toggle.my-accounts:after {
  left: 7rem;
}

.drop-box.show .dropdown-toggle:after {
  transform: rotate(-135deg);
  vertical-align: 0;
  transition: 0.3s all;
  top: 1.25rem;
}

.drop-box {
  .show {
    button.dropdown-toggle {
      &:hover,
      &:active,
      &:visited {
        color: $darkGray;
        background: none;
      }
    }
  }
}

.drop-box + .drop-box {
  @media (min-width: $md) {
    margin-left: 0.75rem;
  }
}

.drop-box .dropdown-menu {
  min-width: 270px;
}

.date-box {
  background-color: transparent;
  font-weight: 500;
  font-size: 15px;
  color: $grayText;
  border: 1px solid $grayOpacity24;
  border-radius: $radiusS;
  width: auto;
  height: 48px;
  padding: 0;
  position: relative;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    border: 1px solid $gray;
  }
}

.date-box input:focus {
  color: $darkGray;
  border: 1px solid $gray;
}

.date-box > span {
  font-size: 0.875rem;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.date-box input {
  padding: 0 20px;
  background-color: transparent;
  height: 100%;
  border: 0;
  font-size: 0.875rem;
  color: $grayText;
  max-width: 100px;
  border-radius: $radiusS;
  cursor: pointer;
  &:hover {
    color: $darkGray;
  }
  &.active {
    color: $purple;
  }
}

.date-box input::-webkit-input-placeholder {
  color: $gray;
}

.date-box input::-moz-placeholder {
  color: $gray;
}

.date-box input:-ms-input-placeholder {
  color: $gray;
}

.date-box input:-moz-placeholder {
  color: $gray;
}

.date-box input:first-child {
  padding-right: 0;
}

.date-box input ~ input {
  padding-left: 12px;
}

.dropdown-box ul li label {
  position: relative;
  margin-right: 10px;
}

.datepicker-dropdown {
  padding: 20px;
  min-width: 260px;
}

.datepicker-dropdown.datepicker table {
  width: 100%;
}

.datepicker-dropdown:before,
.datepicker-dropdown:after {
  display: none;
}

.datepicker-dropdown .table-condensed thead th.prev,
.datepicker-dropdown .table-condensed thead th.next {
  vertical-align: middle;
  padding: 0 0 10px;
  color: transparent;
}

.datepicker-dropdown .table-condensed thead th.prev:before,
.datepicker-dropdown .table-condensed thead th.next:before {
  content: '';
  width: 8px;
  height: 8px;
  border: 1px solid $white;
  border-top: 0;
  display: inline-block;
}

.datepicker-dropdown .table-condensed thead th.prev:before {
  border-right: 0;
  transform: rotate(45deg);
}

.datepicker-dropdown .table-condensed thead th.next:before {
  border-left: 0;
  transform: rotate(-45deg);
}

.datepicker-dropdown thead tr:first-child th:hover,
.datepicker-dropdown tfoot tr th:hover {
  background-color: transparent;
}

.datepicker-dropdown .table-condensed thead th.datepicker-switch {
  font-size: 20px;
  font-weight: 600;
  padding: 0 0 10px;
  line-height: normal;
  vertical-align: middle;
  background-color: transparent;
}

.datepicker-dropdown .table-condensed thead th,
.datepicker-dropdown .table-condensed tbody td {
  color: $white;
  font-size: 14px;
}

.datepicker-dropdown .table-condensed tbody td span {
  display: block;
  width: 33.33333%;
  height: 35px;
  line-height: 35px;
  margin: 0;
}

.datepicker-dropdown .table-condensed tbody td span.disabled {
  color: $gray;
  opacity: 0.3;
}

.datepicker-dropdown .table-condensed tbody td span:hover,
.datepicker-dropdown .table-condensed tbody td span.active {
  background: $purple !important;
}

.react-datepicker__month-container {
  color: white !important;
}

.react-datepicker__month--disabled {
  color: $grayOpacity50 !important;
}

.dropdown-box ul li label input {
  opacity: 0;
  position: absolute;
  top: 0;
}

.dropdown-box ul li label span {
  width: 20px;
  height: 20px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 6px;
  margin: 0;
  margin-top: -2px;
  position: relative;
}

.dropdown-box ul li label span:before {
  content: '';
  position: absolute;
  top: -4px;
  left: -1px;
  bottom: 0;
  right: 0;
  width: 11px;
  height: 7px;
  border: 1.5px solid $purple;
  border-top: 0;
  border-right: 0;
  margin: auto;
  transform: rotate(-45deg);
  opacity: 0;
  transition: 0.3s all;
  visibility: hidden;
}

.dropdown-box ul li label input:checked ~ span:before {
  opacity: 1;
  visibility: visible;
}

.mb-15 {
  margin-bottom: 15px;
}

.ct-box {
  background: $white;
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusL;
  padding: 2rem 1.25rem;
  height: 100%;
  position: relative;
  &.measure {
    padding: 0 0 0 0 !important;
  }
}

.ct-box-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../images/networth/Blur.png');
  background-size: contain;
  min-height: 318px;
}

.networth-placeholder {
  text-align: center;
  p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 150%;
    color: $gray;
    max-width: 297px;
    margin: auto;
    margin-bottom: 20px;
  }
  a {
    height: 40px;
    margin: auto;
    width: 100%;
    max-width: 236px;
    @media (min-width: $md) {
      min-width: 236px;
    }
  }
}

.ct-box:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  border-radius: 10px 0 0 10px;
}

.box-b:before {
  background: $blue;
}

.box-g:before {
  background: $cyan;
}

.box-r:before {
  background: $red;
}

.box-v:before {
  background: $purple;
}

.table {
  margin-bottom: 0;
  display: block;
}

.table-holder .table thead th {
  font-weight: 600;
  font-size: 16px;
  color: $grayText;
  padding: 0 0 20px 0;
  vertical-align: middle;
  white-space: nowrap;
  border: 0;
  cursor: pointer;
}

.table thead th span {
  font-weight: 600;
  font-size: 20px;
  color: $black;
  position: relative;
  cursor: pointer;
}

.table thead th span:first-child::before {
  position: absolute;
  left: -18px;
  top: 6px;
  content: '';
  width: 8px;
  height: 8px;
  border: 2px solid $gray;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg);
  transition: 0.3s all;
}

.table thead th span.t-span:before {
  transform: rotate(-135deg);
  top: 8px;
}

.table-holder .table tbody td {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: $darkGray;
  padding: 15px 0;
  white-space: nowrap;
  border-bottom: 1px solid $grayOpacity20;
  border-top: none;

  &.more-icon-dropdown {
    border-bottom: none;
  }
}

.table-holder .table.account tbody td {
  white-space: pre-wrap;
}

.table thead th.current-m,
.table tbody td.current-m,
.table tfoot td.current-m,
.table-holder .table tfoot td.current-m {
  color: $green;
}

.table thead th:not(:first-child) {
  padding-left: 5px;
  padding-right: 5px;
}

.table-holder .table tbody td:first-child,
.table-holder .table tfoot td:first-child {
  font-weight: 600;
  color: $black;
  max-width: 300px;
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }
}

.table-holder .table.account tbody td:first-child.small {
  width: 100%;
  overflow-wrap: break-word;
}

.table-holder .table.account tbody td:first-child:not(.small) {
  min-width: 226px;
  overflow-wrap: break-word;
}

.table-holder .table tbody td:not(:first-child),
.table-holder .table tfoot td:not(:first-child) {
  padding: 15px 5px;
}

.table-holder .table tfoot td {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: $purple;
  padding: 15px 0;
  white-space: nowrap;
  border: 0;
}

.table tbody .table tfoot {
  transition: 0.3s all;
}

.table thead th,
.table tbody td,
.table tfoot td {
  width: 7%;
  transition: 0.3s all;
  .edited-icon {
    position: absolute;
    right: -15px;
    top: 7px;
    z-index: 100;
  }
}

.table-holder .table tbody tr:first-child {
  td:nth-last-of-type(4).projection:before {
    content: '';
    width: 8px;
    height: 8px;
    border: solid 2px $grayOpacity30;
    border-radius: $radiusS;
    position: absolute;
    top: -4px;
    left: -4px;
    background: $lightBg;
  }
  td:nth-last-of-type(4).projection.holdings-table:before {
    display: none;
  }
  td:nth-last-of-type(5).projection.holdings-table:before {
    content: '';
    width: 8px;
    height: 8px;
    border: solid 2px $grayOpacity30;
    border-radius: $radiusS;
    position: absolute;
    top: -4px;
    left: -4px;
    background: $lightBg;
  }
}

.table-holder .table {
  tbody td:nth-last-of-type(4).projection,
  tfoot td:nth-last-of-type(4).projection {
    background: linear-gradient(90deg, $purpleOpacity05 0%, $purpleOpacity04 100%);
  }

  tbody td:nth-last-of-type(5).projection.holdings-table,
  tfoot td:nth-last-of-type(5).projection.holdings-table {
    background: linear-gradient(90deg, $purpleOpacity05 0%, $purpleOpacity04 100%);
  }
  tbody td:nth-last-of-type(3).projection,
  tfoot td:nth-last-of-type(3).projection {
    background: linear-gradient(90deg, $purpleOpacity04 0%, $purpleOpacity03 100%);
  }

  tbody td:nth-last-of-type(4).projection.holdings-table,
  tfoot td:nth-last-of-type(4).projection.holdings-table {
    background: linear-gradient(90deg, $purpleOpacity04 0%, $purpleOpacity03 100%);
  }

  tbody td:nth-last-of-type(2).projection,
  tfoot td:nth-last-of-type(2).projection {
    background: linear-gradient(90deg, $purpleOpacity03 0%, $purpleOpacity02 100%);
  }

  tbody td:nth-last-of-type(3).projection.holdings-table,
  tfoot td:nth-last-of-type(3).projection.holdings-table {
    background: linear-gradient(90deg, $purpleOpacity03 0%, $purpleOpacity02 100%);
  }
  tbody td:nth-last-of-type(1).projection,
  tfoot td:nth-last-of-type(1).projection {
    background: linear-gradient(90deg, $purpleOpacity02 0%, $purpleOpacity01 100%);
  }

  tbody td:nth-last-of-type(2).projection.holdings-table,
  tfoot td:nth-last-of-type(2).projection.holdings-table {
    background: linear-gradient(90deg, $purpleOpacity02 0%, $purpleOpacity01 100%);
  }

  tbody td:nth-last-of-type(1).projection.holdings-table,
  tfoot td:nth-last-of-type(1).projection.holdings-table {
    background: linear-gradient(90deg, $purpleOpacity02 0%, $purpleOpacity01 100%);
  }
}

.warning-pop {
  background: $pink;
  max-width: 200px;
  border: 0;
  border-radius: $radiusS;
}

.warning-pop.popover .arrow:before,
.warning-pop.popover .arrow:after {
  border-right-color: $pink;
  border-width: 0.3rem 0.3rem 0.3rem 0;
}

.warning-pop.bs-popover-auto[x-placement^='right'] > .arrow,
.warning-pop.bs-popover-right > .arrow {
  left: calc(-0.2rem - 1px);
  width: 0.3rem;
  height: 0.6rem;
  margin: 0.4rem 0;
}

.warning-pop {
  padding: 14px 20px;
}

.warning-pop .popover-header {
  position: relative;
  background-color: transparent;
  color: $white;
  font-weight: 500;
  font-size: 16px;
  padding: 0;
  border: 0;
  margin-bottom: 10px;
  cursor: pointer;
}

.warning-pop .popover-header:before {
  content: 'x';
  position: absolute;
  top: -4px;
  right: 0;
  font-size: 18px;
  line-height: 1;
  font-weight: 200;
}

.warning-pop .popover-content p {
  font-size: 14px;
  line-height: 20px;
  color: $white;
}

.checkbox-list {
  padding: 10px 0;
}

.checkbox-list li:hover {
  background: rgba(83, 76, 234, 0.3);
  color: $white !important;
}

.checkbox-list li label {
  position: relative;
  width: 100%;
  padding: 10px 24px;
}

.checkbox-list:not(.single) li label {
  padding-left: 30px;
}

.checkbox-list li label input {
  opacity: 0;
  position: absolute;
  top: 0;
}

.checkbox-list li label span {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: $white;
}

.checkbox-list:not(.single) li label span:before {
  content: '';
  position: absolute;
  left: 20px;
  top: 13px;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: $radiusS;
}

.checkbox-list:not(.single) li label span:after {
  content: '';
  position: absolute;
  top: 17px;
  left: 25px;
  width: 11px;
  height: 7px;
  border: 1.5px solid $purple;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg);
  opacity: 0;
  transition: 0.3s all;
  visibility: hidden;
}

.checkbox-list li label input:checked ~ span:after {
  opacity: 1;
  visibility: visible;
}

.drop-box .dropdown-menu.dropsm {
  min-width: 150px;
}

.drop-box.active button {
  color: $purple !important;
}

.radiolist {
  padding: 10px 0;
}

.radiolist li {
  padding: 10px 24px;
}

.radiolist li:hover {
  background: rgba(83, 76, 234, 0.3);
}

.radiolist label {
  position: relative;
  padding-left: 30px;
}

.radiolist label input {
  opacity: 0;
  position: absolute;
  top: 0;
}

.radiolist label span {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.02em;
  color: $white;
}

.radiolist label span:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  border-radius: 50%;
}

.radiolist label span:after {
  content: '';
  position: absolute;
  top: 8px;
  left: 6px;
  width: 8px;
  height: 8px;
  background: $purple;
  border-radius: 50%;
  opacity: 0;
  transition: 0.3s all;
  visibility: hidden;
}

.radiolist label input:checked ~ span:after {
  opacity: 1;
  visibility: visible;
}

.padd-20 {
  padding: 20px;
}

.measure-box {
  .title {
    font-weight: 600;
    font-size: 1.25rem;
    color: $purple;
    margin-bottom: 1.25rem;
    img {
      max-width: 20px;
      vertical-align: -3px;
      margin-right: 10px;
    }
    @media (min-width: $bs-lg) {
      font-size: 1rem;
    }
    @media (min-width: $lg) {
      font-size: 1.25rem;
    }
  }
  .measure-label {
    font-weight: 300;
    font-size: 0.875rem;
  }
  ul {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 30px;
    li {
      flex: 0 0 50%;
      max-width: 50%;
      height: 64px;
      @media (min-width: $header) {
        flex: 0 0 25%;
        max-width: 25%;
      }
      @media (min-width: $bs-lg) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      span {
        display: block;
      }
    }
  }
  .measure-amount {
    font-weight: 200;
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
  .inv-data {
    color: $blue;
  }
  .other-data {
    color: $cyan;
  }
  .lty-data {
    color: $red;
  }
  .nw-data {
    color: $purple;
  }
  p {
    font-weight: 500;
    font-size: 16px;
    color: $grayText;
  }
  .measure-box-locked {
    min-height: 200px;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $radiusS;
    position: relative;
    height: 78%;
    background-size: contain;
    background-image: url('../../images/networth/chart-blur.png');
    .mm-btn-primary {
      bottom: -55px;
      font-size: 1rem;
      position: absolute;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 40px;
      @media (min-width: $sm) and (max-width: 991px) {
        position: initial;
        max-width: 236px;
        margin: auto;
      }
    }
    @media (max-width: 575px) {
      display: block;
      min-height: inherit;
      padding: 15px;
      position: initial;
      height: 80%;
      .mm-btn-primary {
        position: relative;
        width: 100%;
        height: 40px;
        display: inline-block;
        margin: 0 auto;
        bottom: 0;
      }
      p {
        max-width: 100%;
      }
      .btn-blue {
        margin-top: 20px;
        max-width: 100%;
      }
    }
    @media (max-width: 991px) {
      padding: 30px 25px 20px 25px;
      margin-bottom: 0;
      min-height: 110px;
      -webkit-align-items: flex-start;
      align-items: flex-start;
      p {
        max-width: 270px;
        margin-right: auto;
      }
      .btn-blue {
        position: static;
        max-width: 230px;
      }
    }
  }
}

.btn-blue {
  background: $purple;
  border-radius: $radiusS;
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: $white;
  height: 40px;
  line-height: 40px;
  border: 0;
  padding: 0 12px;
}

.btn-blue:hover {
  color: $white;
}

.btn-full {
  display: block;
  width: 100%;
}

.mb-40 {
  margin-bottom: 40px;
}

.graphbox {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  width: 100%;
  min-height: 100%;
  justify-content: space-around;
  .title {
    font-weight: 600;
    font-size: 1.25rem;
    color: $purple;
    order: 1;
    display: inherit;
    @media (min-width: $med) {
      display: none;
    }
  }
  .graphbox-label {
    font-weight: 300;
    font-size: 0.875rem;
  }
  .graphbox-amount {
    font-weight: 200;
    font-size: 1.25rem;
    line-height: 1.875rem;
  }
  ul {
    -ms-flex: 0 0 180px;
    -webkit-flex: 0 0 180px;
    flex: 0 0 180px;
    max-width: 180px;
    padding-top: 16px;
    border-top: 1px solid $grayOpacity10;
    @media (min-width: $sm) {
      padding-top: 12px;
      border-top: none;
    }
  }
}

.graphbox ul li span {
  font-weight: 300;
  font-size: 0.875rem;
  color: inherit;
  display: block;
}

.graphbox ul li h3 {
  font-weight: 200;
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: inherit;
}

.graphbox ul li + li {
  margin-top: 22px;
}

.graphbox ul li.inv-data {
  color: $blue;
  margin-left: 1px;
  padding-left: 18px;
  @media (min-width: $med) {
    margin-left: -20px;
    border-left: 2px solid $blue;
  }
}

.graphbox ul li.other-data {
  color: $cyan;
  margin-left: -15px;
  padding-left: 18px;
  max-width: 138px;
  @media (min-width: $sm) {
    max-width: 180px;
  }
  @media (min-width: $med) {
    margin-left: -20px;
    border-left: 2px solid $cyan;
  }
}

.graphbox ul li.lty-data {
  color: $red;
  margin-left: 1px;
  padding-left: 18px;
  @media (min-width: $med) {
    margin-left: -20px;
    border-left: 2px solid $red;
  }
}

.graphbox ul li.nw-data {
  color: $purple;
  padding: 0 20px;
  margin-left: -15px;
  border-bottom-right-radius: $radiusS;
  border-top-right-radius: $radiusS;
  max-width: 138px;
  height: 64px;
  .graphbox-amount {
    font-weight: 300;
  }
  @media (min-width: $sm) {
    max-width: 180px;
  }
  @media (min-width: $med) {
    padding: 10px 20px;
    margin-left: -20px;
    color: $white;
    background: $purple;
    border-left: 2px solid $purple;
  }
}

.account-ct-box {
  .graphbox ul li.inv-data {
    margin-left: -20px;
    padding-left: 18px;
    @media (min-width: $med) {
      margin-left: -20px;
    }
  }
  .graphbox ul li.other-data {
    margin-left: -20px;
  }
  .graphbox ul li.lty-data {
    margin-left: -20px;
  }
}

.responsive-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  & > div {
    min-height: 306px;
  }
  // net-worth barchart tooltip customization
  .bar-tooltip {
    background-color: $darkBg;
    color: #ffffff;
    box-shadow: 0 4px 28px rgba($darkBg, 0.44);
    border-radius: $radiusS;
    padding: 20px 12px 4px;
    .item-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      div {
        width: 10px;
        height: 10px;
        margin-right: 12px;
        border-radius: $radiusXS;
      }
      span {
        font-size: 0.875rem;
        line-height: 16px;
      }
    }
    .item-value {
      margin-left: 22px;
      margin-bottom: 16px;
      font-style: normal;
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 30px;
    }
  }
}

.chartbox {
  flex: 0 0 calc(100% - 180px);
  max-width: calc(100% - 180px);
}

.chartbox canvas {
  width: 100% !important;
}

.navbar-toggler {
  background: none;
  width: 22px;
  height: 14px;
  border: 0;
  padding: 0 !important;
  display: flex;
  z-index: 2;
  margin-top: 22px;
  margin-right: 20px;
}

.navbar-toggler:focus {
  outline: 0;
  box-shadow: none;
}

.navbar-toggler span {
  width: 100%;
  height: 2px;
  background: $black;
  margin: auto;
  position: relative;
}

.navbar-toggler span:before,
.navbar-toggler span:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 2px;
  background: $black;
  transition-property: margin, -webkit-transform;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-delay: 0.2s, 0s;
  -o-transition-delay: 0.2s, 0s;
  transition-delay: 0.2s, 0s;
}

.navbar-toggler span:before {
  margin-top: -6px;
}

.navbar-toggler span:after {
  margin-bottom: -6px;
}

.navbar-toggler:not(.collapsed) span {
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  // -webkit-transition-delay: 0s, 0.5s;
  // -o-transition-delay: 0s, 0.5s;
  // transition-delay: 0s, 0.5s;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    opacity 0.55s ease;
  margin-top: 0;
}

.navbar-toggler:not(.collapsed) span:after {
  margin-bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  // -webkit-transition-delay: 0s, 0.5s;
  // -o-transition-delay: 0s, 0.5s;
  // transition-delay: 0s, 0.5s;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    opacity 0.55s ease;
  width: 100%;
}

.warning-toast {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 400;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
  background: $pink;
  box-shadow: 0 4px 8px rgba(252, 142, 148, 0.3);
  border-radius: $radiusS;
  border: 0;
  margin-top: 75px;
}

.warning-toast .toast-body {
  padding: 10px 15px;
  font-size: 15px;
  color: $white;
}

.warning-toast > span {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  color: $white;
  font-size: 14px;
  margin: auto;
  height: 18px;
  cursor: pointer;
}

.modal-dialog {
  min-height: calc(100vh - 60px);
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  overflow: auto;
}

#upgradeModal .modal-dialog {
  max-width: 520px;
}

#upgradeModal .modal-content {
  background: $white;
  border-radius: $radiusS;
  border: 0;
}

#upgradeModal .modal-header {
  padding: 30px 40px;
  padding-bottom: 20px;
  border-bottom: 0;
}

#upgradeModal .modal-header .modal-title {
  font-size: 28px;
  line-height: 35px;
  color: $black;
}

#upgradeModal .modal-body {
  padding: 0 40px 30px;
}

#upgradeModal .modal-body p {
  font-weight: 500;
  font-size: 15px;
  color: $gray;
  line-height: 1.7;
  max-width: 400px;
}

.table-holder .table tbody td.dataTables_empty {
  display: none;
}

.table:not(#table-net-xls) tbody tr:hover,
.table:not(#table-net-xls) tfoot tr:hover {
  cursor: pointer;
}
/** =============================== **/
/** footer **/
/** =============================== **/

footer {
  border-top: 1px solid rgba(150, 158, 172, 0.25);
  padding: 25px 0;
}

footer .container {
  max-width: 1440px;
  padding-left: 25px;
  padding-right: 25px;
}

footer p {
  font-weight: 500;
  font-size: 15px;
  color: $gray;
}

.foot-link {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.foot-link li + li {
  margin-left: 40px;
}

.foot-link li a {
  font-weight: 500;
  font-size: 15px;
  color: $gray;
}

.foot-link li a:hover {
  color: $darkGray;
}

.table-holder tbody tr td span,
.table-holder tfoot tr td span {
  display: none;
}

.tb-responsive {
  overflow-x: auto;
  padding-left: 20px;
  padding-right: 20px;

  .table-account-name {
    @media (max-width: $med) {
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      color: $black;
    }
  }
}

.d-hide {
  display: none !important;
}

.s-hide {
  display: none !important;
  @media (min-width: $med) {
    display: block !important;
  }
}
.connection-status {
  &.error::before,
  &.pending::before {
    background: $redAlert;
    content: '';
    position: absolute;
    left: -20px;
    top: 18px;
    width: 6px;
    height: 6px;
    border-radius: 2px;
    margin-top: 3px;
  }
  &.success::before {
    background: $green;
    content: '';
    position: absolute;
    left: -20px;
    top: 18px;
    width: 6px;
    height: 6px;
    border-radius: 2px;
    margin-top: 3px;
  }
  &.warning::before {
    background: $yellow;
    content: '';
    position: absolute;
    left: -20px;
    top: 18px;
    width: 6px;
    height: 6px;
    border-radius: 2px;
    margin-top: 3px;
  }
  &.closed::before {
    background: $gray;
    content: '';
    position: absolute;
    left: -20px;
    top: 18px;
    width: 6px;
    height: 6px;
    border-radius: 2px;
    margin-top: 3px;
  }
  &.manual::before {
    background: $blue;
    content: '';
    position: absolute;
    left: -20px;
    top: 18px;
    width: 6px;
    height: 6px;
    border-radius: 2px;
    margin-top: 3px;
  }
}

.dflex-center {
  display: flex;
  justify-content: center;
  .clear-filter {
    margin-right: 0.5rem;
    background: none;
    font-weight: 500;
    font-size: 0.875rem;
    color: $redAlert;
    border-radius: $radiusS;
    border-color: $redAlertOpacity24;
    width: auto;
    height: 48px;
    padding: 0 20px;
    min-width: 122px;
    &:focus,
    &:hover,
    &:active {
      background: none !important;
      box-shadow: none !important;
      color: $red !important;
      border-color: $red;
    }
  }
  .add-filter {
    color: $primary;
    border-color: $primary;
    &:focus,
    &:hover,
    &:active {
      box-shadow: none !important;
      color: $primary !important;
      border-color: $primary;
    }
  }
}

.dropdowns-container {
  display: block;
  @media (min-width: 1185px) {
    display: flex;
  }
}

.flex-wrap-custom {
  flex-wrap: wrap;
  @media (min-width: $md) {
    flex-wrap: unset;
  }
}

.justify-space-between {
  justify-content: space-between;
  @media (min-width: $md) {
    justify-content: center;
  }
}
/** =============================== **/
/** responsive **/
/** =============================== **/

@media (max-width: 1366px) {
  .table thead th {
    font-size: 14px;
  }
  .table thead th span {
    font-size: 18px;
  }
  .table tbody td,
  .table tfoot td {
    font-size: 13px;
  }
  .table thead th,
  .table tbody td,
  .table tfoot td {
    width: 6%;
    transition: 0.3s all;
  }
  .tab-hide {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .money-minx-header .navbar-brand + .headtab {
    margin-left: 40px;
  }
  .headtab a + a {
    margin-left: 40px;
  }
}

@media (max-width: 991px) {
  .navbar-toggler {
    display: flex;
  }
  .mb-40 {
    margin-bottom: 30px;
  }
  .mob-btm + .mob-btm {
    margin-top: 30px;
  }
  .tab-hide {
    display: none !important;
  }
  .foot-link li + li {
    margin-left: 25px;
  }
  footer p,
  .foot-link li a {
    font-size: 14px;
  }
  .content-wrapper .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .table-holder .table tbody td:nth-last-of-type(3),
  .table-holder .table tfoot td:nth-last-of-type(3) {
    background: transparent;
  }
  .chartbox {
    position: relative;
  }
  ul.charttool {
    left: 0;
    transform: inherit;
    top: 0;
    display: none;
  }
}
.table tbody tr td {
  position: relative;
  transition: 0.1s all;
}

@media (min-width: 768px) {
  .table tbody tr {
    transition: 0.1s all;
  }
  .table tbody tr td {
    position: relative;
    transition: 0.1s all;
  }
  .table:not(#table-net-xls) tbody tr:hover {
    background: $darkBg;
    color: $white;
  }
  .table:not(#table-net-xls) tbody tr:hover td {
    color: $white !important;
    border-bottom-color: transparent;
  }
  .table tbody tr td:first-child:before,
  .table tbody tr td:last-child:after {
    content: '';
    position: absolute;
    top: -1px;
    bottom: 0;
    width: 20px;
    height: calc(100% + 1px);
    background: transparent;
    transition: 0.1s all;
  }
  .table tbody tr:first-child td:first-child:before,
  .table tbody tr:first-child td:last-child:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20px;
    height: 100%;
    background: transparent;
    transition: 0.1s all;
  }
  .table tbody tr td:first-child:before {
    left: -20px;
    border-radius: $radiusS 0 0 $radiusS;
  }
  .table tbody tr td:last-child:after {
    right: -20px;
    border-radius: 0 $radiusS $radiusS 0;
  }
  .table:not(#table-net-xls) tbody tr:hover td:first-child:before,
  .table:not(#table-net-xls) tbody tr:hover td:last-child:after {
    background: $darkBg;
  }
}

@media (max-width: $med) {
  .table-holder .table tbody td:nth-last-of-type(3),
  .table-holder .table tfoot td:nth-last-of-type(3) {
    background: transparent;
  }
  .chartbox canvas {
    min-height: 300px;
  }
  .badge-box,
  .money-minx-header .navbar-brand {
    margin-right: auto;
  }
  .upgrader-btn {
    margin-right: 20px;
  }
  .graphbox {
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .graphbox > ul {
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .graphbox ul li {
    -webkit-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .graphbox ul li + li {
    margin-top: 0;
  }
  .chartbox {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .foot-link {
    justify-content: flex-start;
    margin-top: 20px;
  }
  .table-holder .table {
    width: 100% !important;
  }
  .table-holder .table,
  .table-holder .table thead,
  .table-holder .table tbody,
  .table-holder .table tfoot,
  .table-holder .table tr,
  .table-holder .table th,
  .table-holder .table td {
    display: block;
  }
  .table-holder .table thead,
  .table-holder .table tbody,
  .table-holder .table tfoot,
  .table-holder .table tr {
    width: 100%;
  }
  .table thead th:not(:first-child) {
    display: none;
  }
  .table-holder .table thead tr th:first-child {
    width: 100% !important;
    padding-bottom: 20px;
    border-bottom: 1px solid $grayOpacity20;
  }
  .table-holder .table tbody tr,
  .table-holder .table tfoot tr {
    display: -webkit-flex;
    display: flex;
    // justify-content: space-between;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    border-bottom: 1px solid $grayOpacity20;
    padding-bottom: 10px;
  }
  .table-holder .table tfoot tr {
    border-bottom: 0;
  }
  .table-holder .table tfoot + tbody tr {
    border-bottom: 0;
    padding: 0;
  }
  .table-holder .table tbody td,
  .table tfoot td {
    font-size: 12px;
  }
  .table-holder .table tbody td:not(:first-child),
  .table-holder .table tfoot td:not(:first-child) {
    width: calc(100% / 3);
    padding: 10px 0;
    padding-right: 50px;
    border: 0;
  }
  .table-holder .table tfoot tr td:before,
  .table-holder .table tbody tr td:before {
    content: '';
    font-weight: 500;
    font-size: 12px;
    color: $gray;
    display: block;
    margin-bottom: 5px;
  }
  .table-holder .table tfoot tr td:nth-child(6):before,
  .table-holder .table tbody tr td:nth-child(6):before {
    content: attr(data-title4);
  }
  .table-holder .table tfoot tr td:nth-child(7):before,
  .table-holder .table tbody tr td:nth-child(7):before {
    content: attr(data-title5);
  }
  .table-holder .table tfoot tr td:nth-child(8):before,
  .table-holder .table tbody tr td:nth-child(8):before {
    content: attr(data-title6);
  }
  .table-holder .table tfoot tr td:nth-child(9):before,
  .table-holder .table tbody tr td:nth-child(9):before {
    content: attr(data-title7);
  }
  .table-holder .table tfoot tr td:nth-child(10):before,
  .table-holder .table tbody tr td:nth-child(10):before {
    content: attr(data-title8);
  }
  .table-holder .table tbody tr td:last-child {
    padding-right: 0;
  }
  .table-holder .table tbody tr:first-child td {
    padding-top: 10px;
  }
  .table-holder .table tbody tr td:first-child,
  .table-holder .table tfoot tr td:first-child {
    width: 100%;
    padding-top: 20px;
    border: 0;
    font-size: 16px;
  }
  .table-holder .table tbody td:not(:first-child),
  .table-holder .table tfoot td:not(:first-child) {
    padding: 10px 0;
  }

  .mob-btm .ct-box {
    padding: 15px;
  }
  .mob-btm .ct-box.padd-20 {
    padding: 20px;
  }
  footer .container,
  .money-minx-header .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .upgrader-btn {
    margin-right: 15px;
  }
}

@media (max-width: 575px) {
  .mb-40 {
    margin-bottom: 20px;
  }
  .foot-link {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .foot-link li {
    width: 50%;
    margin-bottom: 20px;
  }
  .foot-link li + li {
    margin-left: 0;
  }
  footer {
    padding: 20px 0 0;
  }
  .btnbox {
    display: block;
  }
  .btnbox a {
    display: block;
  }
  .btnbox .btn-com {
    width: 100%;
  }
  .btnbox .btn-normal {
    text-align: center;
  }
  .btnbox .btn-com + .btn-normal {
    margin-left: 0;
    margin-top: 30px;
  }
  #upgradeModal .modal-header {
    padding: 30px 20px 20px;
  }
  #upgradeModal .modal-body {
    padding: 0 20px 30px;
  }
  .drop-box .dropdown-toggle {
    font-size: 14px;
  }
  .table-holder tbody tr td span,
  .table-holder tfoot tr td span {
    display: block;
    color: $gray;
    font-size: 12px;
  }
  .table-holder tbody tr td span.current-m,
  .table-holder tfoot tr td span.current-m {
    color: $green;
  }
  .hide-type {
    display: none !important;
  }
}

@media (max-width: 370px) {
  .graphbox ul li span {
    font-size: 12px;
  }
  .graphbox ul li h3 {
    font-size: 21px;
  }
}

.mm-dropdown-menu {
  @extend .dropdown-menu;
  background-color: $darkBg !important;
  width: 100%;
}
/****************************/
/** bootstrap custom ***/
/***************************/

$class-name: col !default;
$class-auto: auto;
$start-width: 100%;
.#{$class-name}-#{$class-auto} {
  -webkit-flex: 0 0 $class-auto;
}

@for $var from 1 through 12 {
  .#{$class-name}-#{$var} {
    -webkit-flex: 0 0 $start-width / $var;
  }
}

@media (min-width: 576px) {
  .#{$class-name}-sm-#{$class-auto} {
    width: 0 0 $class-auto;
  }
  @for $var from 1 through 12 {
    .#{$class-name}-sm-#{$var} {
      -webkit-flex: 0 0 $start-width / $var;
    }
  }
}

@media (min-width: 768px) {
  .#{$class-name}-md-#{$class-auto} {
    width: 0 0 $class-auto;
  }
  @for $var from 1 through 12 {
    .#{$class-name}-md-#{$var} {
      -webkit-flex: 0 0 $start-width / $var;
    }
  }
}

@media (min-width: 992px) {
  .#{$class-name}-lg-#{$class-auto} {
    width: 0 0 $class-auto;
  }
  @for $var from 1 through 12 {
    .#{$class-name}-lg-#{$var} {
      -webkit-flex: 0 0 $start-width / $var;
    }
  }
}

@media (min-width: 1200px) {
  .#{$class-name}-xl-#{$class-auto} {
    width: 0 0 $class-auto;
  }
  @for $var from 1 through 12 {
    .#{$class-name}-xl-#{$var} {
      -webkit-flex: 0 0 $start-width / $var;
    }
  }
}

.date-separator {
  margin: 1rem 0.5rem 1rem 0.5rem;
  color: $grayText;
  font-size: 0.875rem;
}

.clear-filter {
  .dropdown-toggle {
    color: $red;
    &:hover,
    &:visited,
    &:active {
      color: $red !important;
    }
    &:after {
      display: none;
    }
  }
}

.drop-box {
  .filtered {
    color: $primary;
    &:hover,
    &:visited,
    &:active {
      color: $primary !important;
    }
  }
}

.date-separator.active {
  color: $purple;
}

.justify-space-between {
  .clear-filter {
    width: calc(50% - 4px);
  }
  .react-datepicker-wrapper {
    max-width: 150px;
    .react-datepicker {
      &__input-container {
        margin-bottom: 0.75rem;
      }
    }
  }
}

/* Getting Started */
.getting-started {
  margin-top: -120px;
  height: 80vh;
}
.getting-started-section {
  background-image: url('../../images/networth/getting-started-background.jpg');
  background-size: cover;
  text-align: center;
}
.getting-started-card {
  max-width: 528px;
  margin: 200px auto 0 auto;
  padding: 40px;
  border-radius: $radiusS !important;
}
.getting-started-title {
  font-style: normal;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 2.875rem;
  text-align: center;
  color: $black;
}
.getting-started-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  color: $grayText;
  text-align: center;
  margin-top: 6px;
}
.list-title {
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.625rem;
  color: $purple;
  display: block;
  margin-bottom: 16px;
  margin-top: 30px;
}
.getting-started-list {
  margin: 0 auto 40px auto;
  display: flex;
  .getting-started-step {
    display: flex;
    align-items: center;
  }
  button {
    width: 120px;
    height: 40px;
    display: block;
  }
  .completed-step {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $green;
    text-align: left;
    display: block;
    margin-top: 10px;
  }
  .steps-with-button {
    margin-top: 36px;
    margin-bottom: 24px;
  }
  .current-step {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $purple;
    display: block;
    text-align: left;
    margin-top: 14px;
  }
  .next-step {
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: $gray;
    display: block;
    text-align: left;
  }
}
.getting-started-button {
  margin: auto;
  text-align: center;
  width: 100%;
  max-width: 276px;
}

// Added this for using scoped css classes
.networth-container,
.performance-container,
.income-container,
.discover {
  .modal-title {
    margin-top: -1rem;
  }

  .filter-saved-view-wrapper {
    width: 100%;
    justify-content: space-between;

    .drop-box.dropdown {
      max-width: 144px;
    }

    .view-dropdown {
      ul {
        margin-bottom: 0;
      }

      .btn-icon {
        padding: 0;
        background-color: transparent;
      }

      &__list {
        color: $white;

        li {
          width: 100%;
          display: flex;
          padding: 0.5rem 1rem;
          justify-content: space-between;

          &:first-child {
            margin-top: 1rem;
          }

          &:last-child {
            margin-bottom: 1rem;
          }

          &:hover {
            background-color: rgba(83, 76, 234, 0.3);
          }
        }

        li + li.list-actions {
          margin-top: 1rem;
          padding-top: 0.5rem;
          justify-content: unset;
          span {
            margin-right: 1rem;
          }
        }
      }
    }
  }

  .view-modal-form-wrapper {
    .checkbox-wrapper {
      position: relative;
      font-size: 0.875rem;
      color: $black;
      font-weight: 400;
    }

    .checkbox-delete-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        display: inline-block;
        padding-top: 2px;
      }
    }

    // FIXME: code is repeated in common (Remove it once verified)

    .custom-checkbox {
      cursor: pointer;
      font-size: 1.375rem;
      position: absolute;
      top: 2px;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input:checked ~ .checkmark {
        background-color: #fff;
        &:after {
          display: block;
          color: $primary;
          margin-left: 6px;
        }
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        font-size: 0.875rem;
        border: 1px solid rgba(150, 158, 172, 0.239216);
        border-radius: $radiusS;
        padding: 0 50px 0 20px;
        color: #434a55;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 1.25rem;
        width: 1.25rem;
        border: 1px solid rgba(83, 76, 234, 0.160784);
        box-sizing: border-box;
        border-radius: 0.25rem;
        font-size: 0.875rem;
        color: #000;
        font-weight: 600;

        &:after {
          content: '';
          left: 6px;
          top: 1px;
          width: 5px;
          height: 0.75rem;
          border: solid #534cea;
          border-width: 0 1px 1px 0;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          display: none;
        }
      }
    }
  }
  .filter__row {
    padding-left: 2.6875rem;
    button {
      height: 48px;
    }
    .btn-add-filter {
      font-size: 0.875rem;
      @media (min-width: $header+1) {
        display: none;
      }
    }
    .dropdowns-container {
      @media (max-width: $md) {
        display: none;
      }
    }
    .datepicker-wrapper {
      display: flex;
    }
  }
  .clear-filter {
    &__desktop {
      margin-right: 1rem;
      background: none;
      font-weight: 500;
      font-size: 0.875rem;
      color: $redAlert;
      border-radius: $radiusS;
      border-color: $redAlertOpacity24;
      width: auto;
      height: 48px;
      padding: 0 20px;
      min-width: 122px;
      &:focus,
      &:hover,
      &:active {
        background: none !important;
        box-shadow: none !important;
        color: $red !important;
        border-color: $red;
      }

      @media (max-width: $md) {
        display: none;
      }
    }
  }
  .btn-more-filter {
    margin-right: 1rem;
    background: none;
    font-weight: 500;
    font-size: 0.875rem;
    color: $purple;
    border-radius: $radiusS;
    border-color: $purpleOpacity24;
    width: auto;
    height: 48px;
    padding: 0 20px;
    min-width: 122px;
    &:focus,
    &:hover,
    &:active {
      background: none !important;
      box-shadow: none !important;
      color: $purple !important;
      border-color: $purple;
    }
    @media (max-width: $header) {
      display: none;
    }
  }
  @media (max-width: $header) {
    .desktop-filters {
      display: none;
    }
  }

  .dropdown-filter {
    &__body {
      display: flex;
      flex-direction: column;

      .dflex-center {
        display: flex;
        flex-direction: column;

        .drop-box {
          input {
            max-width: none;

            &:focus {
              border: none;
            }

            &:focus-visible {
              outline: none;
              outline-offset: unset;
            }
          }

          button {
            width: 100%;
          }

          @media (max-width: $md) {
            margin-right: 1rem;
          }
        }

        .datepicker-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 0.5rem;
        }

        .to-date-popper {
          transform: translate3d(150px, 188px, 0px) !important;

          @media (max-width: $md) {
            transform: translate3d(105px, 188px, 0px) !important;
          }
        }
      }

      @media (min-width: $md) {
        .drop-box + .drop-box {
          margin-left: 0;
        }
        .ml-md-3,
        .mr-md-3 {
          margin: 0 !important;
        }

        .date-box {
          input {
            border: 0;
          }
        }
      }

      .dropdowns-container {
        @media (min-width: 1185px) {
          display: flex;
          flex-direction: column;
        }
      }
    }

    &__action-wrapper {
      display: flex;
      flex-direction: column;

      button {
        width: 100%;

        &:not(:first-child) {
          margin-top: 1rem;
        }
      }

      @media (min-width: $md) {
        flex-direction: row;
        justify-content: space-between;

        button {
          width: 45%;

          &:not(:first-child) {
            margin-top: 0;
          }
        }
      }
    }
  }
}

//  Performance screen specific Design here
.performance-container,
.income-container {
  .filter-wrapper {
    &__mobile {
      .clear-filter__desktop {
        display: none;
      }

      @media (min-width: $md) {
        display: none;
      }
    }

    &__desktop {
      @media (max-width: $md) {
        display: none;
      }
    }
  }

  .shortcut-wrapper {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background: rgba(31, 50, 173, 0.03);
    border: 1px solid rgba(150, 158, 172, 0.24);
    border-radius: $radiusS;
    margin-bottom: 20px;

    .shortcut-title {
      font-size: 1rem;
      color: $darkGray;
      font-weight: 600;
    }

    .shortcut-list {
      display: flex;
      align-items: center;
      margin-bottom: 0;

      li {
        font-size: 14px;
        display: inline-block;
        height: 100%;
        margin-left: 30px;
        align-self: center;
        color: $gray;
        &:first-child {
          margin-left: 120px;
        }
        &.active {
          color: $green;
          font-weight: 500;
        }
      }
    }
  }

  .graphbox {
    .portfolio-list {
      margin-top: -1rem;
      background: transparent;
      color: $blue;

      &__sub-text {
        opacity: 0.4;
        font-size: 11px;
      }

      &__label {
        font-size: 1rem;
      }

      &__percentage {
        font-size: 1.5rem;
        white-space: nowrap;
      }
    }
  }

  .similar-investors {
    position: relative;

    svg {
      margin-right: 1rem;
    }

    &__info {
      margin-top: 4rem;

      p {
        color: $primary;
        font-size: 50px;
        font-weight: 300;
        line-height: unset;
        margin: unset;

        &:first-child {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }

    &__action {
      position: absolute;
      left: 20px;
      bottom: 20px;
      text-align: center;
    }
  }

  .table-holder {
    tbody {
      span {
        display: unset;
      }

      td.mw-100 {
        @media (max-width: $md+1) {
          width: 100%;
        }
      }

      span.mobile-header {
        display: block !important;
        padding-bottom: 0.5rem;
        color: $black;

        @media (min-width: $md+1) {
          display: none !important;
        }
      }
    }
  }
}

.portfolio-table {
  .table-header__item {
    min-width: 110px;
  }

  td.projection-last-4 {
    background: linear-gradient(90deg, $purpleOpacity04 0%, $purpleOpacity03 100%) !important;
  }

  td.projection-last-3 {
    background: linear-gradient(90deg, $purpleOpacity03 0%, $purpleOpacity02 100%) !important;
  }

  td.projection-last-2 {
    background: linear-gradient(90deg, $purpleOpacity02 0%, $purpleOpacity01 100%) !important;
  }

  td.projection-last-1 {
    background: linear-gradient(90deg, $purpleOpacity02 0%, $purpleOpacity01 100%) !important;
  }

  td.projection-start {
    border-left: 1px solid $purpleOpacity05;
  }

  tr:first-child td.projection-start {
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border: solid 2px #969eac4d;
      border-radius: $radiusS;
      position: absolute;
      top: -4px;
      left: -4px;
      background: #fafbfd;
    }
  }
}

.table-header__item {
  min-width: 110px;

  .sort-icon-container {
    display: inline-block;
    padding-left: 0.2rem;
  }
}

.stacked-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rotate-180 {
  transform: rotate(180deg);
  margin-bottom: 2px;
  margin-left: 2px;
}

.spinner-container {
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}
