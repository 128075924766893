.error-fallback-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
  padding: 32px;

  button {
    margin: 32px 0;
    min-width: 200px;
  }

  .error-trace-container {
    padding: 32px;
  }

  .error-title {
    font-size: 24px;
  }

  .error-stack {
    border-left: 5px solid $redAlert;
    padding-left: 16px;
  }
}
