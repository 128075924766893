.gallery-card {
  background: $white;
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0 -1px 0 $whiteInsetShadow;
  border-radius: $radiusS;
  font-size: 0.875rem;
  min-height: 150px;
  width: 300px;
  height: 200px;
  position: relative;

  p {
    font-size: 0.875rem;
  }
}
.gallery-image,
.gallery-card iframe {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 12px;
}
.media-download-button {
  &:hover {
    stroke: $purple;
    stroke-width: 0.2px;
  }
}
