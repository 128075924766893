.website-footer {
  margin-top: 145px;
  position: relative;
}

.website-footer-wrapper {
  padding: 19rem 1rem 1rem 1rem;

  @media (min-width: $sm) {
    padding: 19rem 2rem 1rem 2rem
  }
  @media (min-width: $md) {
    padding: 19rem 4rem 3rem 5rem
  }
  @media (min-width: $lg) {
    padding: 19rem 4rem 4rem 4rem
  }
}

.signup-today-wrapper {
  padding: 1.5rem;
  border-radius: $radiusS;
  min-height: 290px;
  left: 20px;
  right: 20px;
  top: -145px;

  &.footer {
    position: absolute;
  }

  h1 {
    font-size: 2rem !important;
  }

  p {
    margin-top: 1.25rem;
  }

  @media (min-width: $md) {
    padding: 1rem 8rem;
    min-height: 290px;
    left: 85px;
    right: 85px;
    top: -145px;

    button {
      margin-bottom: 20px !important;
    }

    p {
      padding: 10px 0 12px 0;
    }
    h1 {
      font-size: 3.5rem !important;
    }
  }

  @media (min-width: $lg) {
    padding: 1rem 12rem;
    min-height: 290px;
    left: 120px;
    right: 120px;
    top: -145px;
  }
}

.wf-list-wrapper p {
  opacity: 0.8;
  line-height: 1.4375rem;
  margin-top: 2.25rem;
  font-size: 1.1rem;
}

.info-wrapper {
  font-size: 0.9375rem;
  line-height: 170%;
}

.footer-hero-wrapper {
  margin-bottom: 0;
  margin-top: 10px;
  @media (min-width: $sm) {
    margin-top: 0;
  }
  @media (min-width: $md) {
    margin-bottom: 50px;
  }
}

@media (max-width: $md) {
  .wf-list-wrapper {
    text-align: left;
  }
}

.signup-btn-wrapper {
  width: 100%;
  margin: auto;

  button {
    display: inline-block;
    margin-bottom: 20px;
    margin-left: 0 !important;

    @media (max-width: $sm + 200) {
      width: 100%;
    }
  }
}

@media (max-width: $sm) {

  .btn-xs-block {
    width: 100%;
    margin: 1.1rem 0 !important;
  }
}

.social-links {
  opacity: 0.8;
  margin-right: 16px;
  padding: 8px 0;

  &:hover {
    opacity: 1;
  }
}

.wf-list-wrapper {
    text-align: left;

  .footer-feature-title {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 2.2;
    display: block;
  }

  .footer-feature {
    opacity: 0.8;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.5;
    display: block;
    color: #ffffffcc;
    height: auto;
    margin-bottom: 20px;
  }

    p {
      margin-top: 0;
    }
  }

.wf-list-items {
    display: block;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    margin-top: 1rem;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;

  h3 {
    color: $whiteOpacity80;
    opacity: .80;
    font-size: 1.1rem;
    font-weight: 300;
    height: auto;
    line-height: 23px;
    margin-bottom: 20px;
  }

     a {
       opacity: 0.8;

       &:hover {
         border-bottom: 1px solid $white;
         opacity: 1;
       }
     }

    p.light {
      min-width: 50% !important;
    }

    @media (min-width: $md) {
      display: initial;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 2rem;
      margin-top: 1rem;
    }

    @media (min-width: $lg) {
      p.light {
        font-size: 1.125rem;
        margin-top: 1.8rem;
      }
    }

  }

.download-btn-wrapper {
    display: flex;

    .first-svg-item {
      margin-right: 0.5rem;
    }
  }

.download-btn-wrapper {
  height: 100px;
  line-height: 160px;

  @media (min-width: $sm) {
    height: 160px;
  }

}

// for responsiveness between mobile and tablet view

@media (min-width: $lg) {
  .coming-soon-wrapper {
    padding-left: 6.375rem;

    h1 {
      font-size: 3.5rem !important;
    }
  }

  .info-wrapper {
    padding-right: 2.5rem;
  }

}

@media (max-width: $sm + 200) {
  .networth-text {
    margin-bottom: 40px;
  }
}
