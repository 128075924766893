.right-menu-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .nav-item {
    padding: 0 0.5rem;
    font-size: 1rem;
    @media (min-width: $bs-lg) {
      font-size: 1.125rem;
    }

    .nav-link {
      color: $darkGray;
    }
  }

  .expand-none {
    display: none;

    @media (min-width: $md) {
      display: block;
    }

    .mm-navbar-logo {
      padding: 8px;
      margin-top: 8px;
      margin-right: 4px;
      align-items: center;
    }
  }
}

.expand-all {
   .mm-navbar-logo {
     padding: 8px;
     margin-top: 8px;
     margin-right: 4px;
     align-items: center;

     svg {
       width: 118px;
     }
  }
}

.mm-nav-item {
  @extend .nav-item;
  margin: 0 0.5rem;
  height: 46px;

  &:hover {
    @media (min-width: $md) {
      border-bottom: 1px solid $primary;
    }
  }

  &:hover:last-child {
    @media (min-width: $md) {
      border-bottom: none;
    }
  }
}

.mm-nav-link {
  @extend .nav-link;
  color: $darkGray;

  &:hover {
    color: $primary !important;
  }
}

.active .mm-nav-item {
  border-bottom: 1px solid $primary;
}

.active>.mm-nav-link {
  color: $primary !important;
}

// for small mobile menu
@media (max-width: $md) {
  .expand-all {
    left: 768px;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    padding: 1rem;
    position: absolute;
    background-color: $darkBg;
    z-index: 9;
    overflow: hidden;
    transition: left 0.7s;

    .btn-outline-primary {
      color: $white;
      background-color: $primary;
    }

    .menu-btn-wrapper {
      width: 100%;
      display: block;

      .navbar-menu-list {
        display: block;

        .nav-link {
          color: $white;

          &:hover {
            opacity: 0.7;
          }
        }
      }

      .auth-btn-wrapper {
        display: flex;

        .navbar-nav {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .nav-link {
            color: $white;

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}

.expand-all.nav-none {
  left: 0;
}

// for tab menu
@media (min-width: $med) {
  .expand-menu {
    left: 0;
    top: 75px;
    width: 100%;
    height: 150px;
    display: flex;
    padding: 1rem;
    position: absolute;
    background-color: lighten($color: $black, $amount: 40);

    .navbar-menu-list {
      display: block;

      .nav-item {
        .nav-link {
          color: $white;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}

.menu-btn-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: $med) {
  .auth-btn-wrapper {
    display: none;
  }
}

@media (max-width: $md) {
  .expand-all {
    position: fixed;

    .navbar-menu-list {
      margin-left: -1rem;
      margin-right: -1rem !important;
      overflow: hidden;

      .mm-nav-item {
        height: 100%;
        width: 100%;
        margin: 0 !important;

        .mm-nav-link {
          font-weight: 300;
          font-size: 24px;
          color: $white;
          padding: 20px 0;
          margin-left: 40px;
          margin-right: 40px;
        }
      }

      .mm-nav-item.active {
        border-bottom: unset;
        background: #534cea4d;

        .mm-nav-link {
          color: #fff !important;
        }
      }
    }

    .mm-navbar-logo {
      display: block;
      margin-bottom: 50px;
    }
  }

  .right-menu-wrapper .nav-item,
  .right-menu-wrapper .mm-nav-item {
    padding: 0 0.3rem;
  }
}

@media (min-width: $md + 1) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.navbar-expand-lg .navbar-toggler {
  display: flex;
  @media (min-width: $header) {
    display: none;
  }
}
.navbar-nav {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navbar-brand svg {
  width: 100px;
  @media screen and (min-width: $sm) {
    width: 118px;
  }
  @media screen and (min-width: $md) {
    width: 106px;
  }
}

.mm-navbar-logo {
  display: none;
}

.mm-navbar-icon {
  position: relative;
  display: block;

  @media (min-width: $md + 1) {
    display: none;
  }
}

.mm-menuToggle {
  display: block;
  top: 50px;
  left: 50px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;

  input {
    display: block;
    width: 40px;
    height: 32px;
    top: -3px;
    left: -3px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 999;
    -webkit-touch-callout: none;
  }

  span {
    display: block;
    width: 27px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: $black;
    border-radius: $radiusS;
    z-index: 1;
    transform-origin: 4px 0;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

    &:first-child {
      transform-origin: 0 0;
    }

    &:nth-last-child(2) {
      transform-origin: 0 100%;
    }
  }

  input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-6px, -10px);
    background: $white;
    z-index: 99;

    &:nth-last-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    &:nth-last-child(2) {
      transform: rotate(-45deg) translate(-6px, 10px);
      background: $white;
      z-index: 99;
    }
  }
}
// Home screen banner (Arta Announcement)
.home-banner {
  align-content: center;
  background-color: #534cea;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 7px 0;
  transition: background-color .2s cubic-bezier(.48,0,.32,1);
  width: 100%;

  &:hover {
    background-color: #5a53da;
    color: #ffffff;
  }

  .banner-text {
    font-size: .9rem;
    font-weight: 500;
    letter-spacing: .1rem;
    line-height: 160%;
    margin-right: .5rem;
    margin-top: .25rem;

    &:hover {
      text-decoration: none;
      color: #ffffff;
    }
  }
}
.home-banner svg {
  margin: auto 0;
}
