.minx-compare {
  color: $purple;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  align-self: flex-end;
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }

  @media screen and (min-width: $header) {
    margin-bottom: 32px;
  }
}

.income-chart-container {
  background: $white;
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusS;
  padding: 0 16px 16px;
  display: flex;

  &__summary {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 20px;
    min-width: 220px;

    .income-label {
      display: block;
      color: $blue;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 4px;
    }

    .income-value {
      color: $blue;
      font-weight: 300;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.02em;
    }

    .legend-wrapper {
      display: flex;
      align-items: center;
      color: $gray;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;

      .legend--blue {
        background-color: $blue;
      }

      .legend--red {
        background-color: $red;
      }

      span {
        width: 18px;
        height: 18px;
        border-radius: 2px;
        margin-right: 16px;
      }
    }
  }

  &__chart {
    flex: 1;

    .networth-placeholder {
      padding: 40px 0;
    }
  }
}

.income-table-wrapper {
  &__head {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }

  &__info {
    color: $gray;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 0;
  }
}

.income-by-accounts {
  background: $white;
  box-shadow: 0 2px 8px $whiteFadeShadow, inset 0px -1px 0px $whiteInsetShadow;
  border-radius: $radiusS;

  .table tbody td:first-child span,
  .table tfoot td:first-child span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }

  .table tbody td:first-child span,
  .table tfoot td:first-child span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
  }

  .table tbody tr td span,
  .table tfoot tr td span {
    display: block;
    color: $gray;

    &.table-account-name {
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      color: $black;
    }

    @media screen and (min-width: 575px) {
      display: none;
    }
  }

  .table thead th span:first-child::before {
    content: '';
    display: none;
  }
}
